import React, { useEffect, useRef, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import baseURL from "../../../configs/api";
import axois from "../../../configs/axois";
import ImagePopup from "./ImagePopup";

type ExtraInformation = {
  id: number;
  idNumber: string;
  issueDate: string;
  exprationDate: string;
  filePath: string;
  idTypeId: string;
  customerId: number;
  type: string;
  imageSize: number,
  uploadedAt: string,
  uploadedBy: string
};

type Props = {
  itemIndex: number;
  handleDeleteExtraRow: (id: number) => void;
  row: ExtraInformation;
  changeOnExtraValue: (row: ExtraInformation) => void;
};

export default function AddIdentification({
  itemIndex,
  handleDeleteExtraRow,
  row,
  changeOnExtraValue,
}: Props) {
  const [type, setType] = useState([]);

  const [file, setFile] = useState<FileList | null>(null);
  const [popup, setPopup] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>(row.filePath);
  const [isUpload, setUpload] = useState<boolean>(row.filePath ? true : false);

  const [issuedDate, setIssuedDate] = useState(row.issueDate ? true : false);
  const [isExpDate, setIsExpDate] = useState(row.exprationDate ? true : false);

  const [idNumber, setIdNumber] = useState(row.idNumber);
  const [typeValue, setTypeValue] = useState(row.idTypeId);
  const [issuedDateValue, setIssuedDateValue] = useState(row.issueDate);
  const [expDateValue, setExpDateValue] = useState(row.exprationDate);
  const [fileSize, setFileSize] = useState<number>(row.imageSize);

  useEffect(() => {
    axois
      .get(`${baseURL}IdType`)
      .then((response) => {
        setType(
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );
        setTypeValue(row.idTypeId ? row.idTypeId : response.data[0].id);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data: ExtraInformation = {
      id: row.id,
      idNumber: idNumber,
      issueDate: issuedDateValue,
      exprationDate: expDateValue,
      filePath: fileName,
      idTypeId: typeValue,
      customerId: row.customerId,
      type: row.type,
      imageSize: fileSize,
      uploadedAt: row.uploadedAt,
      uploadedBy: row.uploadedBy
    };

    changeOnExtraValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idNumber, typeValue, issuedDateValue, expDateValue, isUpload, fileSize]);

  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    /*Selected files data can be collected here.*/
    const selectedFiles = e.target.files as FileList;
    if (selectedFiles.length !== 0) {
      setFile(selectedFiles);
      setFileSize(selectedFiles[0].size);
      setPopup(true);
    }
  };

  return (
    <>
      <tr>
        <td>
          <select
            style={{
              appearance: "none",
              outline: "none",
              border: "none",
              fontSize: "12px",
              padding: "3px",
            }}
            value={typeValue}
            onChange={(e) => setTypeValue(e.target.value)}
          >
            {type.map((item: any) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </td>
        <td>
          <input
            type="text"
            style={{
              appearance: "none",
              outline: "none",
              border: "none",
              width: "100%",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
            value={idNumber}
            onChange={(e) => setIdNumber(e.target.value)}
          />
        </td>
        <td style={{ textAlign: "end" }}>
          <input
            type="date"
            style={{
              appearance: "none",
              outline: "none",
              border: "none",
              color: issuedDate ? "black" : "transparent",
            }}
            value={issuedDateValue}
            onChange={(e) => {
              setIssuedDate(true);
              setIssuedDateValue(e.target.value);
            }}
            required
          />
        </td>
        <td style={{ textAlign: "end" }}>
          <input
            type="date"
            style={{
              appearance: "none",
              outline: "none",
              border: "none",
              color: isExpDate ? "black" : "transparent",
            }}
            value={expDateValue}
            onChange={(e) => {
              setIsExpDate(true);
              setExpDateValue(e.target.value);
            }}
            required
          />
        </td>
        <td style={{ width: "30%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {isUpload ? (
              <>
                <p
                  style={{
                    marginBlock: "0px",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "100px",
                  }}
                  onClick={(e) => {
                    setPopup(true);
                  }}
                >
                  {" "}
                  <AttachFileIcon
                    style={{ fontSize: "12px", color: "black" }}
                  />
                  {fileName}
                </p>
              </>
            ) : (
              <>
                <input
                  type="file"
                  ref={inputFileRef}
                  onChangeCapture={onFileChangeCapture}
                  style={{ display: "none" }}
                />

                <p
                  style={{ marginBlock: "0px", cursor: "pointer" }}
                  onClick={(e) => {
                    if (inputFileRef.current !== null) {
                      inputFileRef.current.click();
                    }
                  }}
                >
                  {" "}
                  <CloudUploadIcon
                    style={{ fontSize: "12px", color: "gray" }}
                  />
                  Upload
                </p>
              </>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <RemoveCircleOutlinedIcon
                sx={{
                  fontSize: "12px",
                  color: "gray",
                  cursor: "pointer",
                  "&: hover": {
                    color: "red",
                  },
                }}
                onClick={() => handleDeleteExtraRow(itemIndex)}
              />
            </div>
          </div>
        </td>
      </tr>

      <tr>
        <td colSpan={5} style={{ display: popup ? "block" : "none" }}>
          <ImagePopup
            handlePopup={() => setPopup(false)}
            showPopup={popup}
            fileName={file}
            fileSize={fileSize}
            uploadedAt={row?.uploadedAt}
            uploadedBy={row?.uploadedBy}
            setFileName={(name) => {
              setFileName(name);
              setUpload(true);
            }}
            filePath={fileName}
            saved={isUpload}
          />
        </td>
      </tr>
    </>
  );
}
