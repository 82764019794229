type HeroSectionProps = {
    mainHeading: string;
    subHeading?: string;
    backgroundImage?: string;
  }
  
  const HeroSection = ({ mainHeading, subHeading, backgroundImage }: HeroSectionProps) => {
    const isMobile = window.innerWidth <= 768;

    return (
      <section
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "100% 80vh",
          backgroundColor: !backgroundImage ? "#181842" : "transparent",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          height: "50vh",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          paddingTop: "65px"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            backgroundColor: "black",
            opacity: 0.45
          }}
        ></div>
  
        <h1
          style={{
            textAlign: "center",
            color: "white",
            padding: "0 0.5rem",
            fontSize: "2rem",
            fontWeight: "bold",
            textTransform: "uppercase",
            position: "relative",
            zIndex: 10
          }}
        >
          {mainHeading}
        </h1>
  
        {subHeading && (
          <h2
            style={{
              textAlign: "center",
              color: "white",
              fontSize: isMobile ? "1.5rem" : "2rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              marginTop: "2rem",
              marginBottom: "2rem",
              marginLeft: "2rem",
              marginRight: "2rem",
              position: "relative",
              zIndex: 10
            }}
          >
            {subHeading}
          </h2>
        )}
      </section>
    );
  };
  
  export default HeroSection;  