import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import HeroSection from '../../components/HeroSection';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { getPlans } from '../../services/Plan';

const FreeTrial = () => {
  const [standardPlan, setStandardPlan] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
      getPlans()
          .then((data: any) => {
              const standard = data.filter((plan: any) => plan.planName.includes("Standard"));
              standard.sort((a: any, b: any) => a.billingCycle.localeCompare(b.billingCycle));
              setStandardPlan(standard[0]);
          })
          .catch(error => console.error('Error fetching plans data:', error));
  }, []);

  const handleCheckout = () => {
      if (standardPlan) {
          navigate("/checkout", {
              state: {
                  planId: standardPlan.id,
                  planName: standardPlan.planName,
                  price: 0,
                  billingCycle: 'month',
                  source: 'free-trial'
              }
          });
      } else {
          console.error("Standard plan is not available.");
      }
  };

  return (
    <>
      <HeroSection
        mainHeading="RD Rental Free Trial"
        backgroundImage="../images/free-trial-rd-rental.png"
      />

      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px',
        boxSizing: 'border-box',
      }}>
        <div style={{
          width: '100%',
          maxWidth: '800px',
          padding: '24px',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
        }}>
          <h1 style={{
            color: '#295B7E',
            fontWeight: 'bold',
            fontSize: '24px',
            marginBottom: '24px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <button style={{
              backgroundColor: '#295B7E',
              color: '#FFF',
              padding: '4px 8px',
              fontSize: '16px',
              marginRight: '8px',
              border: 'none',
              borderRadius: '4px',
            }}>
              <ArrowRightAltIcon />
            </button>
            Free Trial
          </h1>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid #ddd',
            borderRadius: '8px',
            overflow: 'hidden',
            backgroundColor: '#fff',
          }}>
            <h2 style={{
              backgroundColor: '#295B7E',
              color: '#FFF',
              width: '100%',
              textAlign: 'center',
              padding: '16px',
              fontSize: '18px',
              marginBottom: '0',
            }}>
              Standard Membership
            </h2>
            <div style={{ padding: '16px', textAlign: 'left' }}>
              <ul style={{
                listStyleType: 'disc',
                paddingLeft: '20px',
                marginBottom: '16px',
              }}>
                <li style={{ marginBottom: '12px' }}>Unlock essential project management features.</li>
                <li style={{ marginBottom: '12px' }}>Access core functionalities for efficient project tracking and collaboration.</li>
                <li style={{ marginBottom: '12px' }}>Ideal for small teams or projects looking for essential project management tools.</li>
                <li style={{ marginBottom: '12px' }}>Enjoy a one-month free trial with the Standard Membership, with the option to upgrade later.</li>
              </ul>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
                textAlign: 'center',
              }}>
                <button onClick={handleCheckout} style={{
                  backgroundColor: '#295B7E',
                  color: '#FFF',
                  padding: '10px 16px',
                  borderRadius: '4px',
                  border: 'none',
                  cursor: 'pointer',
                }}>
                  Proceed
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeTrial;