import { LockOutlined } from "@mui/icons-material";
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import { VerifiedUser } from "@mui/icons-material";
import { FileDownload } from "@mui/icons-material";
import HeroSection from "../../components/HeroSection";
import { PersonPin } from "@mui/icons-material";
import { SupportAgent } from "@mui/icons-material";
import { Shield } from "@mui/icons-material";
import GetStartedFreeTrial from "../../components/GetStartedFreeTrial";

const securityContent = [
    {
        icon: <LockOutlined style={{ color: '#181842', fontSize: '3.5rem', width: '50%', margin: 'auto' }} />,
        title: "Data Encryption",
        description: "At RD Rental, we employ state-of-the-art encryption techniques to safeguard your data both in transit and at rest, ensuring that your information remains confidential and protected from unauthorized access.",
    },
    {
        icon: <VerifiedUser style={{ color: '#181842', fontSize: '3.5rem', width: '50%', margin: 'auto' }} />,
        title: "Multi-Factor Authentication",
        description: "With RD Rental, you can enhance the security of your account by enabling multi-factor authentication, adding an extra layer of protection beyond just passwords, and significantly reducing the risk of unauthorized access.",
    },
    {
        icon: <SecurityUpdateIcon style={{ color: '#181842', fontSize: '3.5rem', width: '50%', margin: 'auto' }} />,
        title: "Regular Security Updates",
        description: "Our dedicated security team works tirelessly to provide regular updates to RD Rental, addressing vulnerabilities and patching security loopholes promptly to keep your projects safe from evolving cyber threats.",
    },
    {
        icon: <PersonPin style={{ color: '#181842', fontSize: '3.5rem', width: '50%', margin: 'auto' }} />,
        title: "Role-Based Access Controls",
        description: "With RD Rental role-based access controls, you have the power to define who can access specific features and data within your projects, ensuring that sensitive information is only accessible to authorized users, thereby minimizing the risk of data breaches.",
    },
    {
        icon: <Shield style={{ color: '#181842', fontSize: '3.5rem', width: '50%', margin: 'auto' }} />,
        title: "Regular Security Audits",
        description: "Rest assured that your data is in safe hands with RD Rental. Our team conducts regular security audits to identify and address potential vulnerabilities, ensuring that your projects are continuously protected against security threats.",
    },
    {
        icon: <SupportAgent style={{ color: '#181842', fontSize: '3.5rem', width: '50%', margin: 'auto' }} />,
        title: "Incident Response",
        description: "In the event of a security breach, RD Rental incident response team is ready to take rapid and decisive action to mitigate the impact and restore the integrity of your projects, minimizing downtime and ensuring business continuity.",
    },
];

const Security = () => {
    return (
        <div>
            <HeroSection
                mainHeading="RD Rental Security"
                backgroundImage="../images/security-rd-rental.png"
            // Img Source: Canva
            />

            <section style={{ paddingTop: '3.5rem', paddingBottom: '3.5rem' }}>
                <div style={{ maxWidth: '1200px', margin: 'auto', marginTop: '2rem' }}>
                    {securityContent.map((item, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2rem' }}>
                            <div style={{ textAlign: 'center', width: '25%' }}>
                                {item.icon}
                            </div>
                            <div style={{ width: '75%', paddingLeft: '2rem', paddingRight: '2rem', marginTop: '1rem' }}>
                                <h2 style={{ color: '#181842', fontSize: '1.25rem', marginBottom: '1rem', fontWeight: 'bold' }}>
                                    {item.title}
                                </h2>
                                <p style={{ textAlign: 'justify' }}>
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section style={{ paddingTop: '2rem', paddingBottom: '2rem', position: 'relative', backgroundColor: 'white' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <div style={{
                        maxWidth: '600px',
                        margin: 'auto',
                        backgroundColor: 'white',
                        padding: '1rem',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <FileDownload
                            style={{ color: '#1D4ED8', fontSize: '3.5rem', marginBottom: '1rem' }}
                        />
                        <p>
                            Please review our security documentation for RD Rental and feel free
                            to share this information with your team. If you have any further
                            questions or concerns, please don't hesitate to reach out.
                            We want you to have full confidence and peace of mind when using
                            RD Rental for project management, collaboration, and data storage.
                        </p>
                    </div>
                </div>
            </section>

            <GetStartedFreeTrial />
        </div>
    )
}

export default Security;