import GetStartedFreeTrial from "../../components/GetStartedFreeTrial";
import HeroSection from "../../components/HeroSection";

const solutions = [
    {
        title: "Efficient Fleet Management:",
        description: "RD Rental enabled a car rental company to streamline its fleet management, offering real-time tracking and status updates. This helped them maintain an up-to-date view of all vehicles, ensuring availability and reducing idle time."
    },
    {
        title: "Automated Reservation System:",
        description: "With RD Rental's automated booking system, customers were able to easily reserve vehicles without manual intervention. The system automatically updated vehicle availability and sent timely reminders, improving customer satisfaction."
    },
    {
        title: "Seamless Customer Experience:",
        description: "RD Rental's platform provided a seamless experience for customers, from easy vehicle selection to transparent pricing. Customers could book, pay, and manage their reservations all from one user-friendly interface."
    },
    {
        title: "Real-Time Reporting & Analytics:",
        description: "RD Rental's powerful reporting features allowed the rental company to access real-time analytics on fleet utilization, revenue, and customer preferences, enabling smarter decision-making and enhanced business performance."
    },
    {
        title: "Maintenance Scheduling & Alerts:",
        description: "RD Rental's maintenance tracking system helped the car rental company schedule regular vehicle maintenance, reducing downtime and increasing fleet reliability. Alerts for service due dates ensured vehicles stayed in optimal condition."
    },
];

const results = [
    {
        title: "Increased Fleet Utilization:",
        description: "By implementing RD Rental, the company was able to track and manage their fleet more efficiently, leading to improved vehicle utilization and reduced idle time, resulting in higher revenue."
    },
    {
        title: "Higher Customer Satisfaction:",
        description: "The automated reservation system and seamless customer interface resulted in fewer booking errors and quicker checkouts, significantly improving the overall customer experience and satisfaction."
    },
    {
        title: "Improved Operational Efficiency:",
        description: "With real-time tracking, maintenance alerts, and automated workflows, the company was able to reduce manual tasks and optimize its daily operations, ultimately improving team productivity."
    },
    {
        title: "Data-Driven Insights for Growth:",
        description: "The data analytics and reporting features provided actionable insights that allowed the company to adjust its pricing strategy, optimize fleet allocation, and identify areas for improvement, leading to better business outcomes."
    },
];

const CaseStudies = () => {
    return (
        <div>
            <HeroSection
                mainHeading="Case Studies"
                subHeading="How a Car Rental Company Improved Fleet Management with RD Rental"
                backgroundImage="../images/case-studies-rd-rental.png"
            />

            <section style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ maxWidth: '1200px', margin: 'auto', padding: '1rem' }}>
                    <div style={{ padding: '2rem' }}>

                        <div style={{ paddingTop: '2rem' }}>
                            <h2 style={{ color: 'black', fontSize: '2rem', fontWeight: 'bold', paddingBottom: '1rem' }}>
                                Introduction
                            </h2>
                            <p style={{ fontSize: '1.25rem', color: '#4a4a4a' }}>
                                A leading car rental company was facing challenges in efficiently managing its fleet and providing a seamless customer experience. With increasing demand and operational complexities, they turned to RD Rental for a comprehensive solution. The implementation of RD Rental’s platform enabled them to improve vehicle utilization, streamline reservations, and enhance customer satisfaction.
                            </p>
                        </div>

                        <div style={{ paddingTop: '2rem' }}>
                            <h2 style={{ color: 'black', fontSize: '2rem', fontWeight: 'bold', paddingBottom: '1rem' }}>
                                Challenges
                            </h2>
                            <ul style={{ listStyleType: 'decimal', paddingLeft: '1rem', fontSize: '1.25rem', color: '#4a4a4a' }}>
                                <li style={{ paddingBottom: '1rem' }}>
                                    <strong>Manual Fleet Tracking:</strong> The company struggled with manually tracking its fleet, leading to inefficiencies in vehicle allocation and idle time.
                                </li>
                                <li style={{ paddingBottom: '1rem' }}>
                                    <strong>Booking Errors and Delays:</strong> The reservation process was prone to errors, leading to customer frustration and lost revenue opportunities.
                                </li>
                                <li style={{ paddingBottom: '1rem' }}>
                                    <strong>Lack of Real-Time Insights:</strong> Without real-time data, the company had difficulty analyzing fleet performance, customer preferences, and revenue metrics to optimize operations.
                                </li>
                            </ul>
                        </div>

                        <div style={{ paddingTop: '2rem' }}>
                            <h2 style={{ color: 'black', fontSize: '2rem', fontWeight: 'bold', paddingBottom: '1rem' }}>
                                Solution
                            </h2>
                            <p style={{ fontSize: '1.25rem', color: '#4a4a4a', paddingBottom: '1rem' }}>
                                By adopting RD Rental, the company was able to address these challenges and more. Key features of RD Rental that helped include:
                            </p>
                            <ul style={{ listStyleType: 'decimal', paddingLeft: '1rem', fontSize: '1.25rem', color: '#4a4a4a' }}>
                                {solutions.map((issue, index) => (
                                    <li key={index} style={{ paddingBottom: '1rem' }}>
                                        <strong>{issue.title}</strong> {issue.description}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div style={{ paddingTop: '2rem' }}>
                            <h2 style={{ color: 'black', fontSize: '2rem', fontWeight: 'bold', paddingBottom: '1rem' }}>
                                Results
                            </h2>

                            <ul style={{ listStyleType: 'decimal', paddingLeft: '1rem', fontSize: '1.25rem', color: '#4a4a4a' }}>
                                {results.map((issue, index) => (
                                    <li key={index} style={{ paddingBottom: '1rem' }}>
                                        <strong>{issue.title}</strong> {issue.description}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div style={{ paddingTop: '2rem', marginBottom: '5rem' }}>
                            <h2 style={{ color: 'black', fontSize: '2rem', fontWeight: 'bold', paddingBottom: '1rem' }}>
                                Conclusion
                            </h2>
                            <p style={{ fontSize: '1.25rem', color: '#4a4a4a' }}>
                                By implementing RD Rental, the car rental company was able to optimize its fleet management, reduce manual errors, and offer a better experience for customers. The enhanced reporting features also provided valuable insights that helped the company grow its revenue and improve operational efficiency.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div style={{ backgroundColor: '#f8f8f8' }}>
                <GetStartedFreeTrial />
            </div>
        </div>
    );
};

export default CaseStudies;
