import axios from "axios"
import baseURL from "../configs/api";

export const post_GroupCreditCard = async (data: any) => {
    const response = await axios({
        url: `${baseURL}groupCreditCard`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}

export const put_GroupCreditCard = async (data: any) => {
    const response = await axios({
        url: `${baseURL}groupCreditCard`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}

export const delete_GroupCreditCard = async (primaryKey: number, userId: number) => {
    const response = await axios({
        url: `${baseURL}groupCreditCard`,
        method: "DELETE",
        params: {
            id: primaryKey,
            userId
        }
    });
    return response.data;
}

export const getGroupCreditCards = () => {
    return getFetch("groupCreditCard")
}

export const getGroupCreditCard = (primaryKey: string) => {
    return getFetch(`groupCreditCard/${primaryKey}`)
}

export const getGroupCreditCardWithGroupIdAndIsActive = (groupId: string) => {
    return getFetch(`groupCreditCard/GetGroupCreditCardByGroupIdAndIsActiveWithValidExpiryDate/${groupId}`)
}

export const getGroupCreditCardWithGroupId = (groupId: string, page: number, size: number) => {
    return getFetch(`groupCreditCard/GetCreditCardsPagingByGroupId/${groupId},${page},${size}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}