import axios from "axios";
import baseURL from "../configs/api";

export const post_PaymentMethod = async (data: any) => {
    const response = await axios({
        url: `${baseURL}paymentMethod`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}