import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CreditCardView from './CreditCardView';
import '../../styles/QuickPayView.css';

const QuickPayView = () => {
    const { accountNumber }: any = useParams();
    const [amountDue, setAmountDue] = useState<number>(10.10);

    useEffect(() => {
        setAmountDue(10.10);
    }, []);

    return (
        <div className="quickpay-container">
            <div className="quickpay-flex-container">
                <div className="quickpay-left">
                    <div className="quickpay-left-inner">
                        <div className="quickpay-card">
                            <h4 className="account-info">
                                <span>Account: </span>
                                <span className="account-number">{accountNumber}</span>
                            </h4>
                            <div className="credit-card-container">
                                <h4 className="user-name">Mr. Abc Xyx</h4>
                                <CreditCardView amountDue={amountDue} account={true} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="quickpay-right">
                    <div className="image-container">
                        <img
                            src="./images/billpay1.jpg"
                            className="billpay-image"
                            alt="Dining Experience"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuickPayView;
