import Cookies from 'js-cookie';
import { useState } from 'react';
import { post_GroupCreditCard } from '../../../services/GroupCreditCard';
import Cross from '../../../icons/Cross';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardNumberValidate from '../../../components/CardNumberValidate';
import ExpiryDateValidator from '../../../components/ExpiryDateValidator';
import CvvValidate from '../../../components/CvvValidate';
import Loading from '../../../icons/Loading';
import colorConfigs from '../../../configs/colorConfigs';

type CreditCardProps = {
    showPopupAddNewCreditCard: boolean;
    setShowPopupAddNewCreditCard: (value: boolean) => void;
    setIsRefresh: (value: boolean) => void;
}

const AddCreditCard = ({ showPopupAddNewCreditCard, setShowPopupAddNewCreditCard, setIsRefresh }: CreditCardProps) => {
    const userId = Cookies.get("userId-jj-rental-ui");
    const groupId = Cookies.get("groupId-jj-rental-ui");
    const [creditCardDetails, setCreditCardDetails] = useState<any>({
        cardholderName: "",
        creditCardNumber: "",
        expirationDate: "",
        ccvCode: ""
    });

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isProcessing) {
            setIsProcessing(true);
            post_GroupCreditCard({
                ...creditCardDetails,
                isActive: false,
                userId: userId,
                groupId: groupId
            })
                .then(() => {
                    setIsRefresh(true);
                    setIsProcessing(false);
                    setShowPopupAddNewCreditCard(false);
                    setCreditCardDetails({
                        cardholderName: "",
                        creditCardNumber: "",
                        expirationDate: "",
                        ccvCode: ""
                    });
                })
                .catch(error => {
                    console.log(error);
                    setIsProcessing(false);
                })
        }

    }

    const closeModal = () => setShowPopupAddNewCreditCard(false);

    return (
        <div
            style={{
                position: "fixed",
                inset: "0",
                padding: "1rem",
                marginTop: "5rem",
                display: showPopupAddNewCreditCard ? "flex" : "none",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                zIndex: "1000",
                overflow: "auto",
            }}
        >
            <div
                style={{
                    position: "fixed",
                    inset: "0",
                    width: "100%",
                    minHeight: "calc(100vh - 72px)",
                    backgroundColor: "rgba(0,0,0,0.5)",
                }}
            ></div>
            <div
                style={{
                    width: "100%",
                    maxWidth: "32rem",
                    backgroundColor: "white",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0.375rem",
                    padding: "1.25rem",
                    position: "relative",
                }}
            >
                <Cross closeModal={closeModal} />
                <div style={{ width: "100%", marginTop: "1.25rem" }}>
                    <form
                        style={{
                            marginTop: "0.25rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                        }}
                        onSubmit={handleSubmit}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label
                                style={{
                                    display: "block",
                                    textAlign: "start",
                                    color: colorConfigs.primaryColor,
                                    fontSize: "0.75rem",
                                    fontWeight: "500",
                                    marginBottom: "2px",
                                }}
                            >
                                Cardholder Name
                            </label>
                            <input
                                name="cardholderName"
                                type="text"
                                style={{
                                    width: "100%",
                                    border: "1px solid",
                                    borderColor: colorConfigs.primaryColor,
                                    padding: "8px",
                                    borderRadius: "0.375rem",
                                    outline: colorConfigs.primaryColor,
                                }}
                                placeholder="Enter Cardholder Name"
                                value={creditCardDetails.cardholderName}
                                onChange={(e) =>
                                    setCreditCardDetails({
                                        ...creditCardDetails,
                                        cardholderName: e.target.value,
                                    })
                                }
                                required
                            />
                        </div>

                        <div style={{ position: "relative", display: "flex", flexDirection: "column" }}>
                            <CardNumberValidate
                                value={creditCardDetails.creditCardNumber}
                                setValue={(value) =>
                                    setCreditCardDetails({
                                        ...creditCardDetails,
                                        creditCardNumber: value,
                                    })
                                }
                            />
                            <CreditCardIcon
                                style={{
                                    width: "18px",
                                    height: "18px",
                                    position: "absolute",
                                    right: "1rem",
                                    top: "70%",
                                    transform: "translateY(-50%)",
                                    color: "#bbbbbb",
                                }}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                        >
                            <div style={{ position: "relative", flex: "1", display: "flex", flexDirection: "column" }}>
                                <ExpiryDateValidator
                                    expiryDate={creditCardDetails.expirationDate}
                                    setExpiryDate={(value) =>
                                        setCreditCardDetails({
                                            ...creditCardDetails,
                                            expirationDate: value,
                                        })
                                    }
                                />
                            </div>

                            <div style={{ position: "relative", flex: "1", display: "flex", flexDirection: "column" }}>
                                <CvvValidate
                                    value={creditCardDetails.ccvCode}
                                    setValue={(value) =>
                                        setCreditCardDetails({
                                            ...creditCardDetails,
                                            ccvCode: value,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        <div style={{ paddingTop: "1rem" }}>
                            <button
                                type="submit"
                                style={{
                                    color: "white",
                                    backgroundColor: colorConfigs.primaryColor,
                                    borderRadius: "0.375rem",
                                    border: "1px solid",
                                    borderColor: colorConfigs.primaryColor,
                                    padding: "0.5rem",
                                    width: "100%",
                                    textAlign: "center",
                                    cursor: "pointer"
                                }}
                            >
                                {isProcessing ? (
                                    <>
                                        <Loading />
                                        Processing...
                                    </>
                                ) : (
                                    "Save"
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddCreditCard;