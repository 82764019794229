import { useNavigate } from 'react-router-dom';

const customContent = [
    {
        image: "./images/real-time-tracking.jpg",
        // pexels.com
        title: "Real-Time Vehicle Tracking",
        description: "With RD Rental, you can track the location and status of your fleet in real-time. Monitor vehicle usage, optimize routes, and ensure timely delivery or pick-up to enhance customer satisfaction."
    },
    {
        image: "./images/automated-reservation-system.png",
        // pexels.com
        title: "Automated Reservation System",
        description: "RD Rental offers an intuitive and automated booking system, allowing your customers to book vehicles with ease. Customize booking options, manage availability, and automate notifications for a seamless rental experience."
    },
    {
        image: "./images/maintenance-rd-rental.jpg",
        // pexels.com
        title: "Comprehensive Maintenance Tracking",
        description: "Keep your fleet in top condition with RD Rental built-in maintenance tracking. Receive alerts for upcoming maintenance, schedule services, and ensure vehicle reliability for every rental."
    },
];

const Home = () => {
    const navigate = useNavigate();

    return (
        <div>
            <section style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
                <div style={{ position: 'absolute', inset: 0, backgroundColor: '#000', opacity: 1 }}></div>
                <div style={{ position: 'absolute', inset: 0, backgroundColor: 'black', opacity: 0.3 }}>
                    <img
                        style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                        src="../images/home-section-rd-rental.jpg"
                        // pexels.com
                        alt="Hero Section Background"
                    />
                </div>
                <div style={{ position: 'absolute', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ textAlign: 'center', color: 'white', zIndex: 10, position: 'relative' }}>
                        <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem' }}>Welcome to RD Rental</h1>
                        <p style={{ fontSize: '1.25rem', marginBottom: '2rem' }}>Your trusted partner in vehicle rental management</p>
                        <button
                            style={{
                                backgroundColor: '#ffffff',
                                color: 'black',
                                fontWeight: 'bold',
                                padding: '0.75rem 1.5rem',
                                borderRadius: '0.5rem',
                                border: 'none',
                                cursor: 'pointer',
                                transition: 'all 0.3s',
                            }}
                            onClick={() => navigate("/free-trial")}
                            onMouseOver={(e) => {
                                const button = e.target as HTMLButtonElement;
                                button.style.backgroundColor = 'black';
                                button.style.color = 'white';
                            }}
                            onMouseOut={(e) => {
                                const button = e.target as HTMLButtonElement;
                                button.style.backgroundColor = '#ffffff';
                                button.style.color = 'black';
                            }}
                        >
                            Get Started
                        </button>
                    </div>
                </div>
            </section>

            <section style={{ padding: '4rem 0', backgroundColor: 'white', color: 'black' }}>
                <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 1rem' }}>
                    <h2 style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center', marginBottom: '2rem' }}>Why Choose RD Rental?</h2>
                    <p style={{ fontSize: '1rem', textAlign: 'center' }}>
                        With RD Rental, you can easily manage your fleet of vehicles, from reservations to maintenance, all through a user-friendly platform. 
                        Whether you're looking for a car, truck, or specialty vehicle for your customers, RD Rental provides a seamless experience with 
                        real-time tracking, flexible booking, and automatic invoicing. Ensure your fleet is always ready to serve with our built-in service alerts and reporting tools.
                    </p>
                </div>
            </section>

            <section style={{ padding: '4rem 0', backgroundColor: '#f3f4f6' }}>
                <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 1rem' }}>
                    <h2 style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center', color: '#4a5568', marginBottom: '2rem' }}>Key Features</h2>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '2rem' }}>
                        {customContent.map((content, index) => (
                            <div key={index} style={{ backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', overflow: 'hidden' }}>
                                <div style={{ position: 'relative' }}>
                                    <img
                                        style={{ width: '100%', height: '12rem', objectFit: 'cover', objectPosition: 'center' }}
                                        src={content.image}
                                        alt="features"
                                    />
                                </div>
                                <div style={{ padding: '1.5rem' }}>
                                    <h3 style={{ fontSize: '1.25rem', fontWeight: '600', marginBottom: '0.5rem' }}>{content.title}</h3>
                                    <p style={{ color: '#718096' }}>{content.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;