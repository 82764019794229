import React from "react";
import colorConfigs from "../configs/colorConfigs";

interface CvvValidateProps {
  value: string;
  setValue: (value: string) => void;
  className?: any;
}

const CvvValidate = ({ value, setValue, className }: CvvValidateProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    const isValid = /^\d{3,4}$/gm.test(value);
    e.target.setCustomValidity(
      isValid ? "" : "Please enetr a valid cvv number"
    );
  };

  return (
    <>
      <label
        style={{
          display: "block",
          textAlign: "start",
          color: colorConfigs.primaryColor,
          fontSize: "0.75rem",
          fontWeight: "500",
          marginBottom: "2px",
        }}
      >
        CVV
      </label>
      <input
        name="cvv"
        type="text"
        maxLength={4}
        style={{
          width: "100%",
          border: "1px solid",
          borderColor: colorConfigs.primaryColor,
          padding: "8px",
          borderRadius: "0.375rem",
          outline: colorConfigs.primaryColor,
        }}
        placeholder="CVV"
        required
        value={value}
        onChange={handleChange}
      />
    </>
  );
};

export default CvvValidate;