import { Link } from 'react-router-dom';
import { useState } from 'react';
import HeroSection from '../../../components/HeroSection';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';

const blogData = [
    {
        id: 1,
        img: '../images/rd-rental-software-blog.jpg',
        // pexels.com
        category: 'Rental Software',
        link: '/blog/rental-software',
        title: 'Unlocking the Future of Vehicle Rentals with Advanced Software Solutions',
        description: 'In the highly competitive world of vehicle rentals, businesses must rely on cutting-edge technology to stay ahead of the curve. ',
        publishDate: '27/11/2024',
    },
    {
        id: 2,
        img: '../images/fleet-management-rd-rental.jpg',
        // pexels.com
        category: 'Rental Software',
        link: '/blog/advanced-rental-software',
        title: 'How Advanced Rental Software Enhances Fleet Management',
        description: "Effective fleet management is critical for any vehicle rental business. It's not just about owning a collection of cars, trucks, or vans, ",
        publishDate: '27/11/2024',
    },
    {
        id: 3,
        img: '../images/rd-rental-business-operations.png',
        // pexels.com
        category: 'Rental Software',
        link: '/blog/rental-business-operations',
        title: 'Simplifying Rental Business Operations with Advanced Software',
        description: "As the rental industry grows more competitive, the need for efficient management systems is greater than ever. ",
        publishDate: '27/11/2024',
    },
];

const Blog = () => {
    const cardsPerPage = 6;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(blogData.length / cardsPerPage);
    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    const currentBlogData = blogData.slice(startIndex, endIndex);

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div style={{ backgroundColor: '#f3f4f6', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <HeroSection
                mainHeading="Blogs"
                backgroundImage="../images/blog-rd-rental.png"
            />

            <section style={{ width: '100%', padding: '2rem 1rem', display: 'flex', justifyContent: 'center' }}>
                <div
                    style={{
                        maxWidth: '1280px',
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: '2rem',
                    }}
                >
                    {currentBlogData.map((item) => (
                        <div
                            key={item.id}
                            style={{
                                backgroundColor: '#ffffff',
                                borderRadius: '0.5rem',
                                padding: '1rem',
                                position: 'relative',
                                transition: 'box-shadow 0.3s',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={{ position: 'relative', marginBottom: '1rem' }}>
                                <img
                                    src={item.img}
                                    alt={item.title}
                                    style={{
                                        width: '100%',
                                        borderRadius: '0.5rem',
                                        transition: 'transform 0.3s',
                                    }}
                                    className="hover:scale-105"
                                />
                                <p
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        left: '0',
                                        margin: '1rem',
                                        backgroundColor: '#295B7E',
                                        color: '#ffffff',
                                        padding: '0.75rem',
                                        borderRadius: '0.5rem',
                                        fontSize: '0.75rem',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {item.category}
                                </p>
                            </div>
                            <div style={{ marginTop: '5px', flexGrow: 1 }}>
                                <Link to={`${item.link}`} style={{ textDecoration: 'none' }}>
                                    <h2
                                        style={{
                                            fontSize: '1.25rem',
                                            margin: '1rem 0',
                                            fontWeight: 'bold',
                                            color: '#000000',
                                            cursor: 'pointer',
                                            transition: 'color 0.3s',
                                        }}
                                    >
                                        {item.title}
                                    </h2>
                                </Link>
                                <p style={{ marginBottom: '1rem', color: '#6B7280', fontSize: '0.875rem' }}>
                                    {item.description}
                                </p>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AccessTimeIcon style={{ width: '1rem', color: '#6B7280' }} />
                                        <p style={{ marginLeft: '0.5rem', fontSize: '0.875rem', color: '#6B7280' }}>
                                            {item.publishDate}
                                        </p>
                                    </div>
                                    <Link
                                        to={item.link}
                                        style={{
                                            width: '3rem',
                                            height: '3rem',
                                            padding: '1rem',
                                            borderRadius: '50%',
                                            backgroundColor: 'transparent',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '2px solid #6B7280',
                                            transition: 'color 0.3s, border-color 0.3s',
                                        }}
                                    >
                                        <ArrowForwardIosIcon />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section style={{ width: '100%', padding: '2rem 1rem', display: 'flex', justifyContent: 'center' }}>
                <div
                    style={{
                        maxWidth: '1280px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem 2rem',
                    }}
                >
                    <button
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1}
                        style={{
                            fontWeight: '600',
                            color: '#295B7E',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                            opacity: currentPage === 1 ? 0.5 : 1,
                        }}
                    >
                        <ArrowBackIosIcon style={{ marginRight: '0.5rem' }} /> <span>Previous Page</span>
                    </button>
                    <button
                        onClick={goToNextPage}
                        disabled={currentPage === totalPages}
                        style={{
                            fontWeight: '600',
                            color: '#295B7E',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
                            opacity: currentPage === totalPages ? 0.5 : 1,
                        }}
                    >
                        <span>Next Page</span> <ArrowForwardIosIcon style={{ marginLeft: '0.5rem' }} />
                    </button>
                </div>
            </section>

            <style>{`
                @media (max-width: 768px) {
                    /* Mobile view: 1 column grid layout */
                    div[style*="display: grid"] {
                        grid-template-columns: 1fr !important;  /* Enforce 1 column */
                    }
                }
            `}</style>
        </div>
    );
};

export default Blog;
