import React, { useEffect, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type PageModelType = { page: number, size: number };

type PaginationProps = {
    pageOption?: number[];
    showEntries: number;
    setShowEntries: (value: number) => void;
    totalLength: number;
    paginationModel: PageModelType;
    setPaginationModel: (value: PageModelType) => void;
    setIsRefresh?: (value: boolean) => void;
}

const Pagination = ({ pageOption = [5, 10, 20, 50, 100], showEntries, setShowEntries, totalLength, paginationModel, setPaginationModel, setIsRefresh }: PaginationProps) => {
    const [paginationOption, setPaginationOption] = useState<number[]>([1]);

    useEffect(() => {
        if (totalLength > 0) {
            const array: number[] = [];

            for (
                let i = 1;
                i <= Math.ceil(totalLength / paginationModel?.size);
                i++
            ) {
                array.push(i);
            }

            setPaginationOption(array);
        }
    }, [totalLength, paginationModel]);

    const handleShowEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPaginationModel({
            page: 0,
            size: parseInt(e.target.value),
        });
        setShowEntries(parseInt(e.target.value));
        setIsRefresh && setIsRefresh(true);
    };

    const previousPagnation = () => {
        if (paginationModel.page > 0) {
            setPaginationModel({
                ...paginationModel,
                page: paginationModel.page - 1,
            });

            setIsRefresh && setIsRefresh(true);
        }
    };

    const nextPagination = () => {
        if (paginationModel.page < paginationOption[paginationOption.length - 2]) {
            setPaginationModel({
                ...paginationModel,
                page: paginationModel.page + 1,
            });

            setIsRefresh && setIsRefresh(true);
        }
    };

    return (
        <div
            style={{
                width: "100%",
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                paddingTop: "0.75rem",
                paddingInline: "1rem",
                justifyContent: "space-between",
            }}
        >
            <p style={{ fontSize: "0.875rem", color: "#4A4A4A", flex: "1" }}>
                {` ${(paginationModel.page * showEntries) + 1} to ${(showEntries * (paginationModel.page + 1)) >= totalLength ? totalLength : showEntries * (paginationModel.page + 1)} of ${totalLength} entries`}
            </p>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <select
                    style={{
                        fontSize: "0.875rem",
                        color: "#4A4A4A",
                        border: "1px solid #D1D5DB",
                        borderRadius: "0.375rem",
                        height: "1.75rem",
                        marginInline: "1rem",
                        outline: "none",
                    }}
                    value={showEntries}
                    onChange={handleShowEntriesChange}
                >
                    {pageOption?.map((item: number) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                <ul
                    style={{
                        display: "flex",
                       
                        alignItems: "center",
                    }}
                >
                    <li
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "1.75rem",
                            height: "1.75rem",
                            borderRadius: "20%",
                            backgroundColor:
                                paginationModel.page <= 0 ? "rgba(169, 169, 169, 0.3)" : "#A9A9A9",
                            cursor: paginationModel.page <= 0 ? "default" : "pointer",
                            padding: "0.25rem",
                        }}
                        onClick={previousPagnation}
                    >
                        <ArrowBackIosIcon style={{ fill: "#fff" }} />
                    </li>

                    <li
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "0.875rem",
                            width: "fit-content",
                            height: "1.75rem",
                            borderRadius: "0.375rem",
                            padding: "0.25rem 0.5rem",
                        }}
                    >
                        {`${paginationModel.page + 1} - ${paginationOption[paginationOption.length - 1]} of ${paginationOption[paginationOption.length - 1]} page`}
                    </li>

                    <li
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "1.75rem",
                            height: "1.75rem",
                            borderRadius: "20%",
                            backgroundColor:
                                totalLength <= paginationModel.size || paginationModel.page >= paginationOption[paginationOption.length - 2]
                                    ? "rgba(169, 169, 169, 0.3)"
                                    : "#A9A9A9",
                            cursor: totalLength <= paginationModel.size || paginationModel.page >= paginationOption[paginationOption.length - 2]
                                ? "default"
                                : "pointer",
                            padding: "0.25rem",
                        }}
                        onClick={nextPagination}
                    >
                        <ArrowForwardIosIcon style={{ fill: "#fff" }} />
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Pagination;