import {
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    WhatsApp as WhatsAppIcon,
    LinkedIn as LinkedInIcon,
    Comment as CommentIcon,
    Bookmark as BookmarkIcon,
    AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import HeroSection from "../../../components/HeroSection";

const RentalSoftware = () => {
    return (
        <div style={{ backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "column" }}>
            <HeroSection
                mainHeading="Unlocking the Future of Vehicle Rentals with Advanced Software Solutions"
                backgroundImage="../images/rd-rental-software-blog.jpg"
                // pexels.com
            />

            <section>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", marginTop: "40px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <AccessTimeIcon style={{ width: "16px" }} />
                        <p style={{ fontSize: "14px", color: "#555" }}>27/11/2024</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <CommentIcon style={{ width: "16px" }} />
                        <p style={{ fontSize: "14px", color: "#555" }}>0 Comments</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <BookmarkIcon />
                        <p style={{ fontSize: "14px", color: "#555" }}>1,220 Views</p>
                    </div>
                </div>
            </section>

            <section style={{ width: "100%", padding: "32px 16px" }}>
                <div style={{ margin: "0 auto", maxWidth: "768px" }}>
                    <p>
                        In the highly competitive world of vehicle rentals, businesses must rely on cutting-edge technology to stay ahead of the curve. That's where our powerful RD Rental Software comes in. Designed for businesses offering car, truck, van, and other vehicle rentals, our software offers a comprehensive suite of tools that streamline operations and enhance customer satisfaction.
                    </p>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Effortless Reservations and Booking</h2>
                        <p>
                            One of the biggest challenges for vehicle rental businesses is managing reservations. Our software simplifies the process with an easy-to-use, automated reservation system. Customers can effortlessly book vehicles online, ensuring they get the car, truck, or van they need, when they need it. With real-time availability tracking, overbooking is a thing of the past, allowing businesses to better manage their fleet and maximize revenue.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Comprehensive Vehicle Maintenance</h2>
                        <p>
                            Maintaining a fleet of vehicles is no small feat, especially when managing multiple rental types. Our software helps ensure that all vehicles are well-maintained and roadworthy with built-in maintenance scheduling and tracking tools. You can log every service, track repairs, and manage vehicle health proactively. This reduces costly emergency repairs, increases the lifespan of your fleet, and ensures customer safety, boosting your business’s reputation.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Seamless Repair Orders Management</h2>
                        <p>
                            Whether it's a flat tire or an engine issue, our software enables businesses to manage repair orders efficiently. With an easy-to-use interface, you can create, track, and manage repair requests for each vehicle in your fleet. This ensures that any issue is addressed quickly, reducing downtime and keeping vehicles available for customers. The repair history is documented for each vehicle, making future servicing and decision-making much easier.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Customer Enquiries & Support</h2>
                        <p>
                            Customer satisfaction is key to the success of any rental business. Our software includes an efficient system for managing customer inquiries, allowing your team to respond to requests quickly and effectively. From booking questions to vehicle features, your team will have all the information they need at their fingertips to deliver top-notch customer support.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Billing Made Easy</h2>
                        <p>
                            Billing and invoicing can be time-consuming and prone to errors. Our software streamlines this process by automatically generating accurate invoices based on rental duration, vehicle type, and any additional services used. This not only reduces the time spent on paperwork but also ensures your customers receive clear, error-free billing, contributing to greater trust and satisfaction.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Advanced Analytics and Reporting</h2>
                        <p>
                            To help your business grow, our software comes with advanced analytics tools that provide insights into your fleet's performance. From tracking rental patterns and identifying popular vehicle types to assessing revenue streams, our analytics help businesses make data-driven decisions. With easy-to-understand reports, you can optimize operations and anticipate customer needs, improving both operational efficiency and profitability.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <p>
                            In conclusion, our RD Rental Software is designed to transform the way vehicle rental businesses operate. By providing tools for managing reservations, vehicle maintenance, customer inquiries, billing, and more, we help businesses stay organized, reduce costs, and enhance the overall customer experience. With the added benefit of advanced analytics, businesses can make informed decisions that lead to long-term success. Embrace the future of vehicle rentals with our innovative software.
                        </p>
                    </div>
                </div>
            </section>

            <section style={{ marginBottom: "32px" }}>
                <div style={{ maxWidth: "768px", margin: "0 auto", borderRadius: "8px", display: "flex", flexDirection: "column", gap: "24px", alignItems: "center" }}>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        <p style={{ backgroundColor: "#e5e5e5", color: "#666", padding: "4px 8px", borderRadius: "4px" }}>Vehicle Rentals</p>
                        <p style={{ backgroundColor: "#e5e5e5", color: "#666", padding: "4px 8px", borderRadius: "4px" }}>Fleet Management</p>
                        <p style={{ backgroundColor: "#e5e5e5", color: "#666", padding: "4px 8px", borderRadius: "4px" }}>Customer Support</p>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "12px", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#42599E", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <FacebookIcon />
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#55ACEE", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <TwitterIcon />
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#34AF23", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <WhatsAppIcon />
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0798CF", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <LinkedInIcon />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RentalSoftware;
