import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import colorConfigs from '../../configs/colorConfigs';

const QuickPay = () => {
    const navigate = useNavigate();
    const [accountNumber, setAccountNumber] = useState<string>("");
    const [required, setRequired] = useState<boolean>(false);

    const handleQuickPay = () => {
        if (accountNumber.trim() !== "") {
            setRequired(false);
            navigate(`/quick-pay/${accountNumber}`);
        } else {
            setRequired(true);
        }
    }

    return (
        <div style={{ width: "100%" }}>
          <div
            style={{
              maxWidth: "32rem",
              width: "100%",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <h3
              style={{
                fontSize: "1.875rem",
                fontWeight: "800",
              }}
            >
              Quick Pay
            </h3>
      
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <label
                style={{
                  position: "relative",
                }}
              >
                Account Number
                <span
                  style={{
                    content: "'*'",
                    color: "#f87171",
                    marginLeft: "0.25rem",
                    fontWeight: "700",
                  }}
                >
                  *
                </span>
              </label>
      
              <input
                name="email"
                type="text"
                required
                style={{
                  backgroundColor: "transparent",
                  width: "100%",
                  fontSize: "0.875rem",
                  borderRadius: "0.375rem",
                  border: "1px solid",
                  borderColor: colorConfigs.primaryColor,
                  padding: "0.75rem 0.5rem",
                  outline: "none",
                }}
                placeholder="Enter Account Number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
      
              {required && (
                <span
                  style={{
                    color: "#f87171",
                  }}
                >
                  Required
                </span>
              )}
            </div>
      
            <div
              style={{
                width: "100%",
                textAlign: "end", 
              }}
            >
              <button
                type="button"
                style={{
                  color: "white",
                  backgroundColor: colorConfigs.primaryColor,
                  border: "1px solid",
                  borderColor: colorConfigs.primaryColor,
                  borderRadius: "0.375rem",
                  padding: "0.25rem 1.5rem",
                }}
                onClick={handleQuickPay}
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      );      
}

export default QuickPay;