import Cookies from "js-cookie";
import { useEffect } from "react";
import { getGroup } from "../../../services/Group";
import EditIcon from '@mui/icons-material/Edit';
import DetailsTag from "./DetailsTag";


type BillingAddressProps = {
    setShowPopupUpdateBillingAddress: (value: boolean) => void;
    billingAddressData: any;
    setBillingAddressData: (value: any) => void;
}

const BillingAddress = ({ setShowPopupUpdateBillingAddress, billingAddressData, setBillingAddressData }: BillingAddressProps) => {
    const groupId = Cookies.get("groupId-jj-rental-ui");

    useEffect(() => {
        if (groupId) {
            getGroup(groupId)
                .then(res => {
                    setBillingAddressData(res)
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId])

    const editTheBillingAddress = () => {
        setShowPopupUpdateBillingAddress(true);
    }


    return (
        <div
            style={{
                width: "100%",
                border: "1px solid #295B7E",
                borderRadius: "0.375rem",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "50px",
                    padding: "0.5rem 1rem",
                    backgroundColor: "#295B7E",
                    color: "#fff",
                }}
            >
                <h4
                    style={{
                        fontWeight: 500,
                        color: "#fff",
                    }}
                >
                    Billing Address
                </h4>

                <EditIcon
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={editTheBillingAddress}
                />
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    gap: "0.75rem",
                }}
            >
                <DetailsTag title="Company Name" value={billingAddressData?.name} />

                <div
                    style={{
                        display: "flex",
                        gap: "1rem",
                    }}
                >
                    <DetailsTag title="Email" value={billingAddressData?.emailAddress} />
                    <DetailsTag
                        title="Phone Number"
                        value={billingAddressData?.phoneNumber}
                    />
                </div>

                <div
                    style={{
                        display: "flex",
                        gap: "1rem",
                    }}
                >
                    <DetailsTag title="Street" value={billingAddressData?.street} />
                    <DetailsTag title="Street 2" value={billingAddressData?.street2} />
                    <DetailsTag title="ZIP" value={billingAddressData?.zip} />
                </div>

                <div
                    style={{
                        display: "flex",
                        gap: "1rem",
                    }}
                >
                    <DetailsTag title="State" value="TX" />
                    <DetailsTag title="City" value={billingAddressData?.city} />
                    <DetailsTag title="Country" value={billingAddressData?.country} />
                </div>
            </div>
        </div>
    );
}

export default BillingAddress;