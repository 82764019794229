import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import QuickPay from "./QuickPay";
import BillPayLogin from "./BillPayLogin";

const BillPay = () => {
    const navigate = useNavigate();
    const isAdmin = Cookies.get("isAdmin-jj-rental-ui");
    const isMediumScreen = window.matchMedia("(min-width: 768px)").matches;

    useEffect(() => {
        if (isAdmin && parseInt(isAdmin) === 1) {
            navigate(`/bill-details`);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdmin])

    return (
        <div
            style={{
                paddingBottom: "10rem",
                paddingTop: "2.5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                minHeight: "calc(100vh - 600px)",
                backgroundColor: "white"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            gap: "2rem",
                        }}
                    >
                        <QuickPay />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.5rem",
                                width: "100%",
                                paddingLeft: "2rem",
                                paddingRight: "2rem",
                            }}
                        >
                            <hr
                                style={{
                                    backgroundColor: "var(--primary-variant)",
                                    height: "0.25rem",
                                    width: "calc(47.5% - 0.5rem)",
                                }}
                            />
                            <CloseIcon
                                style={{
                                    textAlign: "center",
                                    color: "var(--primary-color)",
                                    marginTop: "-0.75rem",
                                    fontSize: "28px", 
                                }}
                            />
                            <hr
                                style={{
                                    backgroundColor: "var(--primary-variant)",
                                    height: "0.25rem",
                                    width: "calc(47.5% - 0.5rem)",
                                }}
                            />
                        </div>

                        <BillPayLogin />
                    </div>

                    <div
                        style={{
                            flex: 1,
                            display: isMediumScreen ? "block" : "none",
                        }}
                    >
                        <div
                            style={{
                                width: "66.666667%",
                                maxWidth: "83.333333%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src="../images/billpay1.jpg"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                                alt="Dining Experience"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BillPay;