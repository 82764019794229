import { useState } from 'react';
import useDelete from './hooks/useDelete';

const DeletePopup = () => {
    const { deletePopup, setDeletePopup, data }: any = useDelete();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [cancelHovered, setCancelHovered] = useState(false);
    const [deleteHovered, setDeleteHovered] = useState(false);

    const closeModal = () => setDeletePopup(false);

    const deleteTheModal = () => {
        if (!isProcessing) {
            setIsProcessing(true);
            data?.isDelete(() => {
                setIsProcessing(false);
                closeModal();
            });
        }
    }

    return (
        <div style={{
            position: 'fixed',
            inset: 0,
            padding: '1rem',
            marginTop: '2.75rem',
            display: deletePopup ? "flex" : "none",
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 'fit-content',
            zIndex: 1000000568900,
            overflow: 'auto'
        }}>
            <div style={{
                width: '100%',
                maxWidth: '32rem',
                backgroundColor: 'white',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                borderRadius: '0.375rem',
                padding: '1.25rem 1.25rem 1.25rem 1.25rem',
                position: 'relative'
            }}>
                <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320.591 320.591"
                    style={{
                        width: '0.75rem',
                        cursor: 'pointer',
                        flexShrink: 0,
                        fill: 'black',
                        float: 'right'
                    }}
                    onClick={closeModal}
                >
                    <path
                        d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                    />
                    <path
                        d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                    />
                </svg>
                <div style={{ marginTop: '2rem', marginBottom: '2rem', textAlign: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '3rem', fill: '#B5B5B5', display: 'inline' }} viewBox="0 0 24 24">
                        <path
                            d="M19 7a1 1 0 0 0-1 1v11.191A1.92 1.92 0 0 1 15.99 21H8.01A1.92 1.92 0 0 1 6 19.191V8a1 1 0 0 0-2 0v11.191A3.918 3.918 0 0 0 8.01 23h7.98A3.918 3.918 0 0 0 20 19.191V8a1 1 0 0 0-1-1Zm1-3h-4V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM10 4V3h4v1Z"
                        />
                        <path d="M11 17v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Zm4 0v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Z" />
                    </svg>
                    <h2 style={{ fontSize: '1.125rem', fontWeight: 'bold' }}>{data?.tilte}</h2>
                    <h4 style={{ fontSize: '1rem', fontWeight: '600', marginTop: '1rem' }}>{data?.summary}</h4>
                </div>

                <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                    <button
                        type="button"
                        style={{
                            padding: '0.5rem 1.5rem',
                            borderRadius: '0.375rem',
                            color: 'black',
                            fontSize: '0.875rem',
                            fontWeight: '600',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: cancelHovered ? '#D1D1D1' : '#E5E5E5',
                            cursor: "pointer"
                        }}
                        onClick={closeModal}
                        onMouseEnter={() => setCancelHovered(true)}
                        onMouseLeave={() => setCancelHovered(false)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        style={{
                            padding: '0.5rem 1.5rem',
                            borderRadius: '0.375rem',
                            color: 'white',
                            fontSize: '0.875rem',
                            fontWeight: '600',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: deleteHovered ? '#DC2626' : '#E11D48',
                            cursor: "pointer"
                        }}
                        onClick={deleteTheModal}
                        onMouseEnter={() => setDeleteHovered(true)}
                        onMouseLeave={() => setDeleteHovered(false)}
                    >
                        {isProcessing ? "Processing..." : "Delete"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeletePopup;
