import { HashRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { adminRoutes, routes } from "./routes";
import "./App.css";
import { AddVehicles } from "./pages/fleet/vehicles/AddVehicles";
import { AddFeature } from "./pages/settings/fleet/features/AddFeature"
import { EditFeature } from "./pages/settings/fleet/features/EditFeature"
import { PageNotFound } from "./pages/notFound/PageNotFound";
import { EditVehicles } from "./pages/fleet/vehicles/EditVehicles";
import { AddRepairOrder } from "./pages/fleet/repairOrder/AddRepairOrder";
import { EditRepariOrder } from "./pages/fleet/repairOrder/EditRepariOrder";
import { AddCustomer } from "./pages/contacts/customers/AddCustomer";
import { EditCustomer } from "./pages/contacts/customers/EditCustomer";
import { AdminMainLayout } from "./components/layout/AdminMainLayout";
import AddBranch from "./pages/settings/fleet/branches/AddBranch";
import EditBranch from "./pages/settings/fleet/branches/EditBranch";
import AddReservation from "./pages/carrental/reservations/AddReservation";
import CreateRepairOrder from "./pages/fleet/maintenance/CreateRepairOrder";
import { AddSeasons } from "./pages/settings/carrental/seasons/AddSeasons";
import { EditSeasons } from "./pages/settings/carrental/seasons/EditSeasons";
import { AddRates } from "./pages/settings/carrental/rates/AddRates";
import { EditRates } from "./pages/settings/carrental/rates/EditRates";
import { AddUser } from "./pages/settings/usermanagement/users/AddUser";
import EditVehicleClass from "./pages/settings/fleet/vehicleClass/EditVehicleClass";
import { UpdateUsers } from "./pages/settings/usermanagement/users/UpdateUsers";
import AddVehicleClass from "./pages/settings/fleet/vehicleClass/AddVehicleClass";
import AddAdditionalCharges from "./pages/settings/carrental/additionalCharges/AddAdditionalCharges";
import EditAdditionalCharges from "./pages/settings/carrental/additionalCharges/EditAdditionalCharges";
import AddDiscounts from "./pages/settings/carrental/discounts/AddDiscounts";
import EditDiscounts from "./pages/settings/carrental/discounts/EditDiscounts";
import EditReservation from "./pages/carrental/reservations/EditReservation";
import AddSecurityDeposits from "./pages/settings/carrental/securityDeposits/AddSecurityDeposits";
import EditSecurityDeposits from "./pages/settings/carrental/securityDeposits/EditSecurityDeposits";
import { AddEmailTemplate } from "./pages/settings/carrental/emailTemplates/AddEmailTemplate";
import EditEmailTemplate from "./pages/settings/carrental/emailTemplates/EditEmailTemplate";
import { AddVehicleType } from "./pages/settings/fleet/vehicleTypes/AddVehicleType";
import { EditVehicleType } from "./pages/settings/fleet/vehicleTypes/EditVehicleType";
import { AddVehicleModel } from "./pages/settings/fleet/vehicleModel/AddVehicleModel";
import { EditVehicleModel } from "./pages/settings/fleet/vehicleModel/EditVehicleModel";
import { AddVehicleMake } from "./pages/settings/fleet/vehicleMake/AddVehicleMake";
import { EditVehicleMake } from "./pages/settings/fleet/vehicleMake/EditVehicleMake";
import { AddVehicleEngine } from "./pages/settings/fleet/vehicleEngines/AddVehicleEngine";
import { EditVehicleEngine } from "./pages/settings/fleet/vehicleEngines/EditVehicleEngine";
import { AddFleetLocation } from "./pages/settings/fleet/fleetLocations/AddFleetLocation";
import { EditFleetLocation } from "./pages/settings/fleet/fleetLocations/EditFleetLocation";
import Login from "./components/login/Login";
import RequireAuth from "./components/login/RequireAuth";
import { AuthProvider } from "./components/context/AuthProvider";
import PrintQuotes from "./pages/carrental/reservations/PrintQuotes";
import PaymentGatewaysForm from "./pages/settings/paymentGateways/gateways/PaymentGatewaysForm";
import EditPaymentGatewaysForm from "./pages/settings/paymentGateways/gateways/EditPaymentGatewaysForm";
import ForgotPassword from "./components/login/ForgotPassword";
import { AccessControlProvider } from "./components/context/AccessControlProvider";
import UnAuthorize from "./components/login/UnAuthorize";
import Home from "./pages/home/Home";
import Header from "./components/Header";
import { ActiveNavBarProvider } from "./components/context/ActiveNavBarProvider";
import Footer from "./components/Footer";
import Features from "./pages/features/Features";
import Security from "./pages/resources/Security";
import CaseStudies from "./pages/resources/CaseStudies";
import ContactUs from "./pages/resources/ContactUs";
import Faq from "./pages/faq/Faq";
import Blog from "./pages/resources/blog/Blog";
import RentalSoftware from "./pages/resources/blog/RentalSoftware";
import RentalSoftwareBenefits from "./pages/resources/blog/RentalSoftwareBenefits";
import RentalBusinessOperations from "./pages/resources/blog/RentalBusinessOperations";
import ScrollToTop from "./components/ScrollToTop";
import Pricing from "./pages/pricing/Pricing";
import BillPay from "./pages/billPay/BillPay";
import FreeTrial from "./pages/pricing/FreeTrial";
import { RouteType } from "./routes/config";
import appRoutes from "./routes/appRoutes";
import Checkout from "./pages/pricing/Checkout";
import QuickPayView from "./pages/billPay/QuickPayView";
import { DeleteProvider } from "./components/context/DeleteProvider";
import ThankYou from "./pages/pricing/ThankYou";

const Roles = {
  Admin: "Admin",
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        <ActiveNavBarProvider>
        <DeleteProvider>
          <AccessControlProvider>
            <AppWithLocation />
          </AccessControlProvider>
          </DeleteProvider>
        </ActiveNavBarProvider>
      </AuthProvider>
    </Router>
  );
}

function AppWithLocation() {
  const location = useLocation();

    const extractAllPaths = (routes: RouteType[]): string[] => {
      const paths: string[] = [];
      const traverseRoutes = (routes: RouteType[]) => {
        routes.forEach(route => {
          if (route.path) paths.push(route.path);
          if (route.child) traverseRoutes(route.child);
        });
      };
      traverseRoutes(routes);
      return paths;
    };

    const allPaths = extractAllPaths(appRoutes);

    const shouldHideHeaderFooter = allPaths.some(
      path => location.pathname === path || location.pathname.startsWith(`${path}/`)
    );    
    
  return (
    <>
      {!shouldHideHeaderFooter && <Header />}
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/unauthorized" element={<UnAuthorize />} />
         
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route path="/security" element={<Security />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/rental-software" element={<RentalSoftware />} />
          <Route path="/blog/advanced-rental-software" element={<RentalSoftwareBenefits />} />
          <Route path="/blog/rental-business-operations" element={<RentalBusinessOperations />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/bill-pay" element={<BillPay />} />
          <Route path="/quick-pay/:accountNumber" element={<QuickPayView />} />
          <Route path="/free-trial" element={<FreeTrial />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/thank-you" element={<ThankYou />} />
          
            <Route path="login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route element={<RequireAuth roles={[Roles.Admin]} />}>
              <Route path="/" element={<MainLayout />}>
                {routes}
                <Route path="/fleet/vehicle/addvehicles" element={<AddVehicles />} />
                <Route
                  path="/fleet/vehicle/editvehicles"
                  element={<EditVehicles />}
                />
                <Route
                  path="/fleet/repairorders/addrepairorder"
                  element={<AddRepairOrder />}
                />
                <Route
                  path="/fleet/repairorders/editrepairorder"
                  element={<EditRepariOrder />}
                />
                <Route
                  path="/fleet/maintenance/createrepairorder"
                  element={<CreateRepairOrder />}
                />
                <Route
                  path="/contacts/customers/addcustomer"
                  element={<AddCustomer />}
                />
                <Route
                  path="/contacts/customers/editcustomer"
                  element={<EditCustomer />}
                />
                <Route
                  path="/carrental/reservations/addreservation"
                  element={<AddReservation />}
                />
                <Route
                  path="/carrental/reservations/editreservation"
                  element={<EditReservation />}
                />
              </Route>
            </Route>

            <Route element={<RequireAuth roles={[Roles.Admin]} />}>
              <Route path="/admin" element={<AdminMainLayout />}>
                {adminRoutes}
                <Route
                  path="/admin/usermanagement/users/adduser"
                  element={<AddUser />}
                />
                <Route
                  path="/admin/usermanagement/users/edituser"
                  element={<UpdateUsers />}
                />
                <Route
                  path="/admin/fleet/branches/addbranch"
                  element={<AddBranch />}
                />
                <Route
                  path="/admin/fleet/branches/editbranch"
                  element={<EditBranch />}
                />
                <Route
                  path="/admin/fleet/vehicleclasses/addvehicleclasses"
                  element={<AddVehicleClass />}
                />
                <Route
                  path="/admin/fleet/vehicleclasses/editvehicleclasses"
                  element={<EditVehicleClass />}
                />
                <Route
                  path="/admin/carrental/seasons/addseason"
                  element={<AddSeasons />}
                />
                <Route
                  path="/admin/carrental/seasons/editseason"
                  element={<EditSeasons />}
                />
                <Route
                  path="/admin/carrental/rates/addrates"
                  element={<AddRates />}
                />
                <Route
                  path="/admin/carrental/rates/editrates"
                  element={<EditRates />}
                />
                <Route
                  path="/admin/carrental/additionalcharges/addadditionalCharges"
                  element={<AddAdditionalCharges />}
                />
                <Route
                  path="/admin/carrental/additionalcharges/editadditionalCharges"
                  element={<EditAdditionalCharges />}
                />
                <Route
                  path="/admin/carrental/discounts/adddiscount"
                  element={<AddDiscounts />}
                />
                <Route
                  path="/admin/carrental/discounts/editdiscount"
                  element={<EditDiscounts />}
                />
                <Route
                  path="/admin/carrental/securitydeposits/addsecuritydeposit"
                  element={<AddSecurityDeposits />}
                />
                <Route
                  path="/admin/carrental/securitydeposits/editsecuritydeposit"
                  element={<EditSecurityDeposits />}
                />
                <Route
                  path="/admin/carrental/emailtemplates/addemailtemplate"
                  element={<AddEmailTemplate />}
                />
                <Route
                  path="/admin/carrental/emailTemplates/editTemplates"
                  element={<EditEmailTemplate />}
                />
                <Route
                  path="/admin/fleet/vehicletypes/addvehicletype"
                  element={<AddVehicleType />}
                />
                <Route
                  path="/admin/fleet/vehicleTypes/editVehicleType"
                  element={<EditVehicleType />}
                />
                <Route
                  path="/admin/fleet/vehiclemake/addvehiclemake"
                  element={<AddVehicleMake />}
                />
                <Route
                  path="/admin/fleet/vehiclemake/editVehicleMake"
                  element={<EditVehicleMake />}
                />
                <Route
                  path="/admin/fleet/vehiclemodel/addvehiclemodel"
                  element={<AddVehicleModel />}
                />
                <Route
                  path="/admin/fleet/vehiclemodel/editvehiclemodel"
                  element={<EditVehicleModel />}
                />
                <Route
                  path="/admin/fleet/vehicleengines/addvehicleengine"
                  element={<AddVehicleEngine />}
                />
                <Route
                  path="/admin/fleet/vehicleengines/editvehicleengine"
                  element={<EditVehicleEngine />}
                />
                <Route
                  path="/admin/fleet/features/addfeature"
                  element={<AddFeature />}
                />
                <Route
                  path="/admin/fleet/features/editfeature"
                  element={<EditFeature />}
                />
                <Route
                  path="/admin/fleet/fleetlocations/addfleetlocation"
                  element={<AddFleetLocation />}
                />
                <Route
                  path="/admin/fleet/fleetlocations/editfleetlocation"
                  element={<EditFleetLocation />}
                />
                <Route
                  path="/admin/paymentgateways/paymentgatewaysform"
                  element={<PaymentGatewaysForm />}
                />
                <Route
                  path="/admin/paymentgateways/editpaymentgatewaysform"
                  element={<EditPaymentGatewaysForm />}
                />
              </Route>
            </Route>
          
          <Route
            path="/carrental/reservations/print-quote"
            element={<PrintQuotes />}
          />

        </Routes>
        {!shouldHideHeaderFooter && <Footer />}
        </>
  );
}

export default App;