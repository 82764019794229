import { useState } from 'react';
import HeroSection from '../../components/HeroSection';
import axios from 'axios';
import baseURL from '../../configs/api';
import Input from '../../components/Input';

const ContactUs = () => {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const onChangePhoneNumber = (value: string) => {
    const numericValue = value.replace(/[^0-9]+/g, "");
    const trimmedValue = numericValue.slice(0, 10);

    if (trimmedValue.length <= 3) {
      return setPhone(trimmedValue);
    } else if (trimmedValue.length <= 6) {
      return setPhone(`(${trimmedValue.slice(0, 3)}) ${trimmedValue.slice(3)}`);
    } else if (trimmedValue.length <= 10) {
      return setPhone(`(${trimmedValue.slice(0, 3)}) ${trimmedValue.slice(3, 6)}-${trimmedValue.slice(6)}`);
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isProcessing) {
      setIsProcessing(true);
      await axios
        .post(
          `${baseURL}customerEnquiry`,
          JSON.stringify({
            email,
            name,
            question,
            phone,
            created: new Date(),
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          resetForm();
          setIsProcessing(false);
          setSubmitted(true);
        })
        .catch((error) => {
          setIsProcessing(false);
          console.error("Error:", error);
        });
    }
  };

  const resetForm = () => {
    setEmail("");
    setName("");
    setPhone("");
    setQuestion("");
  };

  return (
    <div>
      <HeroSection
        mainHeading="Contact Us"
        backgroundImage="../images/contact-us-rd-rental.png"
      />

      <section style={{ width: '100%', paddingBottom: '10px' }}>
        <div style={{ maxWidth: '1200px', margin: 'auto', padding: '1rem' }}>
          <div style={{ padding: '2rem', textAlign: 'center' }}>
            <p
              style={{
                color: '#295B7E',
                fontSize: '2rem',
                fontWeight: 'bold',
                marginBottom: '1rem',
              }}
            >
              Open a Ticket with Customer Support
            </p>
            <h2
              style={{
                fontSize: '1rem',
                paddingTop: '0.5rem',
                marginBottom: '1px',
                padding: '0 1rem',
                textAlign: 'center',
              }}
            >
              We try our best to reply within 24-48 business hours.
            </h2>
          </div>
        </div>

        <div style={{ maxWidth: '1200px', margin: 'auto', padding: '1rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                width: '100%',
                padding: '1rem',
                textAlign: 'center',
                borderBottom: '1px solid #ddd',
              }}
            >
              <h2
                style={{
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  paddingTop: '0.5rem',
                  marginBottom: '1rem',
                }}
              >
                Spring Location
              </h2>
              <p style={{ fontSize: '0.875rem', color: '#6c757d' }}>
                24624 Interstate 45 N Suite 200,<br />
                Spring, TX 77386
              </p>
              <iframe
                title="Google Map Spring"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3451.0952715809685!2d-95.44079649999999!3d30.1200872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8647343421fd0135%3A0xa8a51ce8ee769957!2s24624%20Interstate%2045%20N%20Suite%20200%2C%20Spring%2C%20TX%2077386%2C%20USA!5e0!3m2!1sen!2sin!4v1699002913944!5m2!1sen!2sin"
                style={{
                  width: '100%',
                  height: '250px',
                  border: '0',
                }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div
              style={{
                width: '100%',
                padding: '1rem',
                textAlign: 'center',
              }}
            >
              <h2
                style={{
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  paddingTop: '0.5rem',
                  marginBottom: '1rem',
                }}
              >
                Sugar Land Location
              </h2>
              <p style={{ fontSize: '0.875rem', color: '#6c757d' }}>
                54 Sugar Creek Center Blvd STE 300,<br />
                Sugar Land, TX 77478
              </p>
              <iframe
                title="Google Map Sugar Land"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3468.397953022852!2d-95.60810362544842!3d29.62118303850247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640e6ff0ed3930f%3A0x76bf2c10013f2a4b!2s54%20Sugar%20Creek%20Center%20Blvd%20STE%20300%2C%20Sugar%20Land%2C%20TX%2077478%2C%20USA!5e0!3m2!1sen!2sin!4v1699003331966!5m2!1sen!2sin"
                style={{
                  width: '100%',
                  height: '250px',
                  border: '0',
                }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section style={{ width: '100%', backgroundColor: '#fff', paddingTop: '2.5rem' }}>
        <div style={{ maxWidth: 'screen-lg', margin: 'auto', padding: '2rem' }}>
          <h1 style={{ color: '#295B7E', fontSize: '2rem', padding: '0 1rem', fontWeight: 'bold', marginTop: '2rem', marginBottom: '4rem', textAlign: 'center' }}>
            Drop us a line. We'd love to hear from you!
          </h1>
          {submitted && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ textAlign: 'center' }}>
                <p style={{ marginBottom: '1rem', padding: '0 1.5rem', fontWeight: 'bold', color: '#295B7E' }}>
                  Thank you! We'll be in touch soon.
                </p>
              </div>
            </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '3rem' }}>
            <form
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '75%' }}
              onSubmit={handleSubmit}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Input
                  type="text"
                  label="Name"
                  pattern=""
                  title=""
                  inputValue={name}
                  setInputValue={setName}
                />

                <Input
                  type="email"
                  label="Email Address"
                  pattern=""
                  title=""
                  inputValue={email}
                  setInputValue={setEmail}
                />
              </div>

              <Input
                type="text"
                label="Phone Number"
                pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                title="Please Enter a 10 digits numbers."
                inputValue={phone}
                setInputValue={onChangePhoneNumber}
              />

              <div style={{ width: '100%' }}>
                <div style={{ position: 'relative', width: '100%' }}>
                  <textarea
                    name="question"
                    style={{
                      width: '100%',
                      height: '150px',
                      borderRadius: '7px',
                      border: '1px solid #ddd',
                      backgroundColor: 'transparent',
                      padding: '0.5rem',
                      fontSize: '1rem',
                    }}
                    rows={6}
                    placeholder=" "
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    required
                  />
                  <label
                    htmlFor="question"
                    style={{
                      position: 'absolute',
                      top: '-1.5rem',
                      left: '0',
                      fontSize: '0.6875rem',
                      fontWeight: 'normal',
                    }}
                  >
                    Question
                  </label>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  type="submit"
                  name="Send"
                  style={{
                    border: 'none',
                    backgroundColor: '#295B7E',
                    color: '#fff',
                    fontWeight: 'bold',
                    padding: '1rem 2rem',
                    borderRadius: '0.25rem',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                >
                  {isProcessing ? "Processing..." : "Send Message"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;