type DetailsTagProps = {
    title: string;
    value: string;
    bg?: string;
}

const DetailsTag = ({ title, value, bg }: DetailsTagProps) => {
    return (
        <div style={{ flex: bg ? "unset" : "1" }}>
        <h5 style={{ fontWeight: 500, color: "black", paddingTop: "1px" }}>{title}</h5>
        <p
          style={{
            backgroundColor: bg || "transparent",
            padding: bg ? "0.125rem 0.5rem" : "0 0",
            width: bg ? "fit-content" : "auto",
            borderRadius: bg ? "0.25rem" : "0",
            color: bg ? "white" : "inherit",
            fontWeight: bg ? 500 : 400,
            fontSize: "0.875rem",
          }}
        >
          {value}
        </p>
      </div>
      
    )
}

export default DetailsTag