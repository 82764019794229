import { useEffect, useState } from 'react';
import { getFaqsByIsRentalSoftware } from '../../services/Faq';
import HeroSection from '../../components/HeroSection';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

function Faq() {
    const [faqData, setFaqData] = useState<any[]>([]);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [isMobile, setIsMobile] = useState(false);
    const isRentalSoftware = true;

    useEffect(() => {
        getFaqsByIsRentalSoftware(isRentalSoftware)
            .then(data => setFaqData(data))
            .catch(error => console.error('Error fetching FAQ data:', error));
        const checkScreenSize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', checkScreenSize);
        checkScreenSize();
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, [isRentalSoftware]);

    const containerStyle = {
        backgroundColor: '#f8f8f8',
    };

    const gridContainerStyle = {
        display: 'grid',
        gap: '1.25rem',
        gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)',
        padding: '2rem',
    };

    const spinnerContainerStyle = {
        position: 'fixed' as const,
        top: '6rem',
        left: '0',
        right: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    };

    const faqCardStyle = {
        backgroundColor: '#fff',
        width: '100%',
        borderRadius: '0.375rem',
        padding: '1.25rem',
        marginBottom: '1.25rem',
        border: '1px solid #e5e7eb',
        transition: 'all 0.3s ease-in-out',
        cursor: 'pointer',
    };

    const faqCardHoverStyle = {
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
    };

    const iconStyle = {
        color: '#295B7E',
        width: '1.5rem',
        height: '1.5rem',
        verticalAlign: 'middle',
    };

    const headingStyle = {
        color: '#295B7E',
        fontSize: '1.125rem',
        fontWeight: '600',
    };

    const answerStyle = {
        fontSize: '1rem',
        marginLeft: '2.5rem',
        opacity: '0.7',
    };

    return (
        <div style={containerStyle}>
            <HeroSection
                mainHeading="FAQ"
                subHeading="See frequently asked questions about our services"
                backgroundImage="../images/faq-rd-rental.png"
            />

            <div style={gridContainerStyle}>
                {faqData.length === 0 ? (
                    <div style={spinnerContainerStyle}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="animate-spin text-4xl text-primaryVariant"
                            style={{ width: '3rem', height: '3rem' }}
                        >
                            <circle
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                                className="stroke-current text-gray-500"
                                fill="none"
                                strokeDasharray="80"
                                strokeDashoffset="60"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                ) : (
                    faqData.map((item, index) => (
                        <div
                            key={item.id}
                            style={{
                                ...faqCardStyle,
                                ...(hoveredIndex === index ? faqCardHoverStyle : {}),
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '1rem', marginBottom: '0.25rem' }}>
                                <p style={{ paddingTop: '0.25rem' }}>
                                    <ContactSupportIcon style={iconStyle} />
                                </p>
                                <h3 style={headingStyle}>{item.question}</h3>
                            </div>
                            <p style={answerStyle}>{item.answer}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default Faq;
