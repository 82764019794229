import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { getGroup } from '../../services/Group';
import useAuth from '../../components/hooks/useAuth';
import { post_UserLogin } from '../../services/Login';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import colorConfigs from '../../configs/colorConfigs';

const BillPayLogin = () => {
  const navigate = useNavigate();
  const { setAuth }: any = useAuth();
  const [passwordTextType, setPasswordTextType] = useState<boolean>(false);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(true);
  const [userData, setUserData] = useState<any>({ userName: "", password: "" });
  const [errorMessage, setErroMessage] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isProcessing) {
      setIsProcessing(true);
      post_UserLogin({
        userName: userData.userName,
        password: userData.password
      })
        .then(res => {
          const jsonData: any = jwtDecode(res);
          const groupId = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"]
          getGroupData(groupId, res);
        })
        .catch(error => {
          setIsProcessing(false);
          setErroMessage(error.response.data);
          console.error(error);
        });
    }
  }

  const getGroupData = (id: string, accessToken: string) => {
    getGroup(id)
      .then(res => {
        const adminUserId = res.adminUserId;
        const jsonData: any = jwtDecode(accessToken);
        const id = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
        const roles = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        const givenname = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
        const emailAddress = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
        const groupId = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"]
        const userName = userData.userName;

        let IsAdmin = false;

        if (parseInt(adminUserId) === parseInt(id)) {
          IsAdmin = true;
        }

        if (isRememberMe) {
          Cookies.set("token-jj-rental-ui", accessToken, {
            expires: jsonData.exp,
          });
          Cookies.set("role", roles, {
            expires: jsonData.exp,
          });
          Cookies.set("groupId-jj-rental-ui", groupId, {
            expires: jsonData.exp,
          });
          Cookies.set("userId-jj-rental-ui", id, {
            expires: jsonData.exp,
          })
          Cookies.set("isAdmin-jj-rental-ui", IsAdmin ? "1" : "0", {
            expires: jsonData.exp,
          });
        } else {
          Cookies.set("token-jj-rental-ui", accessToken, {
            expires: new Date().setHours(new Date().getHours() + 1)
          });
          Cookies.set("role", roles, {
            expires: new Date().setHours(new Date().getHours() + 1)
          });
          Cookies.set("groupId-jj-rental-ui", groupId, {
            expires: new Date().setHours(new Date().getHours() + 1)
          });
          Cookies.set("userId-jj-rental-ui", IsAdmin ? "1" : "0", {
            expires: jsonData.exp,
          });
          Cookies.set("isAdmin-jj-rental-ui", id, {
            expires: jsonData.exp,
          });
        }

        setAuth({ id, userName, givenname, emailAddress, roles, accessToken, groupId, IsAdmin: IsAdmin ? "1" : "0" });
        setUserData({ userName: "", password: "" });
        setIsProcessing(false);
        navigate("/bill-details");
      })
      .catch(error => {
        setIsProcessing(false);
        setErroMessage("Please username and password");
        console.error(error);
      });
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ maxWidth: '28rem', width: '100%', margin: '0 auto' }}>
        <form
          style={{
            backgroundColor: '#fff',
            borderRadius: '1rem',
            padding: '1.5rem',
            border: '1px solid #1a1a1a',
          }}
          onSubmit={handleSubmit}
        >
          <div style={{ marginBottom: '2.5rem' }}>
            <h3 style={{ fontSize: '1.875rem', fontWeight: '800' }}>Admin Sign in</h3>
          </div>
          <div>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                name="email"
                type="text"
                required
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  fontSize: '0.875rem',
                  borderBottom: '1px solid',
                  borderColor: colorConfigs.primaryColor,
                  padding: '0.75rem 0.5rem',
                  outline: 'none',
                  color: 'black',
                }}
                placeholder="Enter username"
                value={userData.userName}
                onChange={(e) =>
                  setUserData({ ...userData, userName: e.target.value })
                }
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#bbb"
                stroke="#bbb"
                style={{
                  width: '18px',
                  height: '18px',
                  position: 'absolute',
                  right: '1rem',
                }}
                viewBox="0 0 24 24"
              >
                <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                <path
                  d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                  data-original="#000000"
                ></path>
              </svg>
            </div>
          </div>
          <div style={{ marginTop: '2rem' }}>
            <div style={{ position: 'relative', alignItems: 'center' }}>
              <input
                name="password"
                type={passwordTextType ? 'text' : 'password'}
                required
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  fontSize: '0.875rem',
                  borderBottom: '1px solid',
                  borderColor: colorConfigs.primaryColor,
                  padding: '0.75rem 0.5rem',
                  outline: 'none',
                  color: 'black',
                }}
                placeholder="Enter password"
                value={userData.password}
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
              />
              <div
                style={{
                  position: "absolute",
                  right: "1rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => setPasswordTextType((prev) => !prev)}
              >
                {passwordTextType ? (
                  <VisibilityOff style={{ cursor: 'pointer', color: '#bbb' }} />
                ) : (
                  <Visibility style={{ cursor: 'pointer', color: '#bbb' }} />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '0.5rem',
              marginTop: '1.5rem',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                style={{
                  height: '16px',
                  width: '16px',
                  border: '1px solid #d4d4d4',
                  borderRadius: '4px',
                }}
                checked={isRememberMe}
                onChange={() => setIsRememberMe(!isRememberMe)}
              />
              <label
                htmlFor="remember-me"
                style={{ marginLeft: '0.75rem', fontSize: '0.875rem' }}
              >
                Remember me
              </label>
            </div>
            <div>
              <Link
                to="/forgot-password"
                style={{
                  fontSize: '0.875rem',
                  fontWeight: '600',
                  textDecoration: 'underline',
                }}
              >
                Forgot Password?
              </Link>
            </div>
          </div>
          <div style={{ marginTop: '2.5rem' }}>
            <button
              type="submit"
              style={{
                width: '100%',
                padding: '0.625rem 1rem',
                fontSize: '0.875rem',
                fontWeight: '600',
                borderRadius: '1rem',
                color: '#fff',
                backgroundColor: '#1a1a1a',
                cursor: 'pointer',
              }}
            >
              {isProcessing ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    fill="#fff"
                    style={{ marginRight: '0.5rem', display: 'inline-block' }}
                    className="animate-spin"
                    viewBox="0 0 26.349 26.35"
                  >
                    <circle cx="..." />
                    <circle cx="..." />
                    <circle cx="..." />
                    {/* Loader Details */}
                  </svg>
                  Loading...
                </>
              ) : (
                'Sign in'
              )}
            </button>
          </div>
          {errorMessage && (
            <p
              style={{
                fontSize: '0.875rem',
                marginTop: '2.5rem',
                color: 'red',
                textAlign: 'center',
              }}
            >
              {errorMessage}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

export default BillPayLogin;
