import { Link } from 'react-router-dom';
import '../styles/GetStartedFreeTrial.css';

const GetStartedFreeTrial = () => {
    return (
        <div>
            <section className="get-started-section">
                <div className="content-container">
                    <div className="left-column">
                        <h1 className="headline">Get Started with Seamless Rental Management Today</h1>
                        <p className="description">
                            Streamline your rental process with RD Rental efficient management system.
                            Optimize operations, manage bookings effortlessly, and provide exceptional
                            customer experiences with ease. Try RD Rental for free today and discover
                            the simplicity of seamless rental management firsthand!
                        </p>
                    </div>

                    <div className="right-column">
                        <Link to="/free-trial" className="cta-button">
                            Start Your Free Trial
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default GetStartedFreeTrial;
