import axios from "axios";
import baseURL from "../configs/api";

export const post_UserLogin = (data: any) => {
    return axios({
        url: `${baseURL}AuthUser/login`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}
