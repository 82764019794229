import Select from 'react-select';
import { useState } from 'react';

type Props = {
  type: string;
  label: string;
  pattern: string;
  title: string;
  inputValue: string;
  setInputValue: (inputValue: string) => void;
  autoComplete?: string;
  options?: { value: string; label: string }[];
};

const Input = ({
  type,
  label,
  pattern,
  title,
  inputValue,
  setInputValue,
  autoComplete,
  options,
}: Props) => {
  const isReactSelect = type === 'react-select';
  const [isFocused, setIsFocused] = useState(false);

  const commonInputStyle = {
    height: '100%',
    width: '100%',
    borderRadius: '7px',
    border: isFocused ? '2.5px solid #295B7E' : '1px solid #374151',
    backgroundColor: 'transparent',
    padding: '5px 4px',
    fontFamily: 'sans-serif',
    fontSize: '0.900rem',
    fontWeight: '500',
    color: '#374151',
    outline: 'none',
    transition: 'all 0.2s',
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          paddingTop: '8px',
          paddingBottom: '8px',
          marginBottom: '1.5rem',
        }}
      >
        {isReactSelect ? (
          <Select
            name={label}
            value={options?.find(option => option.value === inputValue)}
            onChange={(selectedOption) =>
              setInputValue(selectedOption ? selectedOption.value : '')
            }
            options={options}
            placeholder={`${label}`}
            isSearchable={true}
            styles={{
              control: (styles) => ({
                ...styles,
                ...commonInputStyle,
              }),
            }}
          />
        ) : type === 'select' ? (
          <select
            name={label}
            style={{
              ...commonInputStyle,
              fontSize: '0.875rem',
              padding: '0.625rem 1rem',
            }}
            value={inputValue}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => setInputValue(e.target.value)}
            required
          >
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            name={label}
            autoComplete={autoComplete}
            style={{
              ...commonInputStyle,
              padding: '0.625rem 1rem',
              fontSize: '0.875rem',
              height: '2.5rem',
            }}
            placeholder=" "
            pattern={pattern ? pattern : undefined}
            title={title}
            value={inputValue}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => setInputValue(e.target.value)}
            required
          />
        )}
        {(type !== 'react-select' && type !== 'select') && (
          <label
            htmlFor={label}
            style={{
              position: 'absolute',
              top: '-1.2rem',
              left: '0',
              fontSize: '0.9rem',
              fontWeight: 'normal',
              pointerEvents: 'none',
              transition: 'all 0.2s',
            }}
          >
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

export default Input;