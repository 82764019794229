import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import useDelete from '../../../components/hooks/useDelete';
import { delete_AchInfo, getACHInfoWithGroupId } from '../../../services/ACHInfo';
import Pagination from '../../../components/Pagination';

const heading = ["#", "Account Number", "Account Type", "Name", "Routing Number", "Phone Number"];

type ACHDetailsProps = {
    isRefresh: boolean;
    setIsRefresh: (value: boolean) => void;
}

const ACHDetails = ({ isRefresh, setIsRefresh }: ACHDetailsProps) => {
    const userId = Cookies.get("userId-jj-rental-ui");
    const groupId = Cookies.get("groupId-jj-rental-ui");
    const { setDeletePopup, setData: setDeleteData }: any = useDelete();
    const [achData, setAChData] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
        page: 0,
        size: 5
    });
    const [totalLength, setTotalLength] = useState<number>(0);
    const [showEntries, setShowEntries] = useState<number>(5);

    useEffect(() => {
        if (groupId && isRefresh) {
            setIsRefresh(false);
            getACHInfoWithGroupId(groupId, paginationModel.page, paginationModel.size)
                .then(res => {
                    setTotalLength(res?.count);
                    setAChData(res?.groupACHInfo);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, isRefresh])

    const deleteACH = (primaryKey: number) => {
        setDeleteData({
            id: primaryKey,
            tilte: `Delete`,
            summary: `Are you sure you want to delete this ACH Info?`,
            isDelete: (callback: any) => {
                if (userId)
                    delete_AchInfo(primaryKey, userId?.toString())
                        .then(() => {
                            setIsRefresh(true);
                            callback();
                        })
                        .catch(error => console.error(error))
            }
        });

        setDeletePopup(true);
    }

    return (
        <div
            style={{
                width: "100%",
                border: "1px solid #295B7E",
                borderRadius: "0.375rem",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "50px",
                    padding: "0.5rem 1rem",
                    backgroundColor: "#295B7E",
                    color: "#fff",
                }}
            >
                <h4
                    style={{
                        fontWeight: 500,
                        color: "#fff",
                    }}
                >
                    ACH Info
                </h4>
            </div>
    
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    gap: "0.75rem",
                }}
            >
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                        <tr style={{ fontSize: "0.875rem", textAlign: "start", borderBottom: "2px solid #295B7E" }}>
                            {heading.map((item, index) => (
                                <th
                                    key={index}
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0 0.125rem",
                                    }}
                                >
                                    {item}
                                </th>
                            ))}
                            <th style={{ textAlign: "start", padding: "0 0.125rem", color: "#295B7E", fontWeight: "bold" }}></th>
                        </tr>
                    </thead>
    
                    <tbody>
                        {achData?.map((item: any, index: number) => (
                            <tr
                                key={index}
                                style={{
                                    fontSize: "0.875rem",
                                    textAlign: "start",
                                }}
                            >
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0.375rem 0.125rem",
                                    }}
                                >
                                    {item?.id}
                                </td>
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0.375rem 0.125rem",
                                    }}
                                >
                                    {item?.accountNumber}
                                </td>
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0.375rem 0.125rem",
                                    }}
                                >
                                    {item?.accountType}
                                </td>
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0.375rem 0.125rem",
                                    }}
                                >
                                    {item?.bankName}
                                </td>
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0.375rem 0.125rem",
                                    }}
                                >
                                    {item?.routingNumber}
                                </td>
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0.375rem 0.125rem",
                                    }}
                                >
                                    {item?.bankPhone}
                                </td>
                                <td
                                    style={{
                                        textAlign: "start",
                                        borderBottom: "1px solid #295B7E",
                                        padding: "0.375rem 0.125rem",
                                        color: "#295B7E",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        userSelect: "none",
                                    }}
                                    onClick={() => deleteACH(item?.id)}
                                    onMouseOver={(e) => (e.currentTarget.style.color = "#003f62")}
                                    onMouseOut={(e) => (e.currentTarget.style.color = "#295B7E")}
                                >
                                    {"X"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
    
                <Pagination
                    totalLength={totalLength}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    setIsRefresh={setIsRefresh}
                    showEntries={showEntries}
                    setShowEntries={setShowEntries}
                />
            </div>
        </div>
    );    
}

export default ACHDetails
