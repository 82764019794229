import axios from "axios";
import baseURL from "../configs/api";

export const getInvoicesByGroupId = (groupId: string, page: number, size: number) => {
    return getFetch(`invoice/GetInvoicesPagingByGroupId/${groupId},${page},${size}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}