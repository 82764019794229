const heading = ["#", "Subject", "Unused"];

const Support = () => {
    return (
        <div
            style={{
                width: "100%",
                border: "1px solid #295B7E",
                borderRadius: "0.375rem",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "50px",
                    padding: "0.5rem 1rem",
                    backgroundColor: "#295B7E",
                    color: "#fff",
                }}
            >
                <h4
                    style={{
                        fontWeight: 500,
                        color: "#fff",
                    }}
                >
                    Support
                </h4>
            </div>

            <div style={{ display: "flex", flexDirection: "column", padding: "1rem", gap: "0.75rem" }}>
                <table style={{ width: "100%" }}>
                    <thead style={{ borderBottom: "2px solid #295B7E" }}>
                        <tr style={{ fontSize: "0.875rem", textAlign: "left" }}>
                            {heading.map((item, index) => (
                                <th key={index} style={{ borderRight: "1px solid #295B7E", textAlign: "left", padding: "0.125rem" }}>
                                    {item}
                                </th>
                            ))}
                            <th style={{ textAlign: "left", padding: "0.125rem 0.375rem", color: "blue", fontWeight: "bold", userSelect: "none" }}></th>
                        </tr>
                    </thead>

                    <tbody style={{ borderBottom: "1px solid #295B7E" }}>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Support;
