import { useEffect, useState } from 'react'
import DetailsTag from './DetailsTag'
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { getGroupCreditCardWithGroupIdAndIsActive } from '../../../services/GroupCreditCard';
import { getPlan } from '../../../services/Plan';
import { getSubscriptionByGroupId } from '../../../services/Subscription';

type SubscriptionDetailsProps = {
    setShowPopupUpgradeSubscription: (value: boolean) => void;
    planDetails: any;
    setPlanDetails: (value: any) => void;
    isRefreshSubscriptionDetails: boolean;
    setIsRefreshSubscriptionDetails: (value: boolean) => void;
    setActiveCreditCardDetails: (value: any | null) => void;
}

const SubscriptionDetails = ({ setShowPopupUpgradeSubscription, planDetails, setPlanDetails, isRefreshSubscriptionDetails, setIsRefreshSubscriptionDetails, setActiveCreditCardDetails }: SubscriptionDetailsProps) => {
    const groupId = Cookies.get("groupId-jj-rental-ui");
    const [subscriptionDetails, setSubscriptionDetails] = useState<any>({
        planId: "",
        status: "",
        startDate: "",
        endDate: ""
    });

    useEffect(() => {
        if (groupId && isRefreshSubscriptionDetails) {
            setIsRefreshSubscriptionDetails(false);
            getSubscriptionByGroupId(groupId)
                .then(res => {
                    if (res?.planId) {
                        getPlan(res?.planId)
                            .then(res => {
                                setPlanDetails(res);
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }

                    setSubscriptionDetails({
                        ...res,
                        startDate: res?.startDate ? format(new Date(res?.startDate), "dd/MM/yyyy") : "",
                        endDate: res?.endDate ? format(new Date(res?.endDate), "dd/MM/yyyy") : ""
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, isRefreshSubscriptionDetails])

    const upgradePlan = () => {
        if (groupId) {
            getGroupCreditCardWithGroupIdAndIsActive(groupId?.toString())
                .then(res => {
                    setActiveCreditCardDetails(res);
                })
                .catch(error => {
                    setActiveCreditCardDetails(null);
                    console.log(error);
                });
        }

        setShowPopupUpgradeSubscription(true);
    }

    const addSupportCredit = () => {

    }

    return (
        <div
            style={{
                width: "100%",
                border: "1px solid #295B7E",
                borderRadius: "0.375rem", 
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "50px",
                    padding: "0.5rem 1rem",
                    backgroundColor: "#295B7E",
                    color: "#fff",
                }}
            >
                <h4
                    style={{
                        fontWeight: 500,
                        color: "#fff",
                    }}
                >
                    Subscription Details
                </h4>
            </div>

            <div style={{ display: "flex", flexDirection: "column", padding: "1rem", gap: "0.75rem" }}>

                <div style={{ display: "flex", gap: "1rem" }}>
                    <DetailsTag
                        title="Billing Plan"
                        value={`${planDetails?.planName} $${planDetails?.price ? planDetails?.price?.toFixed(2) : "0.00"}/${planDetails?.billingCycle}`}
                    />

                    <div style={{ width: "16.6667%" }}>
                        <DetailsTag
                            title="Status"
                            value={subscriptionDetails?.status}
                            bg="#4ade80"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", gap: "1rem" }}>
                    <DetailsTag
                        title="Start Date"
                        value={subscriptionDetails?.startDate}
                    />

                    <DetailsTag
                        title="Next Invoice Date"
                        value={subscriptionDetails?.endDate}
                    />
                </div>

                <div style={{ display: "flex", gap: "1rem" }}>
                    <button
                        style={{
                            backgroundColor: "#16a34a",
                            borderRadius: "0.375rem",
                            padding: "0.375rem 1rem",
                            fontWeight: "500",
                            color: "white",
                             cursor: "pointer"
                        }}
                        onClick={upgradePlan}
                    >
                        Upgrade
                    </button>

                    <button
                        style={{
                            backgroundColor: "#295B7E",
                            borderRadius: "0.375rem",
                            padding: "0.375rem 1rem",
                            fontWeight: "500",
                            color: "white",
                             cursor: "pointer"
                        }}
                        onClick={addSupportCredit}
                    >
                        Add Support Credits
                    </button>
                </div>

            </div>
        </div>
    )
}

export default SubscriptionDetails
