import React, { useEffect, useState } from 'react'
import '../../../styles/Billing.css';
import BillingAddress from './BillingAddress';
import Cookies from 'js-cookie';
import SubscriptionDetails from './SubscriptionDetails';
import Support from './Support';
import Invoice from './Invoice';
import ACHDetails from './ACHDetails';
import CreditCardDetails from './CreditCardDetails';
import UpdateBillingAddress from './UpdateBillingAddress';
import AddCreditCard from './AddCreditCard';
import UpgradeSubscription from './UpgradeSubscription';
import { post_Subscription } from '../../../services/Subscription';
import moment from 'moment';
import { post_PaymentMethod } from '../../../services/PaymentMethod';
import UpgradePlan from './UpgradePlan';
import { post_GroupPayment } from '../../../services/GroupPayment';

const Billing = () => {
  const userId = Cookies.get("userId-jj-rental-ui");
  const groupId = Cookies.get("groupId-jj-rental-ui");
  const [showPopupAddNewCreditCard, setShowPopupAddNewCreditCard] = useState<boolean>(false);
  const [showPopupUpdateBillingAddress, setShowPopupUpdateBillingAddress] = useState<boolean>(false);
  const [showPopupUpgradeSubscription, setShowPopupUpgradeSubscription] = useState<boolean>(false);
  const [billingAddressData, setBillingAddressData] = useState<any>({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    street: "",
    street2: "",
    zip: "",
    state: "",
    city: "",
    country: ""
  });
  const [isRefreshCreditCardDetails, setIsRefreshCreditCardDetails] = useState<boolean>(true);
  const [planDetails, setPlanDetails] = useState<any | null>(null)
  const [showPopupForPayment, setShowPopupForPayment] = useState<boolean>(false);
  const [upgradPlan, setUpgradePlan] = useState<any | null>(null);
  const [isRefreshSubscriptionDetails, setIsRefreshSubscriptionDetails] = useState<boolean>(true);

  const [isRefreshACHDetails, setIsRefreshACHDetails] = useState<boolean>(true);
  const [activeCreditCardDetails, setActiveCreditCardDetails] = useState<any | null>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [paymentType, setPaymentType] = useState<number>(0);
  const [isCreditCardDataSaved, setIsCreditCardDataSaved] = useState<boolean>(false);
  const [isSubscriptionDataSaved, setIssubscriptionDataSaved] = useState<boolean>(false);
  const [isPaymentMethodDataSaved, setIsPaymentMethodDataSaved] = useState<boolean>(false);
  const [isPaymentDataSaved, setIsPaymentDataSaved] = useState<boolean>(false);
  const [subscritionId, setSubscriptionId] = useState<number>(0);
  const [paymentMethodId, setPaymentMethodId] = useState<number>(0);
  const [cbRef, setCBRef] = useState<(() => void) | null>(null);

  const callTheAPIForUpgradeSubscription = (upgradedPlan: any, creditCardDetails: any, callback: any) => {
    setCBRef(() => callback);

    post_Subscription({
      planId: upgradedPlan?.id,
      startDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      endDate: upgradedPlan?.billingCycle?.toLowerCase() === 'month' ? moment().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss.SSS') : moment().add(1, 'year').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      status: "Active",
      createdAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      createdBy: userId,
      userId: userId,
      groupId: groupId
    })
      .then((res) => {
        setSubscriptionId(res);
        setIssubscriptionDataSaved(true);
      })
      .catch(error => {
        console.log(error);
        setIsProcessing(false);
      })

    post_PaymentMethod({
      methodType: paymentType === 0 ? "Credit Card" : "Bank ACH",
      details: paymentType === 0 ? "Online Payment" : "Bank Transfer",
      expiryDate: creditCardDetails?.expirationDate,
      createdAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      createdBy: userId,
      userId: userId,
      groupId: groupId
    })
      .then((res) => {
        setPaymentMethodId(res);
        setIsPaymentMethodDataSaved(true);
      })
      .catch(error => {
        console.log(error);
        setIsProcessing(false);
      })
  }

  useEffect(() => {
    if (isPaymentMethodDataSaved && isSubscriptionDataSaved) {
      post_GroupPayment({
        subscriptionId: subscritionId,
        paymentType: paymentType === 0 ? "Online Payment" : "Bank Transfer",
        dateofPayment: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        amount: upgradPlan?.price,
        reference: "",
        status: "Pending",
        createdAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        createdBy: userId,
        syncedWithAccounting: false,
        paymentMethodId: paymentMethodId,
        groupId: groupId
      })
        .then(() => {
          setIsPaymentMethodDataSaved(false);
          setIssubscriptionDataSaved(false);
          setIsPaymentDataSaved(true);
        })
        .catch(error => {
          console.log(error);
          setIsProcessing(false);
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentMethodDataSaved, isSubscriptionDataSaved])

  useEffect(() => {
    if (isCreditCardDataSaved && isPaymentDataSaved) {
      setIsRefreshSubscriptionDetails(true);
      setIsProcessing(false);
      setIsCreditCardDataSaved(false);
      setIsPaymentDataSaved(false);

      if (cbRef) cbRef();

      setCBRef(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreditCardDataSaved, isPaymentDataSaved])

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 60px)',
        paddingTop: '2.5rem',
        paddingBottom: '2.5rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        backgroundColor: 'white',
      }}
    >

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "2rem"
        }}
        className="xl-flex-row"
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
          className="xl-width-40"
        >
          <BillingAddress
            setShowPopupUpdateBillingAddress={setShowPopupUpdateBillingAddress}
            billingAddressData={billingAddressData}
            setBillingAddressData={setBillingAddressData}
          />

          <Support />

          <CreditCardDetails
            isRefresh={isRefreshCreditCardDetails}
            setIsRefresh={setIsRefreshCreditCardDetails}
            setShowPopupAddNewCreditCard={setShowPopupAddNewCreditCard}
          />
        </div>

        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <SubscriptionDetails
            setShowPopupUpgradeSubscription={setShowPopupUpgradeSubscription}
            planDetails={planDetails}
            setPlanDetails={setPlanDetails}
            isRefreshSubscriptionDetails={isRefreshSubscriptionDetails}
            setIsRefreshSubscriptionDetails={setIsRefreshSubscriptionDetails}
            setActiveCreditCardDetails={setActiveCreditCardDetails}
          />

          <Invoice />

          <ACHDetails
            isRefresh={isRefreshACHDetails}
            setIsRefresh={setIsRefreshACHDetails}
          />
        </div>
      </div>
      <UpdateBillingAddress
        showPopupUpdateBillingAddress={showPopupUpdateBillingAddress}
        setShowPopupUpdateBillingAddress={setShowPopupUpdateBillingAddress}
        billingAddressData={billingAddressData}
        setBillingAddressData={setBillingAddressData}
      />

      <AddCreditCard
        showPopupAddNewCreditCard={showPopupAddNewCreditCard}
        setShowPopupAddNewCreditCard={setShowPopupAddNewCreditCard}
        setIsRefresh={setIsRefreshCreditCardDetails}
      />

      <UpgradeSubscription
        showPopupUpgradeSubscription={showPopupUpgradeSubscription}
        setShowPopupUpgradeSubscription={setShowPopupUpgradeSubscription}
        planDetails={planDetails}
        setShowPopupForPayment={setShowPopupForPayment}
        setUpgradePlan={setUpgradePlan}
        activeCreditCardDetails={activeCreditCardDetails}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
        setIsCreditCardDataSaved={setIsCreditCardDataSaved}
        callTheAPIForUpgradeSubscription={callTheAPIForUpgradeSubscription}
        setPaymentType={setPaymentType}
      />

      <UpgradePlan
        showPopupForPayment={showPopupForPayment}
        setShowPopupForPayment={setShowPopupForPayment}
        upgradPlan={upgradPlan}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
        setIsCreditCardDataSaved={setIsCreditCardDataSaved}
        callTheAPIForUpgradeSubscription={callTheAPIForUpgradeSubscription}
        setPaymentType={setPaymentType}
        setIsRefresh={setIsRefreshCreditCardDetails}
      />

    </div>
  )
}

export default Billing
