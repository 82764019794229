import {
  Box,
  Toolbar,
  Typography,
  Stack,
  Button,
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import {
  Home,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LocalPrintshopOutlined,
  FilterAlt,
  DateRange
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { format } from "date-fns";
import DailyManifest from "./DailyManifest";
import Bookings from "./Bookings";
import Availability from "./Availability";
import Fleet from "./Fleet";
import Extras from "./Extras";
import Attemps from "./Attemps";
import moment from "moment";
import './Dashboard.css';
import useAccessControl from "../../components/hooks/useAccessControl";
import Cookies from "js-cookie";
import DateRangePicker from "../../components/daterangepicker/DateRangePicker";
import { dateToPosition } from "../carrental/calendar/utlis";

const pages = [
  "dashboarddailymanifest",
  "dashboardbookings",
  "dashboardavailability",
  "dashboardfleet",
  "dashboardextras",
  "dashboardattempts",
];

const pageNames: Record<string, string> = {
  dashboarddailymanifest: "Daily Manifest",
  dashboardbookings: "Bookings",
  dashboardavailability: "Availability",
  dashboardfleet: "Fleet",
  dashboardextras: "Extras",
  dashboardattempts: "Attempts",
};

const Dashboard = () => {
  const role = Cookies.get("role") || '';
  const isAdmin = role.toLowerCase() === 'admin';
  const navigate = useNavigate();
  const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false);
  const [customStartDate, setCustomStartDate] = useState<string>(moment().startOf("month").subtract(1, 'month').format("MMM D, YYYY"));
  const [customEndDate, setCustomEndDate] = useState<string>(moment().format("MMM D, YYYY"));
  const [currentDateFormat, setCurrentDateFormat] = useState(
    customStartDate + " - " +
    customEndDate
  );
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const { permissions }: any = useAccessControl();
  const allowedPages = pages.filter((page: string) => permissions[page] === true || isAdmin);
  const [firstTime, setFirstTime] = React.useState<string | null>(null);
  const [date, setDate] = useState<Date>(new Date());
  const dateRef = useRef<HTMLInputElement>(null);

  const dateRangePickeref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleDropDown = (e: MouseEvent) => {
      if (!dateRangePickeref.current?.contains(e.target as Node)) setShowDateRangePicker(false);
    }

    document.addEventListener("click", handleDropDown);
    return () => document.removeEventListener("click", handleDropDown);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (allowedPages.length > 0 && !firstTime) {
      setFirstTime(allowedPages[0]);
    }
  }, [allowedPages, firstTime]);

  if (!firstTime) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: '16px' }}>Loading...</Typography>
      </div>
    );
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  const onChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentDate = new Date();
    setDate(
      () =>
        new Date(
          new Date(
            new Date(e.target.value.replaceAll("-", "/")).setHours(
              currentDate.getHours()
            )
          ).setMinutes(currentDate.getMinutes())
        )
    );
  };

  return (
    <>
      <Box>
        <Toolbar
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { sm: "column", md: "row" },
            gap: { sm: 1, md: 0 },
            marginBottom: { sm: 2, md: 0 },
            justifyContent: "space-between"
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Home sx={{ fontSize: "24px", marginRight: "5px" }} />
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              {firstTime === "dashboarddailymanifest"
                ? `Daily Manifest - ${format(new Date(), "MM/dd/yyyy")}`
                : pageNames[firstTime] || "Page Not Found"}
            </Typography>
          </Box>

          <Stack spacing={0.5} direction="row">
            {firstTime === "dashboarddailymanifest" &&
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    borderRadius: "0",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: colorConfigs.topbar.bg,
                    },
                  }}
                  onClick={() => {
                    navigate("/carrental/reservations/addreservation");
                  }}
                >
                  New Reservation
                </Button>
                <button
                  style={{
                    backgroundColor: "#ffffff",
                    color: "gray",
                    borderRadius: "0",
                    border: "2px solid #bdbdbd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    new Date() === date
                      ? setDate(
                        () => new Date(new Date().setDate(new Date().getDate() + 1))
                      )
                      : setDate(
                        () => new Date(new Date().setDate(new Date().getDate()))
                      )
                  }
                >
                  {new Date().getDate() === date.getDate() ? "Tomorrow" : "Today"}
                </button>

                <div
                  style={{
                    backgroundColor: "#ffffff",
                    color: "gray",
                    borderRadius: "0",
                    border: "2px solid #bdbdbd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setDate(() => new Date(date.setDate(date.getDate() - 1)))
                  }
                >
                  <KeyboardArrowLeft />
                </div>
                <input
                  type="date"
                  name="dashboardHeaderDate"
                  ref={dateRef}
                  style={{
                    opacity: 0,
                    position: "absolute",
                    pointerEvents: "none",
                    right: 80,
                    top: 30,
                  }}
                  value={date.toISOString().split("T")[0]}
                  onChange={(e) => onChangeCapture(e)}
                />

                <button
                  style={{
                    backgroundColor: "#ffffff",
                    color: "gray",
                    borderRadius: "0",
                    border: "2px solid #bdbdbd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    if (dateRef.current !== null) {
                      dateRef.current.showPicker();
                    }
                  }}
                >
                  {format(date, "MM/dd/yyyy")}
                </button>

                <div
                  style={{
                    backgroundColor: "#ffffff",
                    color: "gray",
                    borderRadius: "0",
                    border: "2px solid #bdbdbd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setDate(() => new Date(date.setDate(date.getDate() + 1)))
                  }
                >
                  <KeyboardArrowRight />
                </div>
              </>
            }

            {firstTime === "dashboardbookings" &&
              <div ref={dateRangePickeref}>
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                    color: colorConfigs.topbar.bg,
                    borderRadius: "0",
                    border: "2px solid #ddd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                  onClick={() => setShowDateRangePicker(!showDateRangePicker)}
                >
                  <span className="text-sm font-semibold">{currentDateFormat}</span>
                  <DateRange style={{ marginLeft: '0.5rem' }} />
                </div>
                <DateRangePicker
                  intialStartDate={customStartDate}
                  intialEndDate={customEndDate}
                  showDateRangePicker={showDateRangePicker}
                  setShowDateRangePicker={setShowDateRangePicker}
                  setDateRange={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                    const differentInDays = dateToPosition(moment(startDate), moment(endDate));
                    if (differentInDays <= 0) {
                      setCurrentDateFormat(
                        moment(startDate).format('MMM D, YYYY')
                      );
                    } else {
                      setCurrentDateFormat(
                        moment(startDate).format('MMM D, YYYY') +
                        moment(endDate).format(' - MMM D, YYYY')
                      );
                    }
                  }}
                />
              </div>
            }

            <div
              style={{
                backgroundColor: "#ffffff",
                color: "black",
                borderRadius: "0",
                border: "2px solid #bdbdbd",
                textTransform: "capitalize",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "5px",
              }}
            >
              <LocalPrintshopOutlined />
            </div>

            <div
              style={{
                backgroundColor: "#ffffff",
                color: "black",
                borderRadius: "0",
                border: "2px solid #bdbdbd",
                textTransform: "capitalize",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "5px",
              }}
            >
              <FilterAlt />
            </div>
          </Stack>
        </Toolbar>

        <AppBar
          position="static"
          sx={{
            backgroundColor: colorConfigs.sidebar.bg,
            border: "none",
            paddingLeft: "18px",
            paddingRight: "18px",
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: "black", paddingBlock: "4px" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {allowedPages.map((page, index) => {
                  if (firstTime === page) {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => handleOnClick(page)}
                        sx={{
                          backgroundColor: colorConfigs.sidebar.bg,
                          color: colorConfigs.sidebar.color,
                        }}
                      >
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem key={index} onClick={() => handleOnClick(page)}>
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                paddingTop: "8px",
                paddingLeft: "0px",
                marginLeft: "-20px",
              }}
            >
              {allowedPages.map((page, index: number) => {
                if (firstTime === page) {
                  return (
                    <Button
                      disableElevation={false}
                      key={index}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",

                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                        "&: hover": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {pageNames[page]}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      disableElevation={false}
                      key={index}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",
                        border: "none",
                        "&: focus": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                        "&: active": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {pageNames[page]}
                    </Button>
                  );
                }
              })}
            </Box>
          </Container>
        </AppBar>

        {firstTime === "dashboarddailymanifest" && <DailyManifest date={date} />}
        {firstTime === "dashboardbookings" && <Bookings startDate={customStartDate} endDate={customEndDate} />}
        {firstTime === "dashboardavailability" && <Availability />}
        {firstTime === "dashboardfleet" && <Fleet />}
        {firstTime === "dashboardextras" && <Extras />}
        {firstTime === "dashboardattempts" && <Attemps />}
      </Box>

    </>
  );
};
export default Dashboard;