import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import MenuIcon from "@mui/icons-material/Menu";
import colorConfigs from "../../../configs/colorConfigs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../configs/api";
import { AddRelation } from "./AddRelation";
import AddIdentification from "./AddIdentification";
import { format } from "date-fns";
import useAuth from "../../../components/hooks/useAuth";
import PhoneNumberInput from "../../../components/common/PhoneNumberInput";
import EmailAlreadyExistChecking from "../../../components/EmailAlreadyExistChecking";
import { getCustomerEmailChecking } from "../../../services/EmailChecking";

type ExtraInformation = {
  id: number;
  idNumber: string;
  issueDate: string;
  exprationDate: string;
  filePath: string;
  idTypeId: string;
  customerId: number;
  type: string;
  imageSize: number,
  uploadedAt: string,
  uploadedBy: string
};

const pages = ["Contact"];

const monthNameOptions = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const daysOptions: any = [];
for (let i = 1; i <= 31; i++) {
  daysOptions.push({ value: i, label: i });
}

const yearOptions: any = [];
for (let i = 1940; i <= new Date().getFullYear(); i++) {
  yearOptions.push({ value: i, label: i });
}

export const AddCustomer = () => {
  const { auth }: any = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [Street, setStreet] = useState<string>("");
  const [street2, setStreet2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [isAddRelationShown, setIsAddRelationShown] = useState<boolean>(false);

  const [firstTime, setFirstTime] = React.useState<String>(pages[0]);

  const [extraRow, setExtraRow] = useState<ExtraInformation[]>([]);
  const [indexNumber, setIndexNumber] = useState<number>(1);
  const [emailTouched, setEmailTouched] = useState<boolean>(false);

  useEffect(() => {
    setFirstTime(pages[0]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddExtraRow = () => {
    const data: ExtraInformation = {
      id: indexNumber,
      idNumber: "",
      issueDate: "",
      exprationDate: "",
      filePath: "",
      idTypeId: "",
      customerId: 1,
      type: "POST",
      imageSize: 0,
      uploadedAt: `${format(new Date(), "MM/dd/yyyy")} ${format(new Date(), "hh: mm: ss a")}`,
      uploadedBy: auth.givenname,
    };

    setExtraRow((extraRow: ExtraInformation[]) => [...extraRow, data]);

    setIndexNumber(indexNumber + 1);
  };

  const handleDeleteExtraRow = (index: number) => {
    const newRow: ExtraInformation[] = extraRow.filter((row: ExtraInformation) => row.id !== index)
    setExtraRow(newRow);
  };

  const changeOnExtraValue = (row: ExtraInformation) => {

    setExtraRow((extraRow) => extraRow.map((rowValue: ExtraInformation) => {
      return (rowValue.id === row.id) ? row : rowValue
    }))
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
  };


  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const customerExtraInformations = extraRow.map((item: any) => {
      return {
        idNumber: item.idNumber,
        issueDate: new Date(item.issueDate),
        exprationDate: new Date(item.exprationDate),
        filePath: item.filePath,
        idTypeId: item.idTypeId,
        customerId: item.customerId,
        imageSize: item.imageSize,
        uploadedAt: new Date(item.uploadedAt),
        uploadedBy: item.uploadedBy
      }
    })

    e.preventDefault();
    await axios
      .post(
        `${baseURL}Customer`,
        JSON.stringify({
          firstName,
          lastName,
          emailAddress,
          phoneNumber,
          Street,
          street2,
          city,
          state,
          country,
          zip,
          birthday: `${year}-${month}-${day}`,
          customerExtraInformations
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <GroupsIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Customer - Add
          </Typography>
        </Box>

        <Button
          variant="contained"
          sx={{
            backgroundColor: colorConfigs.topbar.bg,
            borderRadius: "0",
            textTransform: "capitalize",
          }}
          onClick={() => navigate("/carrental/reservations/addreservation")}
        >
          New Reservation
        </Button>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "50%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            Customer Information
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            >

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="firstName"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    First Name
                    <br />
                    <input
                      type="text"
                      name="firstName"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="lastName"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Last Name
                    <br />
                    <input
                      type="text"
                      name="lastName"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <EmailAlreadyExistChecking
                  emailAddress={emailAddress}
                  setEmailAddress={setEmailAddress}
                  getEmailChecking={getCustomerEmailChecking}
                  className={{
                    parentDiv: {
                      width: "50%"
                    },
                    label: {
                      fontSize: "12px", fontStyle: "bold"
                    },
                    input: {
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    },
                    successMessage: {
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '0.25rem',
                      fontSize: '0.75rem',
                      color: '#00b300',
                    },
                    errorMessage: {
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '0.25rem',
                      fontSize: '0.75rem',
                      color: '#ff3333',
                    },
                  }}
                  touched={emailTouched}
                  setTouched={setEmailTouched}
                />

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="phoneNumber"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Phone Number
                    <br />
                    <PhoneNumberInput
                      name={"phoneNumber"}
                      value={phoneNumber}
                      setValue={setPhoneNumber}
                      fontSize={"14px"}
                      paddingBlock={"5px"}
                      required={true} />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="Street"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Street
                    <br />
                    <input
                      type="text"
                      name="Street"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={Street}
                      onChange={(e) => setStreet(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="street2"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Street 2
                    <br />
                    <input
                      type="text"
                      name="Street2"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={street2}
                      onChange={(e) => setStreet2(e.target.value)}
                    />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="city"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    City
                    <br />
                    <input
                      type="text"
                      name="city"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="state"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    State
                    <br />
                    <input
                      type="text"
                      name="state"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      required
                    />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="zip"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Zip
                    <br />
                    <input
                      type="text"
                      name="zip"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="country"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Country
                    <br />
                    <input
                      type="text"
                      name="country"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <div style={{ width: "33.33%" }}>
                    <label
                      htmlFor="birthday"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Birthday
                      <br />
                      <Select
                        styles={{
                          control: (base) => ({ ...base, fontSize: "12px" }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        name="birthday"
                        value={monthNameOptions.find((item: any) => parseInt(item.value) === parseInt(month)) || null}
                        onChange={(obj: any) => setMonth(obj ? obj.value : "")}
                        options={monthNameOptions}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ width: "33.33%" }}>
                    <br />
                    <Select
                      styles={{
                        control: (base) => ({ ...base, fontSize: "12px" }),
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      name="birthday"
                      value={daysOptions.find((item: any) => item.value === parseInt(day)) || null}
                      onChange={(obj: any) => setDay(obj ? obj.value : "")}
                      options={daysOptions}
                      required
                    />
                  </div>

                  <div style={{ width: "33.33%" }}>
                    <br />
                    <Select
                      styles={{
                        control: (base) => ({ ...base, fontSize: "12px" }),
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      name="birthday"
                      value={yearOptions.find((item: any) => item.value === parseInt(year)) || null}
                      onChange={(obj: any) => setYear(obj ? obj.value : "")}
                      options={yearOptions}
                      required
                    />
                  </div>
                </div>

              </div>

            </div>

            <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Extra Information
            </h3>
            <hr />
            <label>Driver license</label>

            <table>
              <tbody>
                  <tr>
                    <th style={{ width: "15%" }}>Type</th>
                    <th style={{ width: "25%" }}>ID Number</th>
                    <th style={{ width: "15%" }}>Issued On</th>
                    <th style={{ width: "15%" }}>Exp. Date</th>
                    <th style={{ width: "35%" }}>File</th>
                  </tr>

                  {extraRow.map((row: ExtraInformation, index: number) => {
                    return (
                      <AddIdentification
                        key={index}
                        itemIndex={row.id}
                        handleDeleteExtraRow={(index) => handleDeleteExtraRow(index)}
                        row={row}
                        changeOnExtraValue={(
                          row
                        ) =>
                          changeOnExtraValue(
                            row
                          )
                        }
                      />
                    );
                  })}
              </tbody>
            </table>
            <div
              style={{
                height: "40px",
                borderStyle: "none dashed dashed dashed",
                borderWidth: "1px",
                borderColor: "#D5D4D8",
                marginTop: "-10px",
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleAddExtraRow()}
              >
                <AddOutlinedIcon
                  sx={{
                    fontSize: "12px",
                    textAlign: "center",
                    paddingTop: "3px",
                  }}
                />
                Add another Identification
              </p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>

        <div
          style={{
            width: "50%",
          }}
        >
          <h3 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
            Relations
          </h3>
          <hr />
          <table>
            <tr>
              <th>Contact Item</th>
              <th>Relation</th>
            </tr>
          </table>
          <div
            style={{
              height: "40px",
              borderStyle: "none dashed dashed dashed",
              borderWidth: "1px",
              borderColor: "#D5D4D8",
              marginTop: "-10px",
              paddingTop: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontSize: "12px", textAlign: "center", cursor: "pointer" }}
              onClick={() => setIsAddRelationShown(true)}
            >
              <AddOutlinedIcon
                sx={{
                  fontSize: "12px",
                  textAlign: "center",
                  paddingTop: "3px",
                }}
              />
              Add Contact Item
            </p>
          </div>
        </div>
      </div>

      <div style={{ display: isAddRelationShown ? "block" : "none" }}>
        <AddRelation
          handleAllRelationShowClick={() => setIsAddRelationShown(false)}
          showPopup={isAddRelationShown}
        />
      </div>
    </>
  );
};
