import React, { useEffect, useState } from 'react';
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import UnAuthorize from './UnAuthorize';
import useAccessControl from '../hooks/useAccessControl';
import appRoutes from '../../routes/appRoutes';
import { RouteType } from '../../routes/config';
import appAdminRoutes from '../../routes/appAdminRoutes';
import RentalJumper from '../RentalJumper';

type Props = {
    roles: string[];
}

function RequireAuth({ roles }: Props) {
    const { auth, setAuth }: any = useAuth();
    const isAdmin = auth?.roles && roles.includes(auth.roles);
    const [authIsFound, setAuthIsFound] = useState<boolean>(false);
    const location = useLocation();
    const token = Cookies.get("token-jj-rental-ui");
    const { permissions }: any = useAccessControl();
    const [loading, setLoading] = useState<boolean>(true);

    const pages = [
        "dashboarddailymanifest",
        "dashboardbookings",
        "dashboardavailability",
        "dashboardfleet",
        "dashboardextras",
        "dashboardattempts",
    ];

    useEffect(() => {
        if (token && !authIsFound) {
            const jsonData: any = jwtDecode(token);
            const id = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
            const roles = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            const user = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
            const givenname = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
            const emailAddress = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
            setAuth({ id, user, givenname, emailAddress, roles, token });
            setAuthIsFound(true);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const findSectionForPath = (routes: RouteType[], path: string): string | null => {
        for (const route of routes) {
            if (route.path && path.startsWith(route.path) && route.path !== '/dashboard') {

                if (route.child) {
                    const childSection = findSectionForPath(route.child, path);
                    if (childSection) {
                        return childSection;
                    }
                }

                if (path === route.path) {
                    return route.section || null;
                }
            }
        }
        return null;
    };

    const getSectionFromPath = (path: string) => {
        const segments = path.split('/').filter(Boolean);
        for (let i = segments.length; i > 0; i--) {
            const currentPath = '/' + segments.slice(0, i).join('/');
            const section = findSectionForPath([...appRoutes, ...appAdminRoutes], currentPath);
            if (section) {
                return section;
            }
        }

        return null;
    };

    const hasPermission = () => {
        const currentPath = location.pathname;
        if (currentPath === '/dashboard') {
            return checkDashboardAccess();
        }

        const section = getSectionFromPath(currentPath);
        if (section) {
            const hasAccess = permissions[section];
            return hasAccess;
        }
        return false;
    };

    const checkPermissionsStatus = () => {
        const values = Object.values(permissions);
        return values.length === 0 || values.every((value) => value === false);
    };

    const checkDashboardAccess = () => {
        return pages.some(page => permissions[page]);
    };

    if (loading) {
        return <RentalJumper />;
    }

    if (!token) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    if (isAdmin) {
        return <Outlet />;
    }

    if (checkPermissionsStatus()) {
        return <UnAuthorize />;
    }

    return hasPermission() ? (
        <Outlet />
    ) : (
        <UnAuthorize />
    );
}

export default RequireAuth;