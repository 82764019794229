import { useNavigate } from "react-router-dom";
import HeroSection from "../../components/HeroSection";
import { useEffect, useState } from "react";
import GetStartedFreeTrial from "../../components/GetStartedFreeTrial";
import '../../styles/Pricing.css';
import { getPlans } from "../../services/Plan";

const Pricing = () => {
    const navigate = useNavigate();
    const [standardPlans, setStandardPlans] = useState([]);
    const [premiumPlans, setPremiumPlans] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPlans()
            .then(data => {
                const standard = data.filter((plan: any) => plan.planName.includes("Standard"));
                const premium = data.filter((plan: any) => plan.planName.includes("Premium"));

                standard.sort((a: any, b: any) => a.billingCycle.localeCompare(b.billingCycle));
                premium.sort((a: any, b: any) => a.billingCycle.localeCompare(b.billingCycle));

                setStandardPlans(standard);
                setPremiumPlans(premium);
            })
            .catch(error => console.error('Error fetching plans data:', error))
            .finally(() => setLoading(false));
    }, []);

    const handleCheckout = (plan: any) => {
        navigate('/checkout', {
            state: {
                planId: plan.id,
                planName: plan.planName,
                price: plan.price,
                billingCycle: plan.billingCycle
            }
        });
    };

    const renderPlanDetails = (plan: any) => (
        <div key={plan.id} className="plan-column">
            <p className="plan-description">{plan.description}</p>
            <p className="plan-price">
                ${plan.price}/{plan.billingCycle.toLowerCase()}
            </p>
            <button
                className="start-membership-btn"
                onClick={() => handleCheckout(plan)}
            >
                Start Membership
            </button>
        </div>
    );

    const renderPlans = (plans: any) => (
        <div className="plans-section">
            {plans.map((plan: any) => renderPlanDetails(plan))}
        </div>
    );

    const Loader = () => (
        <div className="loader-container">
            <div className="loader"></div>
            <span>Loading...</span>
        </div>
    );

    return (
        <div className="pricing-container">
            <HeroSection
                mainHeading="RD Rental Pricing"
                backgroundImage="../images/pricing-rd-rental.png"
            />

            <section className="plans-section">
                <div className="plan-column">
                    <h2 className="plan-heading">Standard Membership</h2>
                    {loading ? (
                        <Loader />
                    ) : (
                        renderPlans(standardPlans)
                    )}
                </div>

                <div className="plan-column">
                    <h2 className="plan-heading">Premium Membership</h2>
                    {loading ? (
                        <Loader />
                    ) : (
                        renderPlans(premiumPlans)
                    )}
                </div>
            </section>

            <section className="get-started-section">
                <div className="get-started-text">
                    <h2 className="get-started-heading">Try it FREE for 30 days.</h2>
                    <p className="get-started-description">
                        Experience the power of RD Rental with our risk-free trial offer.
                        Sign up today and gain access to all the features and functionalities
                        of RD Rental for 30 days, absolutely free.
                    </p>
                    <div className="features-grid">
                        <div className="feature-item">
                            <h3 className="feature-heading">Access to all RD Rental features</h3>
                            <p className="feature-description">
                                Access to all RD Rental features, including task tracking,
                                agile methodologies support, real-time collaboration, and powerful reporting.
                            </p>
                        </div>
                        <div className="feature-item">
                            <h3 className="feature-heading">No limitations or restrictions</h3>
                            <p className="feature-description">
                                Experience the full capabilities of RD Rental without any barriers.
                            </p>
                        </div>
                        <div className="feature-item">
                            <h3 className="feature-heading">No contracts — cancel anytime.</h3>
                            <p className="feature-description">
                                Start your membership today and choose either a monthly or yearly billing plan. You can cancel anytime.
                            </p>
                        </div>
                        <div className="feature-item">
                            <h3 className="feature-heading">Dedicated customer support</h3>
                            <p className="feature-description">
                                Our experienced team is committed to providing exceptional assistance.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <GetStartedFreeTrial />
        </div>
    );
}

export default Pricing;