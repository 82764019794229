import { useMemo, useState, useRef, useEffect } from "react";
import styles from "./pie-chart.module.css";
import MenuIcon from '@mui/icons-material/Menu';


type DataItem = {
    name: string;
    value: number;
};

type PieChartProps = {
    height: number;
    data: DataItem[];
    title?: string;
};

type OptionType = {
    value: number,
    label: string
};

const MARGIN_X = 150;
const MARGIN_Y = 50;
const INFLEXION_PADDING = 20; // space between donut and label inflexion point

function generateHSLColors(num: number): string[] {
    const colors = [];
    for (let i = 0; i < num; i++) {
        const hue = (i / num) * 360; // Spread across 360 degrees
        colors.push(`hsl(${hue}, 70%, 60%)`); // Saturation = 70%, Lightness = 60%
    }
    return colors;
}

export const PieChart = ({ height, data, title = "Pie Chart" }: PieChartProps) => {
    const ref = useRef<SVGGElement | null>(null);
    const [tooltip, setTooltip] = useState<{
        visible: boolean;
        x: number;
        y: number;
        text: string;
    }>({ visible: false, x: 0, y: 0, text: "" });
    const [pieChartDropDown, setPieChartDropDown] = useState<OptionType[]>([
        { value: 1, label: "View in FullScreen" },
        { value: 2, label: "Print the Chart" }
    ]);
    const [width, setWidth] = useState<number>(0);
    const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);
    const [scheduleFullScreen, setScheduleFullScreen] = useState<boolean>(false);

    const dropDownVisibleRef = useRef<HTMLDivElement>(null);
    const scheduleFullScreenRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const onResize = () => {
            if (scheduleFullScreenRef.current && !scheduleFullScreen) {
                setWidth(scheduleFullScreenRef.current?.clientWidth);
            }
        }

        onResize();
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, scheduleFullScreen])

    useEffect(() => {
        const handleDropDown = (e: MouseEvent) => {
            if (!dropDownVisibleRef.current?.contains(e.target as Node)) setDropDownVisible(false);
        }

        document.addEventListener("click", handleDropDown);
        return () => document.removeEventListener("click", handleDropDown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const colors = generateHSLColors(data?.length);

    const radius = scheduleFullScreen ? (Math.min(window.innerWidth - 2 * MARGIN_X, window.innerHeight - 2 * MARGIN_Y) / 2) : Math.min(width - 2 * MARGIN_X, height - 2 * MARGIN_Y) / 2;

    const totalValue = useMemo(
        () => data.reduce((acc, item) => acc + item.value, 0),
        [data]
    );

    const outerRadius = radius; // Outer radius
    const innerRadius = radius - 10; // Inner radius for donut effect (optional)

    const shapes = useMemo(() => {
        let cumulativeValue = 0;
        const validData = data.filter((item) => item.value !== 0);

        // Keep track of previous label positions to prevent overlaps
        let previousLabelY: any = null;

        return validData.map((item, index) => {
            const startAngle =
                validData.length <= 1
                    ? 0
                    : (cumulativeValue / totalValue) * 2 * Math.PI - Math.PI / 2;
            cumulativeValue += item.value;
            const endAngle =
                validData.length <= 1
                    ? 2 * Math.PI
                    : (cumulativeValue / totalValue) * 2 * Math.PI - Math.PI / 2;

            const largeArcFlag = item.value / totalValue > 0.5 ? 1 : 0;

            const [x0, y0] = [
                outerRadius * Math.cos(startAngle),
                outerRadius * Math.sin(startAngle),
            ];
            const [x1, y1] = [
                outerRadius * Math.cos(endAngle),
                outerRadius * Math.sin(endAngle),
            ];

            const pathData = `M 0 0 L ${x0} ${y0} A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${x1} ${y1} Z
                          M 0 0 L ${innerRadius * Math.cos(startAngle)} ${innerRadius * Math.sin(startAngle)}
                          A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 1 ${innerRadius * Math.cos(endAngle)} ${innerRadius * Math.sin(endAngle)} Z`;

            const centroidAngle = (startAngle + endAngle) / 2;
            const centroidX = outerRadius * Math.cos(centroidAngle);
            const centroidY = outerRadius * Math.sin(centroidAngle);

            const inflexionX = centroidX + (centroidX > 0 ? INFLEXION_PADDING : -INFLEXION_PADDING);
            let inflexionY = centroidY;

            const lineLength = Math.abs(inflexionX) > Math.abs(inflexionY) ? 20 : 70; // Adjust line length based on the axis
            const finalX = inflexionX + (centroidX > 0 ? lineLength : -lineLength);
            const textAnchor = centroidX > 0 ? "start" : "end";

            // Dynamic label adjustment to prevent overlap
            if (previousLabelY !== null && Math.abs(inflexionY - previousLabelY) < 20) {
                // Add vertical spacing if overlapping
                inflexionY = previousLabelY + (centroidY > 0 ? 20 : -20);
            }

            // Update the previousLabelY for the next label
            previousLabelY = inflexionY;

            const label = `${item.name} (${item.value})`;

            return (
                <g
                    key={index}
                    className={styles.slice}
                    onMouseEnter={(e) => {
                        ref.current?.classList.add(styles.hasHighlight);
                        setTooltip({
                            visible: true,
                            x: e.clientX,
                            y: e.clientY,
                            text: label,
                        });
                    }}
                    onMouseLeave={() => {
                        ref.current?.classList.remove(styles.hasHighlight);
                        setTooltip({ visible: false, x: 0, y: 0, text: "" });
                    }}
                >
                    <path d={pathData} fill={colors[index]} />
                    <line
                        x1={centroidX}
                        y1={centroidY}
                        x2={inflexionX}
                        y2={inflexionY}
                        stroke={colors[index]}
                    />
                    <line
                        x1={inflexionX}
                        y1={inflexionY}
                        x2={finalX}
                        y2={inflexionY}
                        stroke={colors[index]}
                    />
                    <text
                        x={finalX + (centroidX > 0 ? 2 : -2)}
                        y={inflexionY}
                        textAnchor={textAnchor}
                        dominantBaseline="middle"
                        fontSize={10}
                    >
                        {label}
                    </text>
                </g>
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, outerRadius, innerRadius, totalValue]);




    useEffect(() => {
        // Handler for fullscreen change event
        const handleFullscreenChange = () => {
            setScheduleFullScreen(document.fullscreenElement === scheduleFullScreenRef.current);
        };

        // Add event listeners for fullscreen change
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        // Clean up event listeners on component unmount
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        if (scheduleFullScreen) {
            setPieChartDropDown((prevValue: OptionType[]) => {
                return prevValue.map((option, index) => (
                    index === 0
                        ? { ...option, label: "Exit FullScreen" }
                        : option
                ))
            });
        } else {
            setPieChartDropDown((prevValue: OptionType[]) => {
                return prevValue.map((option, index) => (
                    index === 0
                        ? { ...option, label: "View in FullScreen" }
                        : option
                ))
            });
        }

    }, [scheduleFullScreen])

    const hideDropdown = (value: number) => {
        switch (value) {
            case 1:
                if (scheduleFullScreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    }
                } else {
                    const element = scheduleFullScreenRef.current;
                    if (element) {
                        element.requestFullscreen();
                    }
                }
                break;
            case 2:
                const printContent = document.getElementById('print-content');
                if (printContent) {

                    const printWindow = window.open('', '', 'height=500, width=500');
                    if (printWindow) {
                        printWindow.document.write('<html><head><title>JVCRM</title></head><body>');
                        printWindow.document.write(printContent.innerHTML);
                        printWindow.document.write('</body></html>');
                        printWindow.document.close();

                        printWindow.print();

                        if (printWindow.closed) {
                            const element = scheduleFullScreenRef.current;
                            if (element) {
                                element.requestFullscreen();
                            }
                        }
                    }
                }
                break;
        }

        setDropDownVisible(false);
    }

    return (
        <div
            ref={scheduleFullScreenRef}
            style={{
                background: "#ECEFF1",
                borderRadius: "0.5rem",
                width: "100%"
            }}
        >
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "0.5rem"
                }}
            >
                <h5
                    style={{
                        flex: "1 1 0%",
                        textAlign: "center",
                        fontSize: "1.25rem",
                        fontWeight: 600
                    }}
                >{title}</h5>

                <div
                    ref={dropDownVisibleRef}
                    style={{
                        width: "fit-content",
                    }}
                >
                    <button
                        style={{
                            padding: "0.125rem 0.5rem",
                            borderRadius: "0.25rem",
                            cursor: "pointer",
                            marginTop: "0.5rem"
                        }}
                        onClick={() => setDropDownVisible(!dropDownVisible)}
                    >
                        <MenuIcon sx={{ fontSize: 22 }} />
                    </button>

                    <ul
                        style={{
                            position: "absolute",
                            zIndex: 1000,
                            top: 0,
                            right: 2,
                            marginTop: "2rem",
                            paddingBlock: "0.25rem",
                            backgroundColor: "#ffffff",
                            border: "1px solid rgb(0 0 0 / 0.3)",
                            borderRadius: "0.25rem",
                            overflow: "auto",
                            whiteSpace: "nowrap",
                            minWidth: "14rem",
                            height: "fit-content",
                            maxHeight: "13rem",
                            display: dropDownVisible ? "block" : "none"
                        }}
                    >
                        {pieChartDropDown?.map((item: OptionType, index: number) => (
                            <li
                                key={index}
                                style={{
                                    padding: "0.125rem 0.5rem",
                                    cursor: "pointer",
                                    userSelect: "none"
                                }}
                                onClick={() => {
                                    hideDropdown(item.value);
                                }}
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div id="print-content">
                <svg width={scheduleFullScreen ? window.innerWidth : width} height={scheduleFullScreen ? window.innerHeight : height} style={{ display: "inline-block" }}  >
                    {!data?.every((item: any) => item?.value <= 0) ? (
                        <>
                            <g
                                transform={`translate(${scheduleFullScreen ? window.innerWidth / 2 : width / 2}, ${scheduleFullScreen ? window.innerHeight / 2 : height / 2})`}
                                className={styles.container}
                                ref={ref}
                            >
                                {shapes}
                            </g>
                            {tooltip.visible && (
                                <foreignObject
                                    x={tooltip.x}
                                    y={tooltip.y}
                                    className={styles.tooltip}
                                >
                                    <div className={styles.tooltipContent}>{tooltip.text}</div>
                                </foreignObject>
                            )}
                        </>
                    ) : (
                        <circle
                            cx={scheduleFullScreen ? window.innerWidth / 2 : width / 2}
                            cy={scheduleFullScreen ? window.innerHeight / 2 : height / 2}
                            r={radius}
                            fill="#fecaca"
                            stroke={"#000000"}
                            strokeWidth={1}
                        />
                    )}
                </svg>
            </div>

            <div
                style={{
                    width: scheduleFullScreen ? window.innerWidth : width,
                    overflowX: "auto",
                    overflowY: "hidden",
                    paddingTop: "0.125rem",
                    paddingInline: "0.5rem",
                    height: "fit-content",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    borderTop: "1px solid rgb(0 0 0 / 0.3)"
                }}
            >
                {data?.map((item: any, index: number) => (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            textWrap: "nowrap",
                            gap: "0.25rem",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: colors[index],
                                width: "0.625rem",
                                height: "0.625rem",
                                borderRadius: "100%"
                            }}
                        ></div>
                        <span
                            style={{
                                fontSize: "0.75rem",
                                fontWeight: 500
                            }}
                        >{item?.name}</span>
                    </div>
                ))}

                <div
                    style={{
                        display: "flex",
                        textWrap: "nowrap",
                        gap: "0.25rem",
                        alignItems: "center"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#fecaca",
                            width: "0.625rem",
                            height: "0.625rem",
                            borderRadius: "100%"
                        }}
                    ></div>
                    <span
                        style={{
                            fontSize: "0.75rem",
                            fontWeight: 500,
                            textAlign: "center"
                        }}
                    >No Data</span>
                </div>
            </div>
        </div>
    );
};
