import { useEffect, useState } from 'react'
import { getPlans } from '../../../services/Plan';
import Cross from '../../../icons/Cross';
import Loading from '../../../icons/Loading';
import colorConfigs from '../../../configs/colorConfigs';

type UpgradeSubscriptionProps = {
  showPopupUpgradeSubscription: boolean;
  setShowPopupUpgradeSubscription: (value: boolean) => void;
  planDetails: any;
  setShowPopupForPayment: (Value: boolean) => void;
  setUpgradePlan: (value: any) => void;
  activeCreditCardDetails: any | null;
  isProcessing: boolean;
  setIsProcessing: (value: boolean) => void;
  setIsCreditCardDataSaved: (value: boolean) => void;
  callTheAPIForUpgradeSubscription: (upgradedPlan: any, creditCardDetails: any, cb: any) => void;
  setPaymentType: (value: number) => void;
}

const UpgradeSubscription = ({ showPopupUpgradeSubscription, setShowPopupUpgradeSubscription, planDetails, setShowPopupForPayment, setUpgradePlan, activeCreditCardDetails, isProcessing, setIsProcessing, setIsCreditCardDataSaved, callTheAPIForUpgradeSubscription, setPaymentType }: UpgradeSubscriptionProps) => {
  const [plans, setPlans] = useState<any>([]);
  const [plansDataIsLoaded, setPlansDataIsLoaded] = useState<boolean>(false);
  const [filterPlans, setFilterPlans] = useState<any>([]);

  useEffect(() => {
    getPlans()
      .then(res => {
        setPlans(res.reverse());
        setPlansDataIsLoaded(true)
      })
      .catch(error => {
        console.error(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (plansDataIsLoaded && planDetails !== null) {
      let filteredPlans = [];

      if (planDetails?.planName?.toLowerCase()?.includes('standard')) {
        if (planDetails?.billingCycle?.toLowerCase() === 'month') {
          filteredPlans = plans?.filter((plan: any) => !(plan?.planName?.toLowerCase()?.includes('standard') && plan?.billingCycle?.toLowerCase() === 'month'));
        } else {
          filteredPlans = plans?.filter((plan: any) => !plan?.planName?.toLowerCase()?.includes('standard'));
        }
      } else {
        if (planDetails?.billingCycle?.toLowerCase() === 'month') {
          filteredPlans = plans?.filter((plan: any) => plan?.planName?.toLowerCase()?.includes('premium') && plan?.billingCycle?.toLowerCase() === 'year');
        } else {
          filteredPlans = [];
        }
      }

      setFilterPlans(filteredPlans);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansDataIsLoaded, planDetails, plans]);

  const closeModal = () => {
    setShowPopupUpgradeSubscription(false);
  }

  const upgradePlan = (value: any) => {
    setUpgradePlan(value);
    if (activeCreditCardDetails === null) {
      setShowPopupForPayment(true);
      closeModal();
    } else {
      if (!isProcessing) {
        setIsProcessing(true);
        setIsCreditCardDataSaved(true);
        setPaymentType(0);

        callTheAPIForUpgradeSubscription(value, activeCreditCardDetails, () => {
          closeModal();
        });
      }
    }
  }

  return (
    <div
      style={{
        position: "fixed",
        inset: "0",
        padding: "1rem",
        marginTop: "4rem",
        display: showPopupUpgradeSubscription ? "flex" : "none",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "fit-content",
        zIndex: "1000",
        overflow: "auto",
      }}
    >
      <div
        style={{
          position: "fixed",
          inset: "0",
          width: "100%",
          minHeight: "calc(100vh - 72px)",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      ></div>
      <div
        style={{
          width: "100%",
          maxWidth: "32rem",
          backgroundColor: "white",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "0.375rem",
          position: "relative",
        }}
      >
        <div
          style={{
            color: "#fff",
            backgroundColor: colorConfigs.primaryColor,
            padding: "0.5rem 1rem",
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderTopLeftRadius: "0.375rem",
            borderTopRightRadius: "0.375rem",
          }}
        >
          <h4 style={{ fontWeight: "500" }}>Subscription Plan</h4>
          {!isProcessing && <Cross closeModal={closeModal} />}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            gap: "0.75rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <h5 style={{ fontWeight: "500", color: colorConfigs.primaryColor }}>
              Current Plan:
            </h5>
            <p
              style={{
                padding: "0",
                fontWeight: "400",
                fontSize: "0.875rem",
              }}
            >
              {planDetails?.planName}
              \{planDetails?.billingCycle?.charAt(0)?.toUpperCase() +
                planDetails?.billingCycle?.slice(
                  1,
                  planDetails?.billingCycle?.split("")?.length
                )}
            </p>
          </div>
          {filterPlans?.map((plan: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                gap: "0.5rem",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid",
                borderColor: colorConfigs.primaryColor,
                padding: "0.1rem 0",
              }}
            >
              <h5
                style={{
                  fontWeight: "500",
                  color: colorConfigs.primaryColor,
                }}
              >
                {`${plan?.planName}\\${plan?.billingCycle
                  ?.charAt(0)
                  ?.toUpperCase() + plan?.billingCycle?.slice(1)}:`}
              </h5>
              {isProcessing ? (
                <button
                  type="button"
                  style={{
                    backgroundColor: colorConfigs.primaryColor,
                    borderRadius: "0.375rem",
                    padding: "0.25rem 0.75rem",
                    fontWeight: "500",
                    color: "white",
                  }}
                >
                  <Loading />
                </button>
              ) : (
                <button
                  style={{
                    backgroundColor: colorConfigs.primaryColor,
                    borderRadius: "0.375rem",
                    padding: "0.25rem 0.75rem",
                    fontWeight: "500",
                    color: "white",
                    cursor: "pointer"
                  }}
                  onClick={() => upgradePlan(plan)}
                >
                  Upgrade
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UpgradeSubscription;