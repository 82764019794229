import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useActiveNavbar from "./hooks/useActiveNavbar";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../styles/Header.css';
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const signIn = [
    {
        name: "Sign In",
        link: "/login"
    }
];

const Header = () => {
    const navigate = useNavigate();
    const [navbarHeader, setNavbarHeader] = useState<any[]>([
        {
            name: "Home",
            link: "/",
            isChildVisible: false,
            child: []
        },
        {
            name: "Features",
            link: "/features",
            isChildVisible: false,
            child: []
        },
        {
            name: "Resources",
            link: "",
            isChildVisible: false,
            child: [
                {
                    name: "Security",
                    link: "/security",
                },
                {
                    name: "Blog",
                    link: "/blog",
                },
                {
                    name: "Case Studies",
                    link: "/case-studies",
                },
                {
                    name: "Contact Us",
                    link: "/contact-us",
                }
            ]
        },
        {
            name: "FAQ",
            link: "/faq",
            isChildVisible: false,
            child: []
        },
        {
            name: "Pricing",
            link: "/pricing",
            isChildVisible: false,
            child: []
        },
        {
            name: "Bill Pay",
            link: "/bill-pay",
            isChildVisible: false,
            child: []
        }
    ]);
    const { selected, setSelected }: any = useActiveNavbar();
    const [toggle, setToggle] = useState<boolean>(false);
    const [visibleTopBtn, setVisibleTopBtn] = useState<boolean>(false);

    const mobileViewRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<(HTMLButtonElement | null)[]>([]);

    useEffect(() => {
        const handleDropDown = (e: MouseEvent) => {
            if (!mobileViewRef.current?.contains(e.target as Node)) {
                if (!(dropdownRef.current)[1]?.contains(e.target as Node) && !(dropdownRef.current)[2]?.contains(e.target as Node)) {
                    setNavbarHeader((prevNavbar) =>
                        prevNavbar.map((item) => ({
                            ...item,
                            isChildVisible: false
                        }))
                    )
                }
            }
        }

        document.addEventListener("click", handleDropDown);
    }, [])

    const progessBtn = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;

            if (scrolled > 300) {
                setVisibleTopBtn(true);
            } else if (scrolled <= 300) {
                setVisibleTopBtn(false);
            }
        };

        window.addEventListener('scroll', toggleVisible);

        return () => window.addEventListener('scroll', toggleVisible);
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const location = useLocation();
    const hideHeaderForAdmin = "admin";
    const hideHeaderForPageNotFound = "not-found";
    const hideHeaderForUnAuthorised = "un-authorized";
    if (location.pathname.replace("/", "").startsWith(hideHeaderForAdmin)
        || location.pathname.replace("/", "").includes(hideHeaderForPageNotFound)
        || location.pathname.replace("/", "").includes(hideHeaderForUnAuthorised)
    ) {
        return <></>;
    }

    const headerStyles: React.CSSProperties = {
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 50,
        transition: "all 0.5s",
        backgroundColor: "#ffffff",
    };

    const headerTopStyles = {
        display: "flex", justifyContent: "space-between", width: "100%", maxWidth: "1200px", margin: "auto", fontWeight: "600", height: "70px", padding: "0 2rem"
    };

    const logoStyles = {
        height: "56px", width: "56px", padding: "0.5rem"
    };

    const buttonGroupStyles = {
        display: "flex", gap: "1rem", alignItems: "center"
    };

    const mobileViewStyles: React.CSSProperties = {
        display: toggle ? "block" : "none",
        position: "absolute",
        top: "72px",
        left: "0",
        width: "100%",
        backgroundColor: "#FFFFFF",
        zIndex: "1000",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    };

    const topBtnStyles: React.CSSProperties = {
        position: "fixed",
        bottom: "65px",
        right: "5px",
        width: "48px",
        height: "48px",
        padding: "8px",
        borderRadius: "50%",
        backgroundColor: "#295B7E",
        color: "#FFFFFF",
        border: "2px solid #295B7E",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "background-color 0.3s",
        cursor: "pointer",
    };

    return (
        <>
            <header style={headerStyles}>
                <div style={headerTopStyles}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "10%" }}>
                        <NavLink to="/">
                            <img style={logoStyles} src="../images/logo/rd-rental-logo.jpeg" alt="RD Rental" />
                        </NavLink>
                    </div>

                    <div className="desktop-only" style={buttonGroupStyles}>
                        {navbarHeader.map((data, index) => (
                            <div key={index} style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                {data.child.length === 0 ? (
                                    <NavLink
                                        to={data.link}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "64px",
                                            textDecoration: "none",
                                        }}
                                        onClick={() => setSelected(data.link)}
                                    >
                                        <span style={{
                                            textAlign: "center",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            color: "#295B7E",
                                            cursor: "pointer",
                                            paddingTop: "1rem"
                                        }}>
                                            {data.name}
                                        </span>
                                        <div style={{
                                            width: "100%",
                                            height: "2px",
                                            backgroundColor: selected === data.link ? "#295B7E" : "transparent"
                                        }} />
                                    </NavLink>
                                ) : (
                                    <div style={{ position: "relative" }}>
                                        <button
                                            ref={(e) => dropdownRef.current[index] = e}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "64px",
                                                backgroundColor: "transparent",
                                                border: "none",
                                                cursor: "pointer"
                                            }}
                                            onClick={() =>
                                                setNavbarHeader((prevNavbar) =>
                                                    prevNavbar.map((item, i) => (
                                                        i === index ? { ...item, isChildVisible: !item.isChildVisible } : { ...item, isChildVisible: false }
                                                    ))
                                                )
                                            }
                                        >
                                            <span style={{
                                                textAlign: "center",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                color: "#295B7E",
                                                cursor: "pointer",
                                                paddingTop: "1rem"
                                            }}>
                                                {data.name}
                                            </span>
                                            <div style={{
                                                width: "100%",
                                                height: "2px",
                                                backgroundColor: data.child.some((item: any) => item.link === selected) ? "#295B7E" : "transparent"
                                            }} />
                                            <ArrowDropDownIcon
                                                style={{
                                                    position: "absolute",
                                                    top: "45%",
                                                    right: "-14px",
                                                    fontSize: "20px",
                                                    color: "#295B7E",
                                                }}
                                            />
                                        </button>

                                        {data.isChildVisible && (
                                            <div style={{
                                                position: "absolute",
                                                zIndex: 1000,
                                                marginTop: "1px",
                                                left: 0,
                                                right: 0,
                                            }}>
                                                <ul style={{
                                                    width: "170px",
                                                    listStyle: "none",
                                                    overflow: "hidden",
                                                    borderColor: "#808080",
                                                    backgroundColor: "#F4F5FA",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
                                                    padding: "4px",
                                                    margin: "0",
                                                    borderRadius: "4px",
                                                }}
                                                >
                                                    {data.child.map((item: any, itemIndex: any) => (
                                                        <li key={itemIndex} style={{
                                                            padding: "16px",
                                                            backgroundColor: "#ffffff",
                                                            borderBottom: "1px solid #e0e0e0",
                                                        }}>
                                                            <NavLink
                                                                to={item.link}
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "flex-start",
                                                                    padding: "0",
                                                                    textDecoration: "none",
                                                                    color: selected === item.link ? "#295B7E" : "#1B1212"
                                                                }}
                                                                onClick={() => {
                                                                    setNavbarHeader((prevNavbar) =>
                                                                        prevNavbar.map((item) => ({
                                                                            ...item,
                                                                            isChildVisible: false
                                                                        }))
                                                                    );
                                                                    setSelected(item.link);
                                                                }}
                                                            >
                                                                <span style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "600",
                                                                    color: selected === item.link ? "#295B7E" : "#1B1212",
                                                                    opacity: 0.8
                                                                }}>
                                                                    {item.name}
                                                                </span>
                                                                <div style={{
                                                                    width: "100%",
                                                                    height: "2px",
                                                                    backgroundColor: selected === item.link ? "#295B7E" : "transparent"
                                                                }} />
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}

                        <div style={buttonGroupStyles}>
                            <div style={buttonGroupStyles}>
                                {signIn.map((item, i) => (
                                    <button
                                        key={i}
                                        style={{
                                            display: "block",
                                            backgroundColor: "#295B7E",
                                            color: "#FFFFFF",
                                            padding: "8px 16px",
                                            borderRadius: "4px",
                                            fontWeight: "bold",
                                            border: "2px solid #295B7E",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            navigate(item.link);
                                            setSelected(item.link);
                                        }}
                                    >
                                        {item.name}
                                    </button>
                                ))}
                            </div>

                            <button
                                style={{
                                    display: "block",
                                    backgroundColor: "#295B7E",
                                    padding: "8px 16px",
                                    borderRadius: "4px",
                                    fontWeight: "bold",
                                    color: "#FFFFFF",
                                    border: "2px solid #295B7E",
                                    transition: "background-color 0.3s",
                                    cursor: 'pointer'
                                }}
                                onClick={() => navigate("/free-trial")}
                            >
                                Free Trial
                            </button>
                        </div>
                    </div>
                    <button
                        style={{
                            width: "48px",
                            height: "48px",
                            position: "absolute",
                            outline: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            top: "40%",
                            right: "16px",
                            transform: "translateY(-50%)",
                        }}
                        className="mobile-only"
                        onClick={() => setToggle(!toggle)}
                    >
                        <div
                            style={{
                                position: "absolute",
                                width: "24px",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 50,
                            }}
                        >
                            <span
                                style={{
                                    display: "block",
                                    height: "2px",
                                    width: "28px",
                                    backgroundColor: "#295B7E",
                                    transform: toggle ? "rotate(45deg)" : "translateY(-6px)",
                                    transition: "all 0.3s",
                                }}
                            />
                            <span
                                style={{
                                    display: "block",
                                    height: "2px",
                                    width: "28px",
                                    backgroundColor: "#295B7E",
                                    opacity: toggle ? 0 : 1,
                                    transition: "opacity 0.3s",
                                }}
                            />
                            <span
                                style={{
                                    display: "block",
                                    height: "2px",
                                    width: "28px",
                                    backgroundColor: "#295B7E",
                                    transform: toggle ? "rotate(-45deg)" : "translateY(6px)",
                                    transition: "all 0.3s",
                                }}
                            />
                        </div>
                    </button>

                    <div ref={mobileViewRef} style={mobileViewStyles}>
                        <div style={{ padding: "12px", backgroundColor: "#FFFFFF", textAlign: "left" }}>
                            {navbarHeader.map((data, index) => (
                                data.child.length === 0 ?
                                    <NavLink
                                        to={data.link}
                                        key={index}
                                        style={{
                                            display: "flex",
                                            padding: "8px",
                                            textDecoration: "none",
                                            fontWeight: "600",
                                            color: selected === data.link ? "#295B7E" : "#1B1212"
                                        }}
                                        onClick={() => {
                                            setSelected(data.link);
                                            setToggle(false);
                                        }}
                                    >
                                        <span style={{
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            color: selected === data.link ? "#295B7E" : "#1B1212"
                                        }}>
                                            {data.name}
                                        </span>
                                    </NavLink>
                                    :
                                    <div key={index}>
                                        <button
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "8px",
                                                width: "100%",
                                                fontWeight: "600",
                                                color: data.child.some((item: any) => item.link === selected) ? "#295B7E" : "#1B1212",
                                                backgroundColor: "transparent",
                                                border: "none",
                                                textAlign: "left",
                                            }}
                                            onClick={() => {
                                                setNavbarHeader((prevNavbar) =>
                                                    prevNavbar.map((item, i) => (
                                                        i === index ? {
                                                            ...item,
                                                            isChildVisible: !item.isChildVisible
                                                        } : item
                                                    ))
                                                );
                                            }}
                                        >
                                            <span>{data.name}</span>

                                            <span style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                gap: "4px",
                                                cursor: "pointer",
                                            }}>
                                                <span onClick={(e) => {
                                                    e.stopPropagation();

                                                    setNavbarHeader((prevNavbar) =>
                                                        prevNavbar.map((item, i) => (
                                                            i === index ? {
                                                                ...item,
                                                                isChildVisible: !item.isChildVisible
                                                            } : item
                                                        ))
                                                    );
                                                }}>
                                                    {data.isChildVisible ? (
                                                        <ExpandLess style={{ fontSize: "20px" }} />
                                                    ) : (
                                                        <ExpandMore style={{ fontSize: "20px" }} />
                                                    )}
                                                </span>
                                            </span>
                                        </button>

                                        {data.isChildVisible &&
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                {data.child.map((item: any, itemIndex: number) => (
                                                    <NavLink
                                                        to={item.link}
                                                        key={itemIndex}
                                                        style={{
                                                            display: "flex",
                                                            padding: "8px",
                                                            textDecoration: "none",
                                                            fontWeight: "600",
                                                            backgroundColor: "#e4e4e4",
                                                            color: selected === item.link ? "#295B7E" : "#1B1212"
                                                        }}
                                                        onClick={() => {
                                                            setSelected(item.link);
                                                            setNavbarHeader((prevNavbar) =>
                                                                prevNavbar.map((item) => ({
                                                                    ...item,
                                                                    isChildVisible: false
                                                                }))
                                                            );
                                                            setToggle(false);
                                                        }}
                                                    >
                                                        <span style={{
                                                            fontSize: "14px",
                                                            color: selected === item.link ? "#295B7E" : "#1B1212"
                                                        }}>
                                                            {item.name}
                                                        </span>
                                                    </NavLink>
                                                ))}
                                            </div>
                                        }
                                    </div>
                            ))}

                            <button
                                style={{
                                    width: "100%",
                                    backgroundColor: "#295B7E",
                                    padding: "12px",
                                    borderRadius: "4px",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF"
                                }}
                                onClick={() => {
                                    navigate("/free-trial");
                                    setToggle(false);
                                }}
                            >
                                Free Trial
                            </button>

                            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                                {signIn.map((item, i) => (
                                    <button
                                        key={i}
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#295B7E",
                                            padding: "12px",
                                            borderRadius: "4px",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            color: "#FFFFFF",
                                            marginTop: "12px"
                                        }}
                                        onClick={() => {
                                            navigate(item.link);
                                            setToggle(false);
                                            setSelected("/login")
                                        }}
                                    >
                                        {item.name}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#295B7E", width: "100%", height: "4px" }} />
                    </div>
                </div>

                <div style={{ backgroundColor: "#295B7E", width: "100%", height: "8px", boxShadow: "inset 0 0 4px rgba(0, 0, 0, 0.2)" }} />

                <button
                    ref={progessBtn}
                    style={{
                        ...topBtnStyles,
                        display: visibleTopBtn ? "flex" : "none",
                    }}
                    onClick={scrollToTop}>
                    <ArrowUpwardIcon />
                </button>
            </header>

            <div style={{ width: "100%", marginTop: "72px", backgroundColor: "#F4F5FA", height: "4px" }} />
        </>
    );
};

export default Header;