import axios from "axios"
import baseURL from "../configs/api";

export const getFaqsByIsRentalSoftware = (isRentalSoftware: boolean) => {
    return getFetch(`faq/GetFaqsByIsRentalSoftware/${isRentalSoftware}`);
};

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}