const ThankYou = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#f3f4f6' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '1.5rem', textAlign: 'center', color: '#181842' }}>
                Thank You for Trying Us!
            </h2>
            <p style={{ fontSize: '1.125rem', textAlign: 'center' }}>
                You can login with your credentials from Sign-in.
            </p>
        </div>
    );
};

export default ThankYou;
