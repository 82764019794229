import { useEffect, useState } from 'react';
import { put_Group } from '../../../services/Group';
import Cross from '../../../icons/Cross';
import EmailValidatedComponent from '../../../components/EmailValidatedComponent';
import { onChangePhoneNumber } from '../../../utils/phoneNumberRegex';
import Loading from '../../../icons/Loading';
import colorConfigs from '../../../configs/colorConfigs';

type UpdateBillingAddressProps = {
    showPopupUpdateBillingAddress: boolean;
    setShowPopupUpdateBillingAddress: (value: boolean) => void;
    billingAddressData: any;
    setBillingAddressData: (value: any) => void;
}

const UpdateBillingAddress = ({ showPopupUpdateBillingAddress, setShowPopupUpdateBillingAddress, billingAddressData, setBillingAddressData }: UpdateBillingAddressProps) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [formBillingAddress, setFormBillingAddress] = useState<any>({
        name: "",
        emailAddress: "",
        phoneNumber: "",
        street: "",
        street2: "",
        zip: "",
        state: "",
        city: "",
        country: ""
    });

    useEffect(() => {
        if (showPopupUpdateBillingAddress) {
            setFormBillingAddress(billingAddressData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPopupUpdateBillingAddress])

    const closeModal = () => {
        setShowPopupUpdateBillingAddress(false);
        setFormBillingAddress({
            name: "",
            emailAddress: "",
            phoneNumber: "",
            street: "",
            street2: "",
            zip: "",
            state: "",
            city: "",
            country: ""
        });
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isProcessing) {
            setIsProcessing(true);
            put_Group(formBillingAddress)
                .then(() => {
                    setIsProcessing(false);
                    setBillingAddressData(formBillingAddress);
                    setShowPopupUpdateBillingAddress(false)
                })
                .catch(error => {
                    console.log(error);
                    setIsProcessing(false);
                })
        }
    }

    return (
        <div
            style={{
                position: "fixed",
                inset: "0",
                padding: "16px",
                marginTop: "70px",
                display: showPopupUpdateBillingAddress ? "flex" : "none",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                zIndex: 1000,
                overflow: "auto",
            }}
        >
            <div
                style={{
                    position: "fixed",
                    inset: "0",
                    width: "100%",
                    minHeight: "calc(100vh - 72px)",
                    backgroundColor: "rgba(0,0,0,0.5)",
                }}
            />
            <div
                style={{
                    width: "100%",
                    maxWidth: "32rem",
                    backgroundColor: "white",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0.375rem",
                    padding: "20px",
                    position: "relative",
                }}
            >
                <Cross closeModal={closeModal} />

                <form onSubmit={handleSubmit}>
                    <div style={{ margin: "8px 0", textAlign: "center" }}>
                        <h4
                            style={{
                                fontSize: "1.125rem",
                                color: colorConfigs.primaryColor,
                                fontWeight: "600",
                            }}
                        >
                            Update Billing Address
                        </h4>

                        <div style={{ marginBottom: "16px" }}>
                            <div style={{ width: "100%", fontSize: "0.875rem", marginBottom: "16px" }}>
                                <label
                                    htmlFor="companyName"
                                    style={{
                                        display: "block",
                                        textAlign: "start",
                                        color: colorConfigs.primaryColor,
                                        fontSize: "0.75rem",
                                        fontWeight: "500",
                                        marginBottom: "2px",
                                    }}
                                >
                                    Company Name
                                </label>
                                <input
                                    name="companyName"
                                    type="text"
                                    required
                                    style={{
                                        width: "100%",
                                        border: "1px solid",
                                        borderColor: colorConfigs.primaryColor,
                                        padding: "8px",
                                        borderRadius: "0.375rem",
                                        outline: colorConfigs.primaryColor,
                                    }}
                                    placeholder="Enter Company Name"
                                    value={formBillingAddress.name}
                                    onChange={(e) =>
                                        setFormBillingAddress({ ...formBillingAddress, name: e.target.value })
                                    }
                                />
                            </div>

                            <div style={{ width: "100%", fontSize: "0.875rem", marginBottom: "16px" }}>
                                <EmailValidatedComponent
                                    text={formBillingAddress.emailAddress}
                                    setText={(value) =>
                                        setFormBillingAddress({ ...formBillingAddress, emailAddress: value })
                                    }
                                    className={{
                                        parentDiv: {
                                            width: "100%",
                                            fontSize: "0.875rem",
                                        },
                                        label: {
                                            display: "block",
                                            textAlign: "start",
                                            color: colorConfigs.primaryColor,
                                            fontSize: "0.75rem",
                                            fontWeight: "500",
                                            marginBottom: "2px",
                                        },
                                        input: {
                                            width: "100%",
                                            border: "1px solid",
                                            borderColor: colorConfigs.primaryColor,
                                            padding: "8px",
                                            borderRadius: "0.375rem",
                                            outline: colorConfigs.primaryColor,
                                        },
                                    }}
                                />

                            </div>

                            <div style={{ width: "100%", fontSize: "0.875rem", marginBottom: "16px" }}>
                                <label
                                    htmlFor="phoneNumber"
                                    style={{
                                        display: "block",
                                        textAlign: "start",
                                        color: colorConfigs.primaryColor,
                                        fontSize: "0.75rem",
                                        fontWeight: "500",
                                        marginBottom: "2px",
                                    }}
                                >
                                    Phone Number
                                </label>
                                <input
                                    name="phoneNumber"
                                    type="text"
                                    required
                                    style={{
                                        width: "100%",
                                        border: "1px solid",
                                        borderColor: colorConfigs.primaryColor,
                                        padding: "8px",
                                        borderRadius: "0.375rem",
                                        outline: colorConfigs.primaryColor,
                                    }}
                                    placeholder="Enter Phone Number"
                                    value={formBillingAddress.phoneNumber}
                                    onChange={(e) =>
                                        setFormBillingAddress({ ...formBillingAddress, phoneNumber: onChangePhoneNumber(e.target.value) })
                                    }
                                />
                            </div>

                            <div style={{ display: "flex", gap: "16px", marginBottom: "8" }}>
                                <div style={{ width: "100%", fontSize: "0.875rem", marginBottom: "8px" }}>
                                    <label
                                        htmlFor="street"
                                        style={{
                                            display: "block",
                                            textAlign: "start",
                                            color: colorConfigs.primaryColor,
                                            fontSize: "0.75rem",
                                            fontWeight: "500",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        Street
                                    </label>
                                    <input
                                        name="street"
                                        type="text"
                                        required
                                        style={{
                                            width: "100%",
                                            border: "1px solid",
                                            borderColor: colorConfigs.primaryColor,
                                            padding: "8px",
                                            borderRadius: "0.375rem",
                                            outline: colorConfigs.primaryColor,
                                        }}
                                        placeholder="Enter Street"
                                        value={formBillingAddress.street}
                                        onChange={(e) =>
                                            setFormBillingAddress({ ...formBillingAddress, street: e.target.value })
                                        }
                                    />
                                </div>

                                <div style={{ width: "100%", fontSize: "0.875rem", marginBottom: "8px" }}>
                                    <label
                                        htmlFor="street2"
                                        style={{
                                            display: "block",
                                            textAlign: "start",
                                            color: colorConfigs.primaryColor,
                                            fontSize: "0.75rem",
                                            fontWeight: "500",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        Street 2
                                    </label>
                                    <input
                                        name="street2"
                                        type="text"
                                        style={{
                                            width: "100%",
                                            border: "1px solid",
                                            borderColor: colorConfigs.primaryColor,
                                            padding: "8px",
                                            borderRadius: "0.375rem",
                                            outline: colorConfigs.primaryColor,
                                        }}
                                        placeholder="Enter Street 2"
                                        value={formBillingAddress.street2}
                                        onChange={(e) =>
                                            setFormBillingAddress({ ...formBillingAddress, street2: e.target.value })
                                        }
                                    />
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "16px", marginBottom: "8px" }}>
                                <div style={{ width: "100%", fontSize: "0.875rem", marginBottom: "8px" }}>
                                    <label
                                        htmlFor="zip"
                                        style={{
                                            display: "block",
                                            textAlign: "start",
                                            color: colorConfigs.primaryColor,
                                            fontSize: "0.75rem",
                                            fontWeight: "500",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        Zip
                                    </label>
                                    <input
                                        name="zip"
                                        type="text"
                                        required
                                        style={{
                                            width: "100%",
                                            border: "1px solid",
                                            borderColor: colorConfigs.primaryColor,
                                            padding: "8px",
                                            borderRadius: "0.375rem",
                                            outline: colorConfigs.primaryColor,
                                        }}
                                        placeholder="Enter Zip"
                                        value={formBillingAddress.zip}
                                        onChange={(e) =>
                                            setFormBillingAddress({ ...formBillingAddress, zip: e.target.value })
                                        }
                                    />
                                </div>

                                <div style={{ width: "100%", fontSize: "0.875rem", marginBottom: "8px" }}>
                                    <label
                                        htmlFor="state"
                                        style={{
                                            display: "block",
                                            textAlign: "start",
                                            color: colorConfigs.primaryColor,
                                            fontSize: "0.75rem",
                                            fontWeight: "500",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        State
                                    </label>
                                    <input
                                        name="state"
                                        type="text"
                                        style={{
                                            width: "100%",
                                            border: "1px solid",
                                            borderColor: colorConfigs.primaryColor,
                                            padding: "8px",
                                            borderRadius: "0.375rem",
                                            outline: colorConfigs.primaryColor,
                                        }}
                                        placeholder="Enter State"
                                        value={formBillingAddress.state}
                                        onChange={(e) =>
                                            setFormBillingAddress({ ...formBillingAddress, state: e.target.value })
                                        }
                                    />
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "16px", marginBottom: "8px" }}>
                                <div style={{ width: "100%", fontSize: "0.875rem", marginBottom: "8px" }}>
                                    <label
                                        htmlFor="city"
                                        style={{
                                            display: "block",
                                            textAlign: "start",
                                            color: colorConfigs.primaryColor,
                                            fontSize: "0.75rem",
                                            fontWeight: "500",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        City
                                    </label>
                                    <input
                                        name="city"
                                        type="text"
                                        required
                                        style={{
                                            width: "100%",
                                            border: "1px solid",
                                            borderColor: colorConfigs.primaryColor,
                                            padding: "8px",
                                            borderRadius: "0.375rem",
                                            outline: colorConfigs.primaryColor,
                                        }}
                                        placeholder="Enter City"
                                        value={formBillingAddress.city}
                                        onChange={(e) =>
                                            setFormBillingAddress({ ...formBillingAddress, city: e.target.value })
                                        }
                                    />
                                </div>

                                <div style={{ width: "100%", fontSize: "0.875rem", marginBottom: "8px" }}>
                                    <label
                                        htmlFor="country"
                                        style={{
                                            display: "block",
                                            textAlign: "start",
                                            color: colorConfigs.primaryColor,
                                            fontSize: "0.75rem",
                                            fontWeight: "500",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        Country
                                    </label>
                                    <input
                                        name="country"
                                        type="text"
                                        style={{
                                            width: "100%",
                                            border: "1px solid",
                                            borderColor: colorConfigs.primaryColor,
                                            padding: "8px",
                                            borderRadius: "0.375rem",
                                            outline: colorConfigs.primaryColor,
                                        }}
                                        placeholder="Enter Country"
                                        value={formBillingAddress.country}
                                        onChange={(e) =>
                                            setFormBillingAddress({ ...formBillingAddress, country: e.target.value })
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", gap: "16px", marginTop: "16px" }}>
                            <button
                                type="button"
                                style={{
                                    padding: "8px 24px",
                                    minWidth: "150px",
                                    width: "100%",
                                    borderRadius: "0.375rem",
                                    color: "#000",
                                    fontSize: "0.875rem",
                                    fontWeight: "600",
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "#e5e7eb",
                                    cursor: "pointer",
                                    transition: "background-color 0.2s",
                                }}
                                onClick={closeModal}
                                onMouseOver={(e) =>
                                    ((e.target as HTMLButtonElement).style.backgroundColor = "#d1d5db")
                                }
                                onMouseOut={(e) =>
                                    ((e.target as HTMLButtonElement).style.backgroundColor = "#e5e7eb")
                                }
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                style={{
                                    padding: "8px 24px",
                                    minWidth: "150px",
                                    width: "100%",
                                    borderRadius: "0.375rem",
                                    color: "white",
                                    fontSize: "0.875rem",
                                    fontWeight: "600",
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: colorConfigs.primaryColor,
                                    cursor: "pointer",
                                    transition: "background-color 0.2s",
                                }}
                            >
                                {isProcessing ? (
                                    <>
                                        <Loading />
                                        Processing...
                                    </>
                                ) : (
                                    "Save"
                                )}
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    );

}

export default UpdateBillingAddress
