import GetStartedFreeTrial from "../../components/GetStartedFreeTrial";
import HeroSection from "../../components/HeroSection";
import '../../styles/Features.css';

const exploreFeatures = [
    {
        number: "01",
        name: "Comprehensive Fleet Management",
        description: "Easily manage and track your entire vehicle fleet in one place. RD Rental allows you to monitor vehicle availability, condition, and location in real-time, ensuring optimal fleet utilization and reducing operational downtime."
    },
    {
        number: "02",
        name: "Real-Time Vehicle Tracking",
        description: "Track the status and location of your vehicles in real-time. RD Rental integrates GPS tracking to give you full visibility of your fleet, ensuring safety, improved logistics, and better customer service."
    },
    {
        number: "03",
        name: "Automated Reservation System",
        description: "Allow your customers to book vehicles anytime, anywhere with RD Rental’s online reservation system. Customize booking details and manage reservations seamlessly with automated updates and reminders."
    },
    {
        number: "04",
        name: "Customizable Rental Plans",
        description: "Offer flexible rental options that suit the needs of your customers. RD Rental lets you customize pricing, rental periods, and terms based on vehicle type, location, and customer preferences."
    },
    {
        number: "05",
        name: "Invoicing & Payments",
        description: "Simplify billing with automated invoicing. RD Rental generates invoices based on rental duration, vehicle type, and customer data, while offering multiple payment methods to streamline transactions."
    },
    {
        number: "06",
        name: "Maintenance & Service Tracking",
        description: "Keep your vehicles in top condition with RD Rental’s built-in maintenance and service tracking. Receive alerts for scheduled servicing and vehicle maintenance, ensuring a reliable fleet at all times."
    },
];

const Features = () => {
    return (
        <div>
            <HeroSection
                mainHeading="RD Rental"
                subHeading="Explore Our Powerful Features for Rental Management"
                backgroundImage="../images/features-rd-rental.png"
            />

            <section className="feature-section">
                <div className="text-container">
                    <h1 className="headline">
                        Streamline Your Vehicle Rental Business with RD Rental
                    </h1>
                </div>

                <div className="feature-row">
                    <div className="feature-text">
                        <h2 className="feature-title">
                            EFFICIENT FLEET MANAGEMENT WITH RD RENTAL
                        </h2>
                        <p className="feature-description">
                            RD Rental helps you manage your entire fleet of vehicles, from cars to trucks. Track vehicle availability, condition, and usage in real-time to ensure your fleet is operating efficiently and without interruption. Maximize revenue by minimizing idle time and ensuring proper maintenance schedules.
                        </p>
                    </div>

                    <div className="feature-image">
                        <img src="../images/rd-rental-feature-1.png" alt="Fleet Management" />
                    </div>
                </div>

                <div className="feature-row">
                    <div className="feature-image">
                        <img src="../images/rd-rental-feature-2.png" alt="Real-Time Tracking" />
                    </div>

                    <div className="feature-text">
                        <h2 className="feature-title">
                            TRACK VEHICLES IN REAL-TIME
                        </h2>
                        <p className="feature-description">
                            RD Rental provides real-time GPS tracking for every vehicle in your fleet. Ensure safe and efficient usage by monitoring vehicle locations, mileage, and usage patterns. Enhance logistics, reduce fuel costs, and improve customer satisfaction with on-time deliveries and pickups.
                        </p>
                    </div>
                </div>
            </section>

            <section className="features-section-box">
                <div className="features-container-box">
                    <h2 className="features-heading-box">
                        Explore RD Rental Best-in-Class Features
                    </h2>

                    <div className="features-list-box">
                        {exploreFeatures.map((item, index) => (
                            <div key={index} className="feature-item-box">
                                <div className="feature-content-box">
                                    <h1 className="feature-number-box">
                                        {item.number}
                                    </h1>
                                    <h3 className="feature-name-box">
                                        {item.name}
                                    </h3>
                                    <p className="feature-description-box">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <GetStartedFreeTrial />
        </div>
    );
};

export default Features;