export const onChangePhoneNumber = (value: string) => {
    
    const numericValue = value.replace(/[^0-9]+/g, "");
    
    const trimmedValue = numericValue.slice(0, 10);

    if (trimmedValue.length <= 3) {
        return trimmedValue;
    } else if (trimmedValue.length <= 6) {
        return `(${trimmedValue.slice(0, 3)}) ${trimmedValue.slice(3)}`;
    } else if (trimmedValue.length <= 10) {
        return `(${trimmedValue.slice(0, 3)}) ${trimmedValue.slice(3, 6)}-${trimmedValue.slice(6)}`;
    }
}
