import colorConfigs from "../configs/colorConfigs";

type ExpiryDateValidatorProps = {
    expiryDate: string;
    setExpiryDate: (value: string) => void;
}

const ExpiryDateValidator = ({ expiryDate, setExpiryDate }: ExpiryDateValidatorProps) => {
    const isValidFormat = (dateStr: string) => {
        const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
        return regex.test(dateStr);
    };

    const isValidExpirationDate = (dateStr: string) => {
        if (!isValidFormat(dateStr)) {
            return false;
        }

        const [monthStr, yearStr] = dateStr.split('/');
        const month = parseInt(monthStr, 10);
        const year = parseInt(yearStr, 10);

        const now = new Date();
        const currentMonth = now.getMonth() + 1;
        const currentYear = now.getFullYear() % 100;

        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            return false;
        }

        return true;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;

        value = value.replace(/\D/g, '');

        if (value.length > 2) {
            value = value.slice(0, 2) + '/' + value.slice(2);
        }
        setExpiryDate(value);

        e.target.setCustomValidity(
            isValidExpirationDate(value) ? "" : "Invalid expiry date. Please enter a valid MM/YY format with a future date."
        );
    };

    return (
        <>
            <label
                style={{
                    display: "block",
                    textAlign: "start",
                    color: colorConfigs.primaryColor,
                    fontSize: "0.75rem",
                    fontWeight: "500",
                    marginBottom: "2px",
                }}
            >
                Expiry Date
            </label>
            <input
                style={{
                    width: "100%",
                    border: "1px solid",
                    borderColor: colorConfigs.primaryColor,
                    padding: "8px",
                    borderRadius: "0.375rem",
                    outline: colorConfigs.primaryColor,
                }}
                type="text"
                id="expiry-date"
                name='e'
                value={expiryDate}
                onChange={handleChange}
                placeholder="MM/YY"
                maxLength={5}
                required
            />
        </>
    );
};

export default ExpiryDateValidator;
