import {
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    WhatsApp as WhatsAppIcon,
    LinkedIn as LinkedInIcon,
    Comment as CommentIcon,
    Bookmark as BookmarkIcon,
    AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import HeroSection from "../../../components/HeroSection";

const RentalBusinessOperations = () => {
    return (
        <div style={{ backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "column" }}>
            <HeroSection
                mainHeading="Simplifying Rental Business Operations with Advanced Software"
                backgroundImage="../images/rd-rental-business-operations.png"
                // pexels.com
            />

            <section>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", marginTop: "40px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <AccessTimeIcon style={{ width: "16px" }} />
                        <p style={{ fontSize: "14px", color: "#555" }}>27/11/2024</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <CommentIcon style={{ width: "16px" }} />
                        <p style={{ fontSize: "14px", color: "#555" }}>3 Comments</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <BookmarkIcon />
                        <p style={{ fontSize: "14px", color: "#555" }}>1,500 Views</p>
                    </div>
                </div>
            </section>

            <section style={{ width: "100%", padding: "32px 16px" }}>
                <div style={{ margin: "0 auto", maxWidth: "768px" }}>
                    <p>
                        As the rental industry grows more competitive, the need for efficient management systems is greater than ever. While full automation is a future possibility, RD Rental is designed to help businesses streamline key operational aspects with features that reduce manual effort and improve customer service. Whether you're managing reservations, vehicle maintenance, or billing, RD Rental offers powerful tools to enhance your operations and customer satisfaction.
                    </p>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Efficient Reservation Management</h2>
                        <p>
                            One of the biggest challenges for rental businesses is handling reservations and bookings efficiently. RD Rental simplifies this process with an easy-to-use system where customers can book vehicles directly from your website or app. Real-time availability ensures that your fleet is properly managed, preventing overbookings and missed opportunities.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Comprehensive Vehicle Maintenance Management</h2>
                        <p>
                            Managing the maintenance of multiple vehicles can be overwhelming without the right tools. With RD Rental, you can track the health and condition of your fleet, schedule maintenance, and ensure vehicles are ready for use when needed. By logging repairs, tracking service intervals, and monitoring vehicle performance, you can avoid costly breakdowns and keep customers happy.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Seamless Billing and Invoicing</h2>
                        <p>
                            RD Rental makes billing easy by automating the generation of invoices. Based on your business’s predefined pricing rules and rental terms, our software generates accurate invoices for each customer. This minimizes the risk of errors and ensures that you are paid accurately for the services rendered. Customers also appreciate receiving clear, timely invoices that detail their charges and payment history.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Customer Communication and Support</h2>
                        <p>
                            With RD Rental, you can easily communicate with your customers at each stage of the rental process. Whether it’s booking confirmations, rental extensions, or reminders for upcoming returns, the software makes it easy to manage customer communication and provide exceptional support. While not fully automated, these features reduce the manual workload and ensure timely interactions.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Tracking and Reporting</h2>
                        <p>
                            RD Rental also empowers business owners with insights into their operations. Through simple, intuitive reporting tools, you can track your fleet's performance, monitor customer behavior, and analyze revenue trends. While automation for generating reports is not currently available, these reports are easy to access and can help guide your decision-making process.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <p>
                            In conclusion, while automation is a powerful tool for businesses, RD Rental provides a robust platform to simplify operations without needing complete automation. From reservation management and fleet tracking to billing and reporting, our software helps rental businesses stay organized, reduce errors, and improve overall customer service. Embrace the future with RD Rental, and start making your rental business more efficient today.
                        </p>
                    </div>
                </div>
            </section>

            <section style={{ marginBottom: "32px" }}>
                <div style={{ maxWidth: "768px", margin: "0 auto", borderRadius: "8px", display: "flex", flexDirection: "column", gap: "24px", alignItems: "center" }}>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        <p style={{ backgroundColor: "#e5e5e5", color: "#666", padding: "4px 8px", borderRadius: "4px" }}>Vehicle Rentals</p>
                        <p style={{ backgroundColor: "#e5e5e5", color: "#666", padding: "4px 8px", borderRadius: "4px" }}>Fleet Management</p>
                        <p style={{ backgroundColor: "#e5e5e5", color: "#666", padding: "4px 8px", borderRadius: "4px" }}>Customer Service</p>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "12px", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#42599E", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <FacebookIcon />
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#55ACEE", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <TwitterIcon />
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#34AF23", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <WhatsAppIcon />
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0798CF", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <LinkedInIcon />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RentalBusinessOperations;
