import React, { useState } from 'react'
import CreditCardIcon from '@mui/icons-material/CreditCard';

type CreditCardViewProps = {
    amountDue: number;
    account?: boolean;
}

const CreditCardView = ({ amountDue, account = false }: CreditCardViewProps) => {
    const [creditCardDetails, setCreditCardDetails] = useState<any>({
        cardNumber: "",
        expiryDate: "",
        cvv: ""
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

    }

    return (
        <div style={{ width: '100%', marginTop: '2rem' }}>
            <h4 style={{ fontWeight: 'bold' }}>{account ? "Pay" : "Pay this Invoice"}</h4>
            <p style={{ fontSize: '0.875rem' }}>
                {account ? "Make payment for this account" : "Make payment for this invoice"}
            </p>
    
            <form
                style={{
                    marginTop: '0.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                }}
                onSubmit={handleSubmit}
            >
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                        name="cardNumber"
                        type="text"
                        style={{
                            backgroundColor: 'transparent',
                            width: '100%',
                            fontSize: '0.875rem',
                            borderRadius: '0.375rem',
                            border: '1px solid #295B7E',
                            paddingLeft: '0.5rem',
                            paddingRight: '2.5rem',
                            paddingTop: '0.75rem',
                            paddingBottom: '0.75rem',
                            outline: 'none',
                            color: '#bbb',
                        }}
                        placeholder="Card number"
                        value={creditCardDetails.cardNumber}
                        onChange={(e) =>
                            setCreditCardDetails({ ...creditCardDetails, cardNumber: e.target.value })
                        }
                        required
                    />
                    <CreditCardIcon
                        style={{
                            width: '18px',
                            height: '18px',
                            position: 'absolute',
                            right: '1rem',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#bbbbbb',
                        }}
                    />
                </div>
    
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <div style={{ position: 'relative', flex: 1, display: 'flex', alignItems: 'center' }}>
                        <input
                            name="expiryDate"
                            type="text"
                            style={{
                                backgroundColor: 'transparent',
                                width: '100%',
                                fontSize: '0.875rem',
                                borderRadius: '0.375rem',
                                border: '1px solid #295B7E',
                                padding: '0.75rem',
                                outline: 'none',
                                color: '#bbb',
                            }}
                            placeholder="MM/YY"
                            value={creditCardDetails.expiryDate}
                            onChange={(e) =>
                                setCreditCardDetails({ ...creditCardDetails, expiryDate: e.target.value })
                            }
                            required
                        />
                    </div>
    
                    <div style={{ position: 'relative', flex: 1, display: 'flex', alignItems: 'center' }}>
                        <input
                            name="cvv"
                            type="text"
                            style={{
                                backgroundColor: 'transparent',
                                width: '100%',
                                fontSize: '0.875rem',
                                borderRadius: '0.375rem',
                                border: '1px solid #295B7E',
                                padding: '0.75rem',
                                outline: 'none',
                                color: '#bbb',
                            }}
                            placeholder="CVV"
                            value={creditCardDetails.cvv}
                            onChange={(e) =>
                                setCreditCardDetails({ ...creditCardDetails, cvv: e.target.value })
                            }
                            required
                        />
                    </div>
                </div>
    
                <div style={{ paddingTop: '1rem' }}>
                    <button
                        type="button"
                        style={{
                            color: 'white',
                            backgroundColor: '#295B7E',
                            borderRadius: '0.375rem',
                            border: '1px solid #295B7E',
                            padding: '0.5rem',
                            width: '100%',
                            textAlign: 'center',
                        }}
                        onClick={() => {}}
                    >
                        {`Pay $${amountDue?.toFixed(2)}`}
                    </button>
                </div>
            </form>
        </div>
    );    
}

export default CreditCardView;