import Cookies from 'js-cookie';
import { useEffect, useState } from 'react'
import useDelete from '../../../components/hooks/useDelete';
import { delete_GroupCreditCard, getGroupCreditCardWithGroupId, put_GroupCreditCard } from '../../../services/GroupCreditCard';
import Pagination from '../../../components/Pagination';
import useAuth from '../../../components/hooks/useAuth';

const heading = ["#", "Cardholder Name", "Exp. Date", "Last 4 Digits", "Active"];

type CreditCardDetailsProps = {
    isRefresh: boolean;
    setIsRefresh: (value: boolean) => void;
    setShowPopupAddNewCreditCard: (value: boolean) => void;
}

const CreditCardDetails = ({ isRefresh, setIsRefresh, setShowPopupAddNewCreditCard }: CreditCardDetailsProps) => {
    const groupId = Cookies.get("groupId-jj-rental-ui");
    const { auth }: any = useAuth();
    const { setDeletePopup, setData: setDeleteData }: any = useDelete();
    const [creditCardData, setCreditCardData] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
        page: 0,
        size: 5
    });
    const [totalLength, setTotalLength] = useState<number>(0);
    const [showEntries, setShowEntries] = useState<number>(5);

    useEffect(() => {
        if (groupId && isRefresh) {
            setIsRefresh(false);
            getGroupCreditCardWithGroupId(groupId, paginationModel.page, paginationModel.size)
                .then(res => {
                    setTotalLength(res?.count);
                    setCreditCardData(res?.groupCreditCard);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, isRefresh])

    const addCreditCard = () => setShowPopupAddNewCreditCard(true);

    const isValidFormat = (dateStr: string) => {
        const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
        return regex.test(dateStr);
    };

    const isValidExpirationDate = (dateStr: string) => {
        if (!isValidFormat(dateStr)) {
            return false;
        }

        const [monthStr, yearStr] = dateStr.split('/');
        const month = parseInt(monthStr, 10);
        const year = parseInt(yearStr, 10);

        const now = new Date();
        const currentMonth = now.getMonth() + 1; 
        const currentYear = now.getFullYear() % 100; 

        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            return false;
        }

        return true;
    };

    const deleteCreditCard = (primaryKey: number) => {
        setDeleteData({
            id: primaryKey,
            tilte: `Delete`,
            summary: `Are you sure you want to delete this Credit Card?`,
            isDelete: (callback: any) => {
                delete_GroupCreditCard(primaryKey, auth?.id)
                    .then(() => {
                        setIsRefresh(true);
                        callback();
                    })
                    .catch(error => console.error(error))
            }
        });

        setDeletePopup(true);
    }

    const updateCreditCard = (creditCardDetails: any) => {
        put_GroupCreditCard(creditCardDetails)
            .then(() => {
                setCreditCardData(prevData =>
                    prevData.map(card =>
                        card.id === creditCardDetails?.id
                            ? { ...card, isActive: creditCardDetails?.isActive }
                            : card
                    )
                );
            })
            .catch(error => console.error(error))
    }

    return (
        <div
            style={{
                width: "100%",
                border: "1px solid #295B7E",
                borderRadius: "0.375rem",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "50px",
                    padding: "0.5rem 1rem",
                    backgroundColor: "#295B7E",
                    color: "#fff",
                }}
            >
                <h4
                    style={{
                        fontWeight: 500,
                        color: "#fff",
                    }}
                >
                    Credit Cards
                </h4>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    gap: "0.75rem",
                }}
            >
                <table style={{ width: "100%" }}>
                    <thead style={{ borderBottom: "2px solid #295B7E" }}>
                        <tr style={{ fontSize: "0.875rem", textAlign: "left" }}>
                            {heading.map((item, index) => (
                                <th
                                    key={index}
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        textAlign: "left",
                                        padding: "0.125rem",
                                    }}
                                >
                                    {item}
                                </th>
                            ))}
                            <th
                                style={{
                                    textAlign: "left",
                                    padding: "0.125rem 0.375rem",
                                    color: "#3490dc",
                                    fontWeight: "bold",
                                    userSelect: "none",
                                }}
                            ></th>
                        </tr>
                    </thead>

                    <tbody style={{ borderBottom: "1px solid #295B7E" }}>
                        {creditCardData?.map((item, index) => (
                            <tr
                                key={index}
                                style={{
                                    fontSize: "0.875rem",
                                    textAlign: "left",
                                }}
                            >
                                <td
                                    style={{
                                        borderRight: "1px solid #3490dc",
                                        borderBottom: "1px solid #3490dc",
                                        textAlign: "start",
                                        padding: "0.125rem 0.375rem",
                                    }}
                                >
                                    {item?.id}
                                </td>
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0.375rem",
                                    }}
                                >
                                    {item?.cardholderName}
                                </td>
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0.375rem",
                                    }}
                                >
                                    {item?.expirationDate}
                                </td>
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "start",
                                        padding: "0.375rem",
                                    }}
                                >
                                    {item?.creditCardNumber?.slice(-4)}
                                </td>
                                <td
                                    style={{
                                        borderRight: "1px solid #295B7E",
                                        borderBottom: "1px solid #295B7E",
                                        textAlign: "center",
                                        padding: "0.375rem",
                                    }}
                                >
                                    <button
                                        style={{
                                            padding: "0.5rem",
                                            borderRadius: "0.375rem",
                                            color: "#fff",
                                            backgroundColor: item?.isActive && isValidExpirationDate(item?.expirationDate) ? "#16a34a" : "red",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            updateCreditCard({ ...item, isActive: !item?.isActive })
                                        }
                                        disabled={!isValidExpirationDate(item?.expirationDate)}
                                    >
                                        {item?.isActive && isValidExpirationDate(item?.expirationDate)
                                            ? "Yes"
                                            : "No"}
                                    </button>

                                </td>
                                <td
                                    style={{
                                        textAlign: "start",
                                        borderBottom: "1px solid #295B7E",
                                        padding: "0.375rem",
                                        color: "#3490dc",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        userSelect: "none",
                                    }}
                                    onClick={() => deleteCreditCard(item?.id)}
                                >
                                    {"X"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Pagination
                    totalLength={totalLength}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    setIsRefresh={setIsRefresh}
                    showEntries={showEntries}
                    setShowEntries={setShowEntries}
                />

                <div>
                    <button
                        style={{
                            backgroundColor: "#295B7E",
                            borderRadius: "0.375rem",
                            padding: "0.375rem 1rem",
                            fontWeight: "500",
                            color: "#fff",
                            cursor: "pointer",
                        }}
                        onClick={addCreditCard}
                    >
                        Add New
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreditCardDetails