import React from "react";
import colorConfigs from "../configs/colorConfigs";

interface CardNumberValidateProps {
  value: string;
  setValue: (value: string) => void;
  className?: any;
}

const CardNumberValidate = ({
  value,
  setValue,
  className,
}: CardNumberValidateProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    const isValid = /^\d{8,19}$/gm.test(value);
    e.target.setCustomValidity(
      isValid ? "" : "Please enetr a valid card number"
    );
  };

  return (
    <>
      <label
        style={{
          display: "block",
          textAlign: "start",
          color: colorConfigs.primaryColor,
          fontSize: "0.75rem",
          fontWeight: "500",
          marginBottom: "2px",
        }}
      >
        Card Number
      </label>
      <input
        name="cardnumber"
        type="text"
        style={{
          width: "100%",
          border: "1px solid",
          borderColor: colorConfigs.primaryColor,
          padding: "8px",
          borderRadius: "0.375rem",
          outline: colorConfigs.primaryColor,
        }}
        placeholder="Card number"
        required
        value={value}
        onChange={handleChange}
      />
    </>
  );
};

export default CardNumberValidate;
