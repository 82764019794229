import {
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    WhatsApp as WhatsAppIcon,
    LinkedIn as LinkedInIcon,
    Comment as CommentIcon,
    Bookmark as BookmarkIcon,
    AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import HeroSection from "../../../components/HeroSection";

const RentalSoftwareBenefits = () => {
    return (
        <div style={{ backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "column" }}>
            <HeroSection
                mainHeading="How Advanced Rental Software Enhances Fleet Management"
                backgroundImage="../images/fleet-management.jpg"
                // pexels.com
            />

            <section>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", marginTop: "40px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <AccessTimeIcon style={{ width: "16px" }} />
                        <p style={{ fontSize: "14px", color: "#555" }}>27/11/2024</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <CommentIcon style={{ width: "16px" }} />
                        <p style={{ fontSize: "14px", color: "#555" }}>2 Comments</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <BookmarkIcon />
                        <p style={{ fontSize: "14px", color: "#555" }}>1,500 Views</p>
                    </div>
                </div>
            </section>

            <section style={{ width: "100%", padding: "32px 16px" }}>
                <div style={{ margin: "0 auto", maxWidth: "768px" }}>
                    <p>
                        Effective fleet management is critical for any vehicle rental business. It's not just about owning a collection of cars, trucks, or vans, but ensuring that each vehicle is being used efficiently, maintained properly, and generating revenue. Without the right tools, managing a fleet can quickly become overwhelming. This is where advanced rental software like RD Rental can make a huge difference.
                    </p>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Optimized Fleet Tracking</h2>
                        <p>
                            One of the biggest challenges for rental businesses is keeping track of their fleet's location, status, and utilization. With RD Rental, fleet tracking is simplified through real-time updates. You can monitor the availability of each vehicle, track rental durations, and even plan for upcoming maintenance or service. This ensures that vehicles are being used efficiently and are available when needed, leading to maximized revenue.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Maintenance Scheduling</h2>
                        <p>
                            Managing vehicle maintenance is a crucial aspect of fleet management. Without a proper system in place, businesses may face unexpected breakdowns, costly repairs, and dissatisfied customers. RD Rental simplifies this process with built-in maintenance scheduling. The software allows you to track service history, schedule preventive maintenance, and set reminders for inspections or repairs. This helps reduce downtime, extend the lifespan of your fleet, and avoid unexpected costs.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Automated Vehicle Allocation</h2>
                        <p>
                            Manual vehicle allocation can be a tedious and error-prone process. RD Rental automates this with a smart allocation system. The software uses real-time data to match customers with available vehicles based on their preferences, location, and rental duration. This ensures that each vehicle is assigned optimally, reducing inefficiencies and improving customer satisfaction.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>Dynamic Pricing for Better Profitability</h2>
                        <p>
                            One of the biggest challenges in fleet management is pricing. Traditional pricing models can leave money on the table, especially when demand fluctuates. With RD Rental, businesses can implement dynamic pricing strategies. The software allows you to adjust prices based on factors like demand, vehicle type, rental duration, and even location. This flexibility enables you to maximize profitability by capitalizing on high-demand periods and adjusting rates during slow seasons.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <h2 style={{ color: "#181842", fontSize: "20px", fontWeight: "600", marginBottom: "12px" }}>In-Depth Analytics for Informed Decisions</h2>
                        <p>
                            Analytics play a crucial role in optimizing fleet management. RD Rental offers robust reporting tools that provide valuable insights into fleet performance. You can analyze rental trends, identify the most popular vehicles, monitor revenue, and even track operational costs. With this data at your fingertips, you can make informed decisions to optimize your fleet, improve profitability, and anticipate customer needs.
                        </p>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <p>
                            In conclusion, managing a fleet of rental vehicles can be complex and time-consuming, but with the help of advanced rental software like RD Rental, businesses can streamline operations, reduce costs, and enhance customer satisfaction. Whether it's through optimized fleet tracking, automated maintenance scheduling, or dynamic pricing, rental software offers solutions that improve efficiency, boost profitability, and ultimately contribute to long-term success.
                        </p>
                    </div>
                </div>
            </section>

            <section style={{ marginBottom: "32px" }}>
                <div style={{ maxWidth: "768px", margin: "0 auto", borderRadius: "8px", display: "flex", flexDirection: "column", gap: "24px", alignItems: "center" }}>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        <p style={{ backgroundColor: "#e5e5e5", color: "#666", padding: "4px 8px", borderRadius: "4px" }}>Fleet Management</p>
                        <p style={{ backgroundColor: "#e5e5e5", color: "#666", padding: "4px 8px", borderRadius: "4px" }}>Vehicle Maintenance</p>
                        <p style={{ backgroundColor: "#e5e5e5", color: "#666", padding: "4px 8px", borderRadius: "4px" }}>Dynamic Pricing</p>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "12px", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#42599E", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <FacebookIcon />
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#55ACEE", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <TwitterIcon />
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#34AF23", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <WhatsAppIcon />
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0798CF", padding: "12px", borderRadius: "50%", color: "#fff", width: "48px", height: "48px" }}>
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                                <LinkedInIcon />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default RentalSoftwareBenefits;