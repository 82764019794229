import { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { getInvoicesByGroupId } from '../../../services/Invoice';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Pagination from '../../../components/Pagination';

const heading = ["#", "Issued On", "Total Amount", "Status"];

const Invoice = () => {
    const groupId = Cookies.get("groupId-jj-rental-ui");
    const [creditCardData, setCreditCardData] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
        page: 0,
        size: 5
    });
    const [totalLength, setTotalLength] = useState<number>(0);
    const [showEntries, setShowEntries] = useState<number>(5);

    useEffect(() => {
        if (groupId) {
            getInvoicesByGroupId(groupId, paginationModel.page, paginationModel.size)
                .then(res => {
                    setTotalLength(res?.count)
                    const data = res?.invoices?.map((item: any) => ({
                        ...item,
                        invoiceDate: item?.invoiceDate ? format(new Date(item?.invoiceDate), "dd/MM/yyyy") : "",
                        totalAmount: item?.amountDue ?? 0 + item?.amountPaid ?? 0,
                    }))
                    setCreditCardData(data);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, paginationModel])


    return (
        <div
          style={{
            width: "100%",
            border: "1px solid #295B7E",
            borderRadius: "0.375rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "50px",
              padding: "0.5rem 1rem",
              backgroundColor: "#295B7E",
              color: "#fff",
            }}
          >
            <h4
              style={{
                fontWeight: 500,
                color: "#fff",
              }}
            >
              Invoices
            </h4>
          </div>
      
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              gap: "0.75rem",
            }}
          >
            <table style={{ width: "100%" }}>
              <thead style={{ borderBottom: "2px solid #3490dc" }}>
                <tr style={{ fontSize: "0.875rem", textAlign: "start" }}>
                  {heading.map((item, index) => (
                    <th
                      key={index}
                      style={{
                        borderRight: "1px solid #3490dc",
                        textAlign: "start",
                        padding: "0.125rem",
                      }}
                    >
                      {item}
                    </th>
                  ))}
                  <th
                    style={{
                      textAlign: "start",
                      paddingLeft: "0.375rem",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
      
              <tbody style={{ borderBottom: "1px solid #3490dc" }}>
                {creditCardData?.map((item, index) => (
                  <tr
                    key={index}
                    style={{ fontSize: "0.875rem", textAlign: "start" }}
                  >
                    <td
                      style={{
                        borderRight: "1px solid #3490dc",
                        borderBottom: "1px solid #3490dc",
                        textAlign: "start",
                        padding: "0.125rem 0.375rem",
                      }}
                    >
                      {item?.id}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #3490dc",
                        borderBottom: "1px solid #3490dc",
                        textAlign: "start",
                        padding: "0.125rem 0.375rem",
                      }}
                    >
                      {item?.invoiceDate}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #3490dc",
                        borderBottom: "1px solid #3490dc",
                        textAlign: "end",
                        padding: "0.125rem 0.375rem",
                      }}
                    >
                      ${item?.totalAmount === null ? "0.00" : (parseFloat(item?.totalAmount))?.toFixed(2)}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #3490dc",
                        borderBottom: "1px solid #3490dc",
                        textAlign: "start",
                        padding: "0.375rem 0.625rem",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#4ade80",
                          padding: "0.375rem 0.25rem",
                          borderRadius: "0.375rem",
                          color: "#fff",
                        }}
                      >
                        {item?.status}
                      </span>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #3490dc",
                        padding: "0.125rem 0.375rem",
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "#3b82f6",
                        userSelect: "none",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "0.875rem",
                        }}
                      >
                        <PictureAsPdfIcon style={{ fill: "#3b82f6" }} />
                        <span>PDF</span>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
      
            <Pagination
              totalLength={totalLength}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              showEntries={showEntries}
              setShowEntries={setShowEntries}
            />
          </div>
        </div>
      );      
}

export default Invoice;